import { useContext, useEffect, useState } from "react";
import { Form, Stack, Button } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { AccountContext } from "../../Contexts/AccountContext";
import { AssmtContext } from "../../Contexts/AssmtContext";
import Navigate from "../../Navigate";
import ToastMessage from "../../ToastMessage";
import { GiDna1 } from "react-icons/gi";
import AssmtSet from "../GroupAssmt/AssmtSet";
import AccessDenied from "../../AccessDenied";
import { EditAssmtContext } from "../../Contexts/EditAssmtContext";
import {Helmet} from 'react-helmet';

const EditGroupAssmt = () => {
    const { loginStatus, loggedInUser, apiCall } = useContext(AccountContext);
    const {
		assmt,
		toast,
		setToast,
		message,
		setMessage,
		status,
		setStatus,
		setAssmt
    } = useContext(AssmtContext);

	const {
		createAssmtGrp,
		setAssmtGrpID
	  } = useContext(EditAssmtContext);
	
    const AGID = useParams().id;
	setAssmtGrpID(AGID);

    let navigate = useNavigate();

    const [assmtGrpObj, setAssmtGrpObj] = useState({});
    const [selectedAssmtList, setSelectedAssmtList] = useState([]);
	const [newPubAssmtList, setPubNewAssmtList] = useState([]);
	const [newNotPubAssmtList, setNotPubNewAssmtList] = useState([]);
    

    useEffect(() => {
        async function getData() {
            const { data } = await apiCall(`admin/getEditAssmtData/${AGID}`);
            setSelectedAssmtList(data[1]);
            let assmtObj = data[0][0];
			assmtObj["desc"] = assmtObj["description"];
			delete assmtObj["description"];
            setAssmtGrpObj(assmtObj);
            let selectedIds = [];
            data[1].forEach((val) => {
                selectedIds.push(val["AID"]);
            })
			const getInfo = await apiCall(`admin/getAssmts`);
			console.log(getInfo);
            let pubAssmtList = [], notPubAssmtList = [];
            getInfo["data"].forEach((data) => {
                if(data["type"] !== "group" && !selectedIds.includes(data["AID"])) {
                    if(data["published"] === "Yes") pubAssmtList.push(data);
                    else notPubAssmtList.push(data);
            }})
            setPubNewAssmtList(pubAssmtList);
            setNotPubNewAssmtList(notPubAssmtList);
        }
		
        getData();
    }, [])

    function addRemoveAssmtFromDDown(e) {
		let {options, value, selectedIndex} = e.target
		let tempList = [...selectedAssmtList]
		let getAssmtObj = {}
		let data = value.split(" ")
		if(data[1] === "Yes") getAssmtObj = newPubAssmtList[data[0]];
		else getAssmtObj = newNotPubAssmtList[data[0]];
		let assmtObj = {
			AID: getAssmtObj["AID"],
			name: options[selectedIndex].innerHTML,
			published: getAssmtObj["published"]
		}
		tempList.push(assmtObj);
		setSelectedAssmtList(tempList);

		tempList = [];
		if(data[1] === "Yes") tempList = [...newPubAssmtList];
		else tempList = [...newNotPubAssmtList];
		tempList.forEach(async (obj, idx) => {
			if(obj["AID"] === getAssmtObj["AID"]) {
				tempList.splice(idx, 1);
			}
		});
		if(data[1] === "Yes") setPubNewAssmtList(tempList);
		else setNotPubNewAssmtList(tempList);
	}

    function addRemoveAssmtFromList(assmtObj, idx) {
		console.log(assmtObj);
		let tempList = []
		if(assmtObj["published"] === "Yes") tempList = [...newPubAssmtList];
		else tempList = [...newNotPubAssmtList];
		tempList.push(assmtObj);
		if(assmtObj["published"] === "Yes") setPubNewAssmtList(tempList);
		else setNotPubNewAssmtList(tempList);

		tempList = [...selectedAssmtList];
		tempList.splice(idx, 1);
		setSelectedAssmtList(tempList);
	}

    function changePosUp(idx) {
		if(idx === 0) return;
		let tempList = [...selectedAssmtList];
		let obj = tempList[idx];
		tempList.splice(idx, 1);
		tempList.splice(idx-1, 0, obj);
		setSelectedAssmtList(tempList);
	}

	function changePosDown(idx) {
		let tempList = [...selectedAssmtList];
		if(idx === tempList.length-1) return; 
		let obj = tempList[idx];
		tempList.splice(idx, 1);
		tempList.splice(idx+1, 0, obj);
		setSelectedAssmtList(tempList);
	}

    return(
        <div>
            {loginStatus === true && loggedInUser.group === "Admin" ? (
				<>
				<Helmet>
					<title>Helix Employability Tool | Edit Group Assessment</title>
				</Helmet>
                <Navigate />
					<h6><a href="#" onClick={() => window.history.back()}>&lt;Back</a></h6>
					<ToastMessage
						toast={toast}
						onClose={() => setToast(!toast)}
						message={message}
						status={status}
					/>
					<Stack direction="horizontal" gap={3}>
						<label>Edit Assessment Group</label>
						<div className="ms-auto">
							<Form.Select
							style={{width: "fit-content"}}
							value={""}
							onChange={(e) => {addRemoveAssmtFromDDown(e)}}
							>
								<option value="" disabled selected>
									Select assessments
								</option>
								<option value="" disabled selected>
									Published: Yes
								</option>
								{newPubAssmtList.length > 0 && newPubAssmtList.map((singleAssmt, idx) => (
									<option key={singleAssmt.AID} value={`${idx} ${singleAssmt.published}`}>
										{singleAssmt.name}
									</option>
								))}
								<option value="" disabled selected>
									Published: No
								</option>
								{newNotPubAssmtList.length > 0 && newNotPubAssmtList.map((singleAssmt, idx) => (
									<option key={singleAssmt.AID} value={idx}>
										{singleAssmt.name}
									</option>
								))}
							</Form.Select>
						</div>
					</Stack>
					<br />

					<div className="newAssmt">
						<div className="w-25">
							<Form>
								<Form.Group className="mb-3">
									<Form.Control
										type="text"
										placeholder="Group Name"
                                        value={assmtGrpObj["name"]}
										onChange={(e) => {
											setAssmtGrpObj((prevValue) => {
												return {
													...prevValue,
													name: e.target.value,
												};
											});
										}}
									/>
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Control
										as="textarea"
										placeholder="Group Description"
                                        value={assmtGrpObj["desc"]}
										rows={5}
										onChange={(e) => {
											setAssmtGrpObj((prevValue) => {
												return {
													...prevValue,
													desc: e.target.value,
												};
											});
										}}
									/>
								</Form.Group>
								<Form.Group>
								<Button
								variant="light"
								style={{ color: "#163172" }}
								className="createAssmt-btn"
								onClick={async() => {
									if(!assmtGrpObj["name"]) {
										setMessage(
											`Please give the assessment a name`
										);
										setStatus(false);
										setToast(true);
									}
									else if(!assmtGrpObj["desc"]) {
										setMessage(
											`Please give the assessment a descrption`
										);
										setStatus(false);
										setToast(true);
									}
									else if(selectedAssmtList.length == 0) {
										setMessage(
											`Please select atleast 1 assessment`
										);
										setStatus(false);
										setToast(true);
									}
									else {
										let assmtList = [];
										selectedAssmtList.map((selectedAssmt, aIdx) => (
											assmtList.push(selectedAssmt["AID"])
										))
										const result = await createAssmtGrp(assmtGrpObj, assmtList, true);
										if (result) {
											const { data } = await apiCall("admin/");
											setAssmt(data);
											setMessage(
												`New Assessment ${assmtGrpObj.name} created successfully!`
											);
											setStatus(true);
											setToast(true);
											navigate("/admin", {
												replace: false,
											});
										} else if (result === false) {
											setMessage(
												"Assessment could not be created!"
											);
											setStatus(false);
											setToast(true);
										}
									}
								}}
								>
									{" "}
									<GiDna1
									style={{
										color: "#163172",
										fontSize: "20px",
									}}
									/>
									Create
								</Button>
								</Form.Group>
							</Form>
							<br />
							<br />
						</div>
						<div className="assmt-b">
							{selectedAssmtList.map((singleAssmt, aIdx) => (
								<div key={aIdx}>
									<AssmtSet
										aIdx={aIdx}
										addRemoveAssmtFromList={addRemoveAssmtFromList}
										singleAssmt={singleAssmt}
										changePosUp={changePosUp}
										changePosDown={changePosDown}
									/>
									<br />
								</div>
							))}
						</div>
					</div>
                </>
			) : (
				<AccessDenied />
			)}
        </div>
    );
}

export default EditGroupAssmt;