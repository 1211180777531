import React, { useEffect, useRef, useState, useContext } from "react";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import { useNavigate } from "react-router-dom";
import { AssmtContext } from "../../Contexts/AssmtContext";
import CheckModal from "../../Common/CheckModal";
import { AccountContext } from "../../Contexts/AccountContext";
import { GroupContext } from "../../Contexts/GroupContext";

const UserListTable = (props) => {
  const { setToast, setMessage, setStatus, exportUserResForAllAssmtToCSV } =
    useContext(AssmtContext);
  const {apiCall} = useContext(AccountContext);
  const {setGroupList} = useContext(GroupContext);
  $.DataTable = require("datatables.net");
  const tableRef = useRef();
  const navigate = useNavigate();
  const setAllUserRes = props.setAllUserRes;
  const handleShow1 = props.handleShow1;
  const setAllresCSVFilename = props.setAllresCSVFilename;
  const setHeader = props.setHeader;
  const setBody = props.setBody;
  const handleShow4 = props.handleShow4;
  const setUserToDisable = props.setUserToDisable;
  const setUserList = props.setUserList;

  const [userToDelete, setUserToDelete] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleShowDeleteModal = () => {
    setShowDeleteModal(true);
  }

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  }

  const deleteUser = async ()=>{
    try {
      let resp = await apiCall(`admin/deleteUser/${userToDelete.UID}`, "DELETE");

      if (resp?.name === "AxiosError")
          throw {message: 'Sorry, something went wrong!'}
      if (resp?.data?.error) throw resp.data;

      //filtering out the user that was disabled
      setUserList((data) => {
        return data.filter((user) => {
          return user.email_ID !== userToDelete.email_ID;
        });
      });

      //update the disable group memebers count
      setGroupList((groups) => {
        let userGroups = userToDelete.GIDs.split(",");
        return groups.map((e) => {
          if (userGroups.includes(String(e.GID))) {
            return { ...e, no_members: e.no_members - 1};
          }
          return e;
        });
      });

      setMessage("User deleted successfully!");
      setStatus(true);
      setToast(true);
    } catch (err) {
      console.log("Error while updating user status: ", err);
      setMessage(err.message);
      setStatus(false);
      setToast(true);
      return;
    }finally{
      handleCloseDeleteModal();
    }
  }

  useEffect(() => {
    const table = $(tableRef.current).DataTable({
      scrollX: true,
      order: [[0,'asc',[1,'asc']]],
      data: props.userList,
      columns: [
        {
          className: "dt-body-left",
          title: "First Name",
          data: "first_name",
        },
        { className: "dt-body-left", title: "Last Name", data: "last_name" },
        {
          className: "dt-body-left",
          title: "Email ID",
          data: "email_ID",
        },
        {
          className: "dt-body-left",
          width: '125px',
          title: "Groups",
          data: "GroupNames",
          render: function(data){
            //dont render All Users group name because it is already standard
            //render other groups if the user is present in
            let groups = data.split(',').filter((grpName)=> {return grpName !== "All Users"});
            if(groups.length === 0)
              return "----"
            let selectHTML = `<select class="grp-dropdown">
            ${groups.map((grp) => {
              return `<option class="grp-opt" value="${grp}">${grp.length >= 10 ? grp.slice(0,10)+'...' : grp }</option>`
            })}
            </select>`
            return selectHTML;
          }
        },
        {
          className: "dt-body-left",
          title: "Email Verified",
          data: "email_verified",
          render: function (data) {
            if (data === 0) {
              return "False";
            } else {
              return "True";
            }
          },
        },
        {
          className: "dt-body-left",
          title: "Consent",
          data: "consent",
          render: function (data) {
            if (data === 0) {
              return "No";
            } else {
              return "Yes";
            }
          },
        },
        {
          className: "dt-body-left",
          title: "All Response",
          defaultContent: `<Button class="all-csvbtn btn btn-outline-secondary btn-sm">
                CSV
              </Button>`,
        },
        {
          className: "dt-body-left",
          title: "Set Disabled",
          defaultContent: `<Button class="disable-btn btn btn-outline-danger btn-sm">
                  Disable
                </Button>`,
        },
        {
          className: "dt-body-left",
          title: "Delete",
          defaultContent: `<Button class="delete-btn btn btn-outline-danger btn-sm">
                  Delete
                </Button>`,
        },
      ],
      destroy: true,
    });
 
    $("#example2 tbody").on("click", ".all-csvbtn", async function (e) {
      e.stopPropagation();
      const data = table.row($(this).parents("tr")).data();
      if (data !== undefined) {
        let exportUserResAllData = await exportUserResForAllAssmtToCSV(
          data.UID
        );
        if (exportUserResAllData.length !== 0) {
          setAllUserRes(exportUserResAllData);
          setHeader(`${data.first_name} ${data.last_name}`);
          setBody(
            `${data.first_name} ${data.last_name}'s responses for all assessments is generated as .csv file and ready to download!`
          );
          setAllresCSVFilename(
            `${
              data.UID
            } responses for all assmt (exported on ${new Date().toLocaleDateString()})`
          );
          handleShow1();
        } else {
          setMessage("No response found!");
          setStatus(false);
          setToast(true);
        }
      }
    });

    $("#example2 tbody").on("click", ".disable-btn", function (e) {
      e.stopPropagation();
      const data = table.row($(this).parents("tr")).data();
      if (data !== undefined) {
        setUserToDisable(data);
        handleShow4();
        return;
      }
    });

    $("#example2 tbody").on("click", ".delete-btn", function (e) {
      e.stopPropagation();
      const data = table.row($(this).parents("tr")).data();
      if (data !== undefined) {
        setUserToDelete(data);
        handleShowDeleteModal();
        return;
      }
    });

    $("#example2 tbody").on("click", ".grp-dropdown", function (e) {
      e.stopPropagation();
    });

    $("#example2 tbody").on("click", "tr", function () {
      const data = table.row($(this)).data();
      if (data !== undefined) {
        navigate(`/admin/userManagement/userResponse`, {
          state: {
            userObj: {
              first_name: data.first_name,
              last_name: data.last_name,
              email_ID: data.email_ID,
              UID: data.UID,
            },
          },
        });
      }
    });
    return function () {
      table.destroy();
    };
  }, [props.userList]);

  return (
    <>    
    <CheckModal 
       show={showDeleteModal}
       handleClose={handleCloseDeleteModal}
       title={'Delete User'}
       bodyText={'Do you want to permanently delete this user? This action cannot be reverted!'}
       btnText={'Delete'}
       callback={deleteUser}
    />

    <div className="user-table">
      <table
        className="hover dt[-head|-body]-left cell-border"
        width="100%"
        id="example2"
        ref={tableRef}
      >
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email ID</th>
            <th>Groups</th>
            <th>Email Verified</th> 
            <th>Consent</th>
            <th>All Response</th>
            <th>Set Disabled</th>
            <th>Action</th>
          </tr>
        </thead>
      </table>
    </div>
    </>
    //delete modal for users
  );
};

export default UserListTable;
