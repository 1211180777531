import React, { useContext, useEffect, useState } from "react";
import { AccountContext } from "./Contexts/AccountContext";
import { AssmtContext } from "./Contexts/AssmtContext";
import { Navbar, Container, Stack, Form, Button, Nav, NavDropdown } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../image/Helix-logo.png";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import ToastMessage from "./ToastMessage";
import { useMediaQuery } from "react-responsive";
import menu from "../image/menu.svg";
import { Helmet } from "react-helmet";
import axios from "axios";

const Login = () => {
	const {
		authenticate,
		loggedInUser,
		loginStatus,
		getSession,
		setLoginStatus,
		setLoggedInUser,
	} = useContext(AccountContext);

	const navigate = useNavigate();
	const location = useLocation();
	const [sessionExpired, setSessionExpired] = useState(location.state? location.state.sessionExpired : false);
	const isMobile = useMediaQuery({ maxWidth: 767 });
    const isLandscapeMobile = useMediaQuery({ query: '(orientation: landscape) and (max-height: 431px' });


	useEffect(() => {
		const getSessionData = async () => {
			const { userInfo } = await getSession();
			setLoggedInUser(userInfo);
			setLoginStatus(true);
		};
		getSessionData();
	}, []);

	if (loginStatus === true && loggedInUser.group === "Admin") {
		navigate("/admin");
	} else if (loginStatus === true && loggedInUser.group === "User") {
		navigate(`/user/dashboard/${loggedInUser.UID}`);
	}

	const {
		toast,
		setToast,
		message,
		setMessage,
		status,
		setStatus,
	} = useContext(AssmtContext);

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [passShow, setPassShow] = useState(false);
	const [errMsg, setErrMsg] = useState("");
	const [load, setLoad] = useState(false);

	const togglePassword = () => {
		setPassShow(!passShow);
	};

	const onLogin = async (event) => {
	    try{
			event.preventDefault();

			if (email === "" || password === "") {
				setMessage("Email or password missing!");
				setStatus(false);
				setToast(true);
				return;
			}
			setLoad(true);

			//now since whenever we are disabling an user, we are directly updating the database, so while logging the user check the status first and then call Cognito
            let resp = await axios.post(
				`${window.location.protocol}//${process.env.REACT_APP_SERVER_IP}:${
				  process.env.REACT_APP_SERVER_PORT
				}/user/checkStatus`,
				{ email_ID: email });
			
			if(resp?.data?.error)
				throw resp.data;
		    
			if(!resp?.data)
				throw {message: "Network Error!"}

			authenticate(email, password)
				.then((data) => {
					if (data.role === "Admin") {
						navigate("/admin");
					} else if (data.role === "User") {
						navigate(`/user/dashboard/${data.UID}`);
					}
				})
				.catch((err) => {
					setLoad(false);
					setErrMsg(err.message);
					setEmail("");
					setPassword("");
				});

		}catch(err){
			console.log("error while logging in : ",err);
			setLoad(false);
			setErrMsg(err.message);
			setEmail("");
			setPassword("");
		}
	};

	useEffect(() => {
		if (sessionExpired) {
			setMessage("Session has expired. Please log in again.");
			setStatus(false);
			setToast(true);
			setSessionExpired(false);
			navigate(location.pathname, { state: { sessionExpired: false }, replace: true})
		}
	}, [sessionExpired]);

	return (
		<div>
			<Helmet>
				<title>Helix Employability Tool | Sign In</title>
			</Helmet>
			<Navbar fixed="top" className="navbar">
				<Container>
					<Navbar.Brand href="/">
						<img
							alt=""
							src={logo}
							width="auto"
							height="40"
							className="d-inline-block align-top"
						/>
					</Navbar.Brand>
					{isMobile ? (
                        <NavDropdown
                         title={<img src={menu} alt="Menu" />}
                         id="basic-nav-dropdown"
                         align="end"
                         style={{ marginRight: "-5px" }}
                         className="custom-dropdown"
                        >
                           <NavDropdown.Item href="/">Home</NavDropdown.Item>
                           <NavDropdown.Divider />
                           <NavDropdown.Item href="/about-us">About Us</NavDropdown.Item>
                           <NavDropdown.Divider />
                           <NavDropdown.Item href="/contact-us">Contact Us</NavDropdown.Item>
                           <NavDropdown.Divider />
                           <NavDropdown.Item href="/login">Sign in</NavDropdown.Item>
                           <NavDropdown.Divider />
                           <NavDropdown.Item href="/register">Sign up</NavDropdown.Item>
                        </NavDropdown>
                    ) : isLandscapeMobile? (<NavDropdown
                        title={<img src={menu} alt="Menu" />}
                        id="basic-nav-dropdown"
                        align="end"
                        style={{ marginRight: "-5px" }}
                        className="custom-dropdown"
                       >
                          <NavDropdown.Item href="/">Home</NavDropdown.Item>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="/about-us">About Us</NavDropdown.Item>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="/contact-us">Contact Us</NavDropdown.Item>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="/login">Sign in</NavDropdown.Item>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="/register">Sign up</NavDropdown.Item>
                       </NavDropdown>):(
                        <Nav className="nav-right-gap">
                            <Nav.Link href="/">Home</Nav.Link>
                            <Nav.Link href="/about-us">About Us</Nav.Link>
                            <Nav.Link href="/contact-us">Contact Us</Nav.Link>
                            <Nav.Link href="/login"><Button>Sign in</Button></Nav.Link>
                            <Nav.Link href="/register"><Button className="custom-button">Sign up</Button></Nav.Link>
                        </Nav>
                    )}
				</Container>
			</Navbar>
			<ToastMessage
				toast={toast}
				onClose={() => setToast(!toast)}
				message={message}
				status={status}
			/>
			<br />
			<div className="login-div">
				<Stack gap={3}>
					<h1 className="text-muted text-center">
						Log in to{" "}
						<span style={{ color: "#163172" }}>Helix</span>
					</h1>
					<Form onSubmit={onLogin}>
						<Form.Group className="mb-3" controlId="formBasicEmail">
							<Form.Control
								type="email"
								placeholder="Enter email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</Form.Group>

						<Form.Group
							className="mb-3"
							controlId="formBasicPassword"
						>
							<div style={{ display: "flex" }}>
								<Form.Control
									type={passShow ? "text" : "password"}
									placeholder="Password"
									value={password}
									onChange={(e) =>
										setPassword(e.target.value)
									}
								/>
								{password.length ? (
									<Button
										variant="outline-light"
										style={{ color: "#163172" }}
										onClick={togglePassword}
									>
										{passShow === false ? (
											<BsFillEyeFill />
										) : (
											<BsFillEyeSlashFill />
										)}
									</Button>
								) : (
									<></>
								)}
							</div>

							{errMsg.length > 0 ? (
								<Form.Text
									style={{
										color: "#ff0000",
										fontSize: "10px",
									}}
								>
									{errMsg}
								</Form.Text>
							) : (
								<></>
							)}
						</Form.Group>
						<Button
							className="mb-3"
							variant="light"
							style={{ color: "#163172", width: "100%" }}
							type="submit"
							disabled={load}
						>
							{load ? "Wait..." : "Login"}
						</Button>
					</Form>
					<p style={{ color: "#163172", textAlign: "center" }}>
						Forgot your password?{" "}
						<Link to="/forgotpassword">click here</Link>
					</p>

					<p style={{ color: "#163172", textAlign: "center" }}>
						Don’t have an account?{" "}
						<Link to="/register">Register</Link>
					</p>
				</Stack>
			</div>
		</div>
	);
};

export default Login;
