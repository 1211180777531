import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { UserContext } from "../../Contexts/UserContext";
import { AssmtContext } from "../../Contexts/AssmtContext";
import { AccountContext } from '../../Contexts/AccountContext';
import { useNavigate } from 'react-router-dom';

const SaveAndNextButton = ({ onNext }) => {
  const {
    AssmtID,
    UserAssmtID,
    allSecObj,
    step,
    allQuestion,
    userResponseList,
    setUserResponseList,
    submitSection,
    grpAssmtID,
    setIncompleteQuestions,
  } = useContext(UserContext);

  const { setMessage, setStatus, setToast } = useContext(AssmtContext);
  const {
		loggedInUser
	} = useContext(AccountContext);

  const navigate = useNavigate();

  const handleSaveAndNext = async () => {
    let flagM = true;
    let flagP = true;
    let flagL = true;
    let flagL6 = true;
    let flagI = true;
    const uniqueIncompleteQuestions = new Set();

    allQuestion.forEach((que) => {
      if (que.type === "Multiple") {
        let tempF = false;
        que.choices.forEach((ch) => {
          if (ch.checked === 1) {
            tempF = true;
          }
        });
        flagM = tempF;
        if(flagM === false){
          uniqueIncompleteQuestions.add(que.QID);
        }
      } else if (que.type === "Pick One") {
        if (que.checked === 0) {
          flagP = false;
          uniqueIncompleteQuestions.add(que.QID);
        }
      } else if (que.type === "Likert") {
        que.choices.forEach((cho) => {
          if (cho.checked === 0) {
            flagL = false;
            uniqueIncompleteQuestions.add(que.QID);
          }
        });
      } else if (que.type === "Likert-6") {
        que.choices.forEach((cho) => {
          if (cho.checked === 0) {
            flagL6 = false;
            uniqueIncompleteQuestions.add(que.QID);
          }
        });
      } else if (que.type === "Inventory") {
        que.choices.forEach((cho) => {
          if (cho.checked === 0) {
            flagI = false;
            uniqueIncompleteQuestions.add(que.QID);
          }
        });
      }
    });
    setIncompleteQuestions([...uniqueIncompleteQuestions]);

    if (flagM === false || flagP === false || flagL === false || flagL6 === false || flagI === false) {
      setMessage("Attempt all questions");
      setStatus(false);
      setToast(true);
      return;
    } else {
      const submitSec = await submitSection(
        UserAssmtID,
        userResponseList,
        AssmtID,
        step,
        allSecObj[step]["sec_analysisType"],
        allSecObj[step]["nfc"],
        grpAssmtID
      );
      if (submitSec === true) {
        onNext();
        setUserResponseList([]);
      } else {
        setMessage("There was an issue submitting your responses. Please resume again after a while. If the issue persists, kindly contact the administrator for assistance.");
        setStatus(false);
        setToast(true);
        navigate(`/user/dashboard/${loggedInUser.UID}`);
      }
    }
  };

  return (
    <Button variant="outline-primary" onClick={handleSaveAndNext} className='createAtmptBttn'>
      Save & Next
    </Button>
  );
};

export default SaveAndNextButton;