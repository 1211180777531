import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Alert, Spinner } from "react-bootstrap";

import {
  Stack,
  Form,
  Card,
  Button,
  Accordion,
  Badge,
  Table,
  Modal,
  ProgressBar,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import AccessDenied from "../../AccessDenied";
import { AccountContext } from "../../Contexts/AccountContext";
import {
  Chart as ChartJS,
  CategoryScale,
  RadialLinearScale,
  LinearScale,
  BarElement,
  Title,
  PointElement,
  LineElement,
  Filler,
  Colors,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar, Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Slider } from "@mui/material";
import Navigate from "../../Navigate";
import { AssmtContext } from "../../Contexts/AssmtContext";
import { generateRGBColors } from "../../../utils/helpers";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { CSVLink } from "react-csv";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import RoundyWrapper from "./RoundyWrapper.js";
import moment from "moment";
import "moment-timezone";
import Roundy from "roundy";
import emailjs from "emailjs-com";
import validator from "validator";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Colors,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  CategoryScale,
  LinearScale,
  BarElement
);

const AnalyzeBySection = () => {
  const AID = useParams().AID;
  const UID = useParams().UID;
  const { state } = useLocation();
  const UAID = state.UAID;
  let AGID = localStorage.getItem("AGID");
  const navigate = useNavigate();
  ChartJS.defaults.color = "#97989c";
  const { loginStatus, loggedInUser, apiCall } = useContext(AccountContext);
  const { assmtType, setAssmtType, exportSingleResToCSV } =
    useContext(AssmtContext);
  let [nameList, setNameList] = useState([]);
  let [scoreData, setScoreData] = useState([]);
  let [hcsValue, setHCSValue] = useState([]);
  let [hcsEmpty, setHCSEmpty] = useState(true);
  // let [cuArEmpty, setCuArEmpty] = useState(true);
  // let [motivEmpty, setMotivEmpty] = useState(true);
  let [retHcsValue, setRetHCSValue] = useState([]);
  let [userAssmtObj, setUserAssmtObj] = useState({});
  let [colorArr, setColorArr] = useState([]);
  // let [motivBarData, setMotivBarData] = useState([]);
  // let [cuArBarData, setCuArBarData] = useState([]);
  // const [userMotivation, setUserMotivation] = useState(null);
  const [sectionGraphs, setSectionGraphs] = useState({});
  let [assmtName, setAssmtName] = useState("");
  const [sectionAnalysisData, setSectionAnalysisData] = useState([]);
  const [secObj, setSecObj] = useState([]);
  const [userRespObj, setUserRespObj] = useState([]);
  const [seqNameList, setSeqNameList] = useState([]);
  const [seqScoreData, setSeqScoreData] = useState([]);
  const [assmtObj, setAssmtObj] = useState({});
  const D2color = [
    [52, 165, 243],
    [52, 165, 243],
    [52, 165, 243],
    [52, 165, 243],
    [52, 165, 243],
    [52, 165, 243],
    [52, 165, 243],
  ]; //[[50, 168, 82],[168, 162, 50],[125, 50, 168],[168,50,54],[50,104,168]];
  const acolorfix = [
    [23, 151, 255],
    [255, 147, 21],
    [0, 203, 154],
  ]; //[[255,0,0],[52,165,243],[78,216,217]]
  const componentRef = useRef();
  const [pdfGenerating, setPdfGenerating] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [namedAnalysisData, setNamedAnalysisData] = useState([]);
  const [colorIndex, setColorIndex] = useState(0);
  const [userRes, setUserRes] = useState([]);
  const [csvFilename, setCSVfilename] = useState("");
  const [bodyName, setBodyName] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [recipientEmail, setRecipientEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [emailStatusMessage, setEmailStatusMessage] = useState("");
  const [emailStatusVariant, setEmailStatusVariant] = useState("success");

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isLandscapeMobile = useMediaQuery({
    query: "(orientation: landscape) and (max-height: 431px",
  });

  useEffect(() => {
    async function getIndiAssmtAnalysisData() {
      const { data: secNames } = await apiCall(
        `analysis/sectionnamelist/${AID}`
      );

      const mergeMap = new Map();
      secNames.forEach((item, i) => {
        if (!mergeMap.has(item.sec_name)) {
          mergeMap.set(item.sec_name, [i]);
        } else {
          const curr = mergeMap.get(item.sec_name);
          mergeMap.set(item.sec_name, [...curr, i]);
        }
      });

      const seqMap = new Map();
      secNames.forEach((item) => {
        if (
          !seqMap.has(item.sec_name) ||
          item.sSeq < seqMap.get(item.sec_name)
        ) {
          seqMap.set(item.sec_name, item.sSeq);
        }
      });
      secNames.sort((a, b) => seqMap.get(a.sec_name) - seqMap.get(b.sec_name));

      const uniqueSecNames = [];

      secNames.forEach((item) => {
        const existingItem = uniqueSecNames.find(
          (uniqueItem) => uniqueItem.sec_name === item.sec_name
        );
        if (existingItem) {
          if (item.sSeq < existingItem.sSeq) {
            Object.assign(existingItem, item);
          }
        } else {
          uniqueSecNames.push({ ...item });
        }
      });

      const combinedSecNames = [...mergeMap.keys()];

      const { data: commVal } = await apiCall(
        `/analysis/communicationScore/${AID}/${UID}`
      );

      if (Object.keys(commVal).length > 0) {
        setNameList([...combinedSecNames, "Communication"]);
        uniqueSecNames.push({ sec_name: "Communication" });
        const orderedNameList = uniqueSecNames.map((item) => item.sec_name);
        setSeqNameList(orderedNameList);
      } else {
        setNameList(combinedSecNames);
        const orderedNameList = uniqueSecNames.map((item) => item.sec_name);
        setSeqNameList(orderedNameList);
      }

      // const { data: userMotivationScores } = await apiCall(`/analysis/motivationscore/${AID}/${UID}`);
      // setUserMotivation(userMotivationScores);

      const {
        data: { secData, color },
      } = await apiCall(`analysis/sectionscorelist/${AID}/${UID}`);
      //=================================================================== The code below is to select the most recent position for each user and display it in the radar chart, replace secData with sercData in setScoreData and other places to use this code
      // let positions = {
      //   "Past Position": null,
      //   "Present Position": null,
      //   "Future Position": null
      // }
      // const extractDate = (label) => {
      //   const datePart = label.match(/(\w{3} \d{1,2}, \d{4})/)[0];
      //   //date parsing may cause issues in some browsers so maybe use moment.js
      //   const parsedDate = new Date(datePart);
      //   return parsedDate
      // }

      // secData.forEach((item,i) => {
      //   const itemDate = extractDate(item.label);
      //   const positionKey = item.label.split(" ")[3] + ' Position';
      //   if(item.UAID === UAID) {
      //     positions={...positions, [positionKey]: item};
      //   }
      //   else {
      //     if(positions[positionKey]) {
      //       const storedDate = extractDate(positions[positionKey].label);
      //       if(itemDate > storedDate && positions[positionKey].UAID !== UAID) {
      //         positions={...positions, [positionKey]: item};
      //       }
      //     } else {
      //       positions={...positions, [positionKey]: item};
      //     }
      //   }
      // })

      // const placeholder = ['Past Position', 'Present Position', 'Future Position'].map((key) => positions[key]).filter(item => item !== null);
      // let sercData = []
      // if(placeholder.length > 1) {
      //  sercData = placeholder.map((item,i) => {
      //     return{
      //       ...item,
      //       backgroundColor: `rgba(${acolorfix[i][0]},${acolorfix[i][1]},${acolorfix[i][2]}, 0.3)`
      //     }
      //   })
      // } else {
      //   sercData = [{ ...placeholder[0], backgroundColor: `rgba(${acolorfix[1][0]},${acolorfix[1][1]},${acolorfix[1][2]}, 0.3)` }]
      // }
      //===================================================================

      //=================================================================== The code below to to filter the latest three attempts of present positions
      // const extractDate = (label) => {
      //   const datePart = label.match(/(\w{3} \d{1,2}, \d{4})/)[0];
      //   //date parsing may cause issues in some browsers so maybe use moment.js
      //   const parsedDate = new Date(datePart);
      //   return parsedDate
      // }

      // let presentPositions = secData
      //   .filter(item => item.label.includes("Present Position"))
      //   .map(item => ({
      //     ...item,
      //     parsedDate: extractDate(item.label),
      //     label: item.label.replace("Present Position", "")
      //   }))
      //   .sort((a, b) => a.parsedDate - b.parsedDate);

      // let latestThree = presentPositions.slice(0, 3);

      // const currentAttempt = secData.find(item => item.UAID === UAID && item.label.includes("Present Position"));
      // if (currentAttempt && !latestThree.find(item => item.UAID === UAID)) {
      //   const oldestAttempt = latestThree[latestThree.length - 1];
      //   const currentAttemptDate = extractDate(currentAttempt.label);
      //   latestThree[latestThree.length - 1] = {
      //     ...currentAttempt,
      //     label: currentAttempt.label.replace("Present Position", ""),
      //     parsedDate: currentAttemptDate
      //   };
      // }
      // let sercData = [];
      // if(latestThree.length > 1) {
      //   sercData = latestThree.map(({parsedDate, ...item},index) => {
      //     return {
      //       ...item,
      //       backgroundColor: `rgba(${acolorfix[index][0]},${acolorfix[index][1]},${acolorfix[index][2]}, 0.2)`,
      //       borderColor: `rgba(${acolorfix[index][0]},${acolorfix[index][1]},${acolorfix[index][2]}, 0.7)`
      //     }
      //   });
      // } else {
      //   sercData = [{ ...latestThree[0], backgroundColor: `rgba(${acolorfix[0][0]},${acolorfix[0][1]},${acolorfix[0][2]}, 0.2)`, borderColor: `rgba(${acolorfix[0][0]},${acolorfix[0][1]},${acolorfix[0][2]}, 0.7)` }]
      // }

      //===================================================================

      //=================================================================== The code below to to filter the latest three attempts of present positions
      const extractDate = (label) => {
        const datePart = label.match(/(\w{3} \d{1,2}, \d{4})/)[0];
        //date parsing may cause issues in some browsers so maybe use moment.js
        const parsedDate = new Date(datePart);
        return parsedDate;
      };

      let presentPositions = secData
        .map((item) => ({
          ...item,
          parsedDate: extractDate(item.label),
          label: item.label.includes("Present Position")
            ? item.label.replace("Present Position", "")
            : item.label.includes("Past Position")
            ? item.label.replace("Past Position", "")
            : item.label.includes("Future Position")
            ? item.label.replace("Future Position", "")
            : item.label,
        }))
        .sort((a, b) => a.parsedDate - b.parsedDate);

      let latestThree = presentPositions.slice(-3);

      const currentAttempt = secData.find((item) => item.UAID === UAID);
      if (currentAttempt && !latestThree.find((item) => item.UAID === UAID)) {
        const currentAttemptDate = extractDate(currentAttempt.label);
        latestThree[0] = {
          ...currentAttempt,
          label: currentAttempt.label.includes("Present Position")
            ? currentAttempt.label.replace("Present Position", "")
            : currentAttempt.label.includes("Past Position")
            ? currentAttempt.label.replace("Past Position", "")
            : currentAttempt.label.includes("Future Position")
            ? currentAttempt.label.replace("Future Position", "")
            : currentAttempt.label,
          parsedDate: currentAttemptDate,
        };
      }
      let sercData = [];
      if (latestThree.length > 1) {
        sercData = latestThree.map(({ parsedDate, ...item }, index) => {
          return {
            ...item,
            backgroundColor: `rgba(${acolorfix[index][0]},${acolorfix[index][1]},${acolorfix[index][2]}, 0.2)`,
            borderColor: `rgba(${acolorfix[index][0]},${acolorfix[index][1]},${acolorfix[index][2]}, 0.7)`,
          };
        });
      } else {
        sercData = [
          {
            ...latestThree[0],
            backgroundColor: `rgba(${acolorfix[0][0]},${acolorfix[0][1]},${acolorfix[0][2]}, 0.2)`,
            borderColor: `rgba(${acolorfix[0][0]},${acolorfix[0][1]},${acolorfix[0][2]}, 0.7)`,
          },
        ];
      }

      //===================================================================

      setScoreData(
        sercData.map((scoreObj) => {
          let updatedData = scoreObj.data;
          if (combinedSecNames.length !== secNames.length) {
            updatedData = new Array(combinedSecNames.length);
            combinedSecNames.forEach((secName, i) => {
              let sum = 0;
              mergeMap.get(secName).forEach((index) => {
                sum += scoreObj.data[index];
              });
              updatedData[i] = sum / mergeMap.get(secName).length;
            });
          }
          if (Object.keys(commVal).length > 0)
            updatedData = [...updatedData, commVal[scoreObj.UAID] || 0];

          return {
            ...scoreObj,
            data: [...updatedData],
          };
        })
      );
      setColorArr(color);

      const { data: hcsScore } = await apiCall(
        `analysis/hcsscore/${AID}/${UAID}`
      );
      const colorInd = sercData
        .map((o, ind) => (o.UAID === UAID ? ind : -1))
        .filter((ind) => ind !== -1)[0];
      const orderedHcsScore = [
        { ...hcsScore[0], color: "rgba(55, 169, 255)" /*color[colorInd]*/ },
      ];
      setColorIndex(colorInd);
      setHCSValue(orderedHcsScore);

      if (hcsScore?.length > 0) setHCSEmpty(false);

      // const mBData = await apiCall(`analysis/motivscorelist/${AID}/${UID}`);
      // setMotivBarData(mBData["data"]);

      // if (mBData["data"].length !== 0) setMotivEmpty(false);

      // const cuArBData = await apiCall(`analysis/cuarscorelist/${AID}/${UID}`);
      // setCuArBarData(cuArBData["data"]);

      const { data: sectionGraphs } = await apiCall(
        `/analysis/sectiongraphs/${AID}/${UID}/${UAID}`
      );
      setSectionGraphs(sectionGraphs);

      // if (cuArBData["data"].length !== 0) setCuArEmpty(false);

      setRetHCSValue(sercData.map((_) => -1));

      const assmtName = await apiCall(`admin/getAssmtName/${AID}`);
      setAssmtName(assmtName["data"][0]["name"]);
    }
    async function getGrpAssmtAnalysisData() {
      const { data } = await apiCall(`analysis/assmtnamelist/${AID}`);
      const nameList = data.map((obj) => obj.name);
      setNameList(nameList);
      setSeqNameList(nameList);

      const secScore = await apiCall(`analysis/assmtscorelist/${AID}/${UID}`);
      let temp = [];
      await data.forEach((obj, i) => {
        secScore["data"]["assmtData"][0]["data"].forEach((scoreObj, j) => {
          if (scoreObj.AID === obj.AID) {
            temp.push(scoreObj.score);
          }
        });
      });
      secScore["data"]["assmtData"][0]["data"] = temp;
      setScoreData(secScore["data"]["assmtData"]);
      setColorArr(secScore["data"]["color"]);

      const assmtName = await apiCall(`admin/getAssmtName/${AID}`);
      setAssmtName(assmtName["data"][0]["name"]);
    }
    async function getAssmtData(AID) {
      const { data } = await apiCall(`admin/getAssmtDetailsByID/${AID}`);
      setAssmtType(data[0]["type"]);
    }
    if (!assmtType) getAssmtData(AID);
    else if (assmtType === "individual") getIndiAssmtAnalysisData();
    else if (assmtType === "group") getGrpAssmtAnalysisData();

    async function getUserDetails() {
      const { data } = await apiCall(`analysis/getuserdetails/${UID}`);
      setUserAssmtObj(data);
    }
    getUserDetails();
  }, [loggedInUser.jwtToken, assmtType, AID, UID]);

  useEffect(() => {
    const getSections = async (AID) => {
      const { data } = await apiCall(`admin/getSections/${AID}`);
      setSecObj(data);
    };
    const getGrpAssmts = async (AGID) => {
      const { data } = await apiCall(`admin/getGrpAssmtByID/${AGID}`);
      //setSecObj(data);
    };
    if (loggedInUser.jwtToken && assmtType === "individual") getSections(AID);
    if (loggedInUser.jwtToken && assmtType === "group") getGrpAssmts(AGID);
    const getAssmt = async (AID) => {
      const { data } = await apiCall(`admin/getAssmtDetailsByID/${AID}`);
      setAssmtObj(data[0]);
    };
    getAssmt(AID);
  }, [AID, loggedInUser.jwtToken, assmtType, AGID]);

  useEffect(() => {
    const getUserAssmtData = async () => {
      const assmtData = await apiCall(
        `admin/getResponseRefresh/${AID}/${UAID}`
      );
      await assmtData["data"].forEach((obj) => {
        obj.referenceDate = moment.utc(obj.referenceDate).format("MM/DD/YYYY");
        obj.start_Tstamp = moment
          .utc(obj.start_Tstamp)
          .tz("America/New_York")
          .format("MM/DD/YYYY hh:mm:ss A");
        obj.end_Tstamp = moment
          .utc(obj.end_Tstamp)
          .tz("America/New_York")
          .format("MM/DD/YYYY hh:mm:ss A");
      });
      setUserRespObj(assmtData["data"][0]);
    };

    const getUserGrpAssmtData = async () => {
      const assmtData = await apiCall(
        `admin/getResponseRefresh/${AGID}/${UAID}`
      );
      await assmtData["data"].forEach((obj) => {
        obj.referenceDate = moment.utc(obj.referenceDate).format("MM/DD/YYYY");
        obj.start_Tstamp = moment
          .utc(obj.start_Tstamp)
          .tz("America/New_York")
          .format("MM/DD/YYYY hh:mm:ss A");
        obj.end_Tstamp = moment
          .utc(obj.end_Tstamp)
          .tz("America/New_York")
          .format("MM/DD/YYYY hh:mm:ss A");
      });
      setUserRespObj(assmtData["data"][0]);
    };

    if (
      userRespObj.length === 0 &&
      (AGID === "undefined" || assmtType === "individual")
    )
      getUserAssmtData();
    else if (userRespObj.length === 0 && AGID && assmtType === "group")
      getUserGrpAssmtData();
  }, [UAID, loggedInUser.jwtToken, userRespObj, AGID, assmtType]);

  useEffect(() => {
    const getSectionAnalysisData = async (AID, UAID, secObj) => {
      const { data } = await apiCall(
        `analysis/secscore/${AID}/${UAID}`,
        "POST",
        { responses: secObj }
      );
      const { data: communicationScore } = await apiCall(
        `analysis/communicationScore/${AID}/${userRespObj.UID}`
      );
      const formattedData = data.map((item) => ({
        ...item,
        sec_score:
          Math.floor(item.sec_score) === item.sec_score
            ? `${item.sec_score}.0`
            : item.sec_score,
      }));
      if (communicationScore[UAID]) {
        setSectionAnalysisData([
          ...formattedData,
          {
            sec_no: "Communication",
            sec_score: Number.isInteger(communicationScore)
              ? `${communicationScore}.0`
              : Number(communicationScore[UAID]).toFixed(1),
          },
        ]);
      } else setSectionAnalysisData(formattedData);
    };
    const getSectionAssmtanalysisData = async () => {
      if (sectionAnalysisData.length > 0) return;
      scoreData[0].data.forEach((score, i) => {
        setSectionAnalysisData((prev) => [
          ...prev,
          {
            sec_no: i + 1,
            sec_score: Math.floor(score) === score ? `${score}.0` : score,
          },
        ]);
      });
    };
    if (secObj.length !== 0 && userRespObj.UID && assmtType === "individual")
      getSectionAnalysisData(AID, UAID, secObj);
    if (scoreData.length !== 0 && userRespObj.UID && assmtType === "group")
      getSectionAssmtanalysisData();
  }, [secObj, loggedInUser.jwtToken, userRespObj, scoreData, assmtType]);

  useEffect(() => {
    if (scoreData.length > 0) {
      let index = seqNameList.map((item) => nameList.indexOf(item));
      let newSeqScoreData = [...scoreData];
      for (let i = 0; i < scoreData.length; i++) {
        let temp = [];
        for (let j = 0; j < scoreData[i].data.length; j++) {
          temp.push(scoreData[i].data[index[j]]);
        }
        newSeqScoreData = newSeqScoreData.map((item) =>
          item.UAID === scoreData[i].UAID
            ? { ...scoreData[i], data: temp }
            : item
        );
      }
      setSeqScoreData(newSeqScoreData);
    }
  }, [scoreData, nameList, seqNameList]);

  useEffect(() => {
    if (sectionAnalysisData.length > 0 && nameList.length > 0) {
      let temp = [];
      sectionAnalysisData.forEach((item, index) => {
        if (item.sec_no === "Communication") {
          temp.push({
            sec_no: "Communication",
            sec_score: item.sec_score,
          });
        } else {
          temp.push({
            sec_no: seqNameList[index],
            sec_score: item.sec_score,
          });
        }
      });
      setNamedAnalysisData(temp);
    }
  }, [sectionAnalysisData, seqNameList]);

  const radarOptions = {
    scales: {
      r: {
        angleLines: {
          display: true,
          color: "rgba(104, 105, 107, 0.5)", //"#68696B",//"#B9D9EB",
          centerPointLabels: true,
        },
        pointLabels: {
          callback: function (label, index, labels) {
            const ctx = this.chart.ctx;
            const maxWidth = 90;
            const words = label.split(" ");
            let finalLines = [];
            let currentLine = words[0];

            // Directly specify the font properties
            ctx.font = "13px Montserrat"; // Example: Replace with your desired font

            for (let i = 1; i < words.length; i++) {
              const word = words[i];
              const width = ctx.measureText(currentLine + " " + word).width;
              if (width < maxWidth) {
                currentLine += " " + word;
              } else {
                finalLines.push(currentLine);
                currentLine = word;
              }
            }
            finalLines.push(currentLine);
            return finalLines;
          },
          font: {
            size: 13,
            family: "Montserrat",
          },
        },
        ticks: {
          // count: 5,
          backdropColor: "rgba(0, 0, 0, 0)",
          stepSize: 1,
          display: false,
        },
        grid: {
          color: "rgba(104, 105, 107, 0.5)", //"#68696B",//"#B9D9EB",
        },
        beginAtZero: true,
        min: 0,
        max: 5,
        // startAngle: 20
      },
    },
    plugins: {
      datalabels: {
        display: false,
        align: "end",
        color: "black",
        font: {
          weight: "bold",
        },
      },
      // colors: {
      //     forceOverride: true
      // },
      legend: {
        onClick: function (e, legendItem, legend) {
          const index = legendItem.datasetIndex;
          const ci = legend.chart;
          if (ci.isDatasetVisible(index)) {
            ci.hide(index);
            legendItem.hidden = true;
          } else {
            ci.show(index);
            legendItem.hidden = false;
          }

          let hidden = legendItem.hidden;
          if (hidden && colorIndex === index) {
            let newRetHcsData = [...retHcsValue];
            newRetHcsData[index] = hcsValue[0];
            setRetHCSValue(newRetHcsData);
            setHCSValue([]);
          } else {
            if (hcsValue.length === 0 && colorIndex === index) {
              let newRetHcsData = [...retHcsValue];
              let data = newRetHcsData[index];
              newRetHcsData[index] = -1;
              setRetHCSValue(newRetHcsData);
              setHCSValue([data]);
            }
          }
        },
        labels: {
          usePointStyle: true,
          textAlign: "center",
          font: "normal normal medium 10px/13px Montserrat",
          letterSpacing: "0px",
          color: "#68696B",
          opacity: 1,
          padding: 20,
        },
      },
    },
  };

  let columns = [];
  if (assmtType === "individual") {
    columns = [
      {
        name: "Section Name",
        selector: (row) => row["sec_no"],
        sortable: true,
        wrap: true,
        minWidth: "13em",
      },
      {
        name: "Score",
        selector: (row) => row["sec_score"],
        sortable: true,
        style: {
          marginLeft: "1em",
        },
      },
    ];
  } else {
    columns = [
      {
        name: "Assessment Name",
        selector: (row) => row["sec_no"],
        sortable: true,
        wrap: true,
        minWidth: "14em",
      },
      {
        name: "Score",
        selector: (row) => row["sec_score"],
        sortable: true,
      },
    ];
  }

  let radarData = {};
  if (assmtType === "group") {
    radarData = {
      labels: nameList,
      datasets: scoreData,
    };
  } else {
    radarData = {
      labels: seqNameList,
      datasets: seqScoreData,
    };
  }

  const handleSendEmail = () => {
    if (emailInput.trim() === "") {
      alert("Email address is required to send the email.");
      return;
    }

    if (!validator.isEmail(emailInput.trim())) {
      alert("Please enter a valid email address.");
      return;
    }

    setRecipientEmail(emailInput.trim());
    // Do not close the modal here
    setPdfGenerating(true);
    setSendEmail(true);
  };

  const handleCancelEmail = () => {
    setShowModal(false);
    setEmailInput("");
    setEmailStatusMessage(""); // Reset the message
  };

  useEffect(() => {
    if (pdfGenerating) {
      const cloneDiv = document.getElementById("divToPrint");
      html2canvas(cloneDiv, { scale: 1 })
        .then(async (canvas) => {
          const imgData = canvas.toDataURL("image/jpeg", 0.85);
          const pdf = new jsPDF({
            orientation: "portrait",
            unit: "mm",
            format: "a4",
            compressPdf: true,
          });
          const imgProps = pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * 12.7;
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          pdf.addImage(imgData, "JPEG", 12.7, 12.7, pdfWidth, pdfHeight);

          const pdfBlob = pdf.output("blob");

          if (sendEmail) {
            // Create a FormData object
            const formData = new FormData();
            formData.append("subject", `Helix: "${assmtName}"`);
            formData.append(
              "text",
              `Assessment: "${assmtName}", Reference Date: ${userRespObj.referenceDate}.`
            );
            formData.append(
              "filename",
              `${userRespObj.first_name}_${userRespObj.last_name}_${userRespObj.referenceDate}.pdf`
            );
            formData.append(
              "pdf",
              pdfBlob,
              `${userRespObj.first_name}_${userRespObj.last_name}_${userRespObj.referenceDate}.pdf`
            );
            formData.append("recipientEmail", recipientEmail);

            try {
              const response = await apiCall(
                `analysis/pdfemail`,
                "POST",
                formData,
                {
                  headers: {
                    // Do not set 'Content-Type' header when sending FormData
                  },
                }
              );

              if (response) {
                console.log("PDF sent to server successfully!", response.data);
                setEmailStatusMessage(
                  "Send to Email request has been submitted successfully!"
                );
                setEmailStatusVariant("success");
              } else {
                console.log("PDF not sent to server!");
                setEmailStatusMessage("Failed to send email.");
                setEmailStatusVariant("danger");
              }
            } catch (error) {
              console.error("Error sending PDF to server:", error);
              setEmailStatusMessage(
                "Error sending email. Please try again later."
              );
              setEmailStatusVariant("danger");
            }
          } else {
            pdf.save(
              `${userRespObj.first_name}_${userRespObj.last_name}_${userRespObj.referenceDate}.pdf`
            );
          }
        })
        .finally(() => {
          setPdfGenerating(false);
          setSendEmail(false);
          setRecipientEmail("");
          setEmailInput("");
        });
    }
  }, [pdfGenerating, sendEmail]);

  // useEffect(() => {
  //   const chunkArray = async (array, size) =>  {
  //     const chunkedArr = [];
  //     for (let i = 0; i < array.length; i += size) {
  //       chunkedArr.push(array.slice(i, i + size));
  //     }
  //     setChunky(chunkedArr);
  //   }
  //   if (Object.keys(sectionGraphs).length > 1) {
  //     const valuesToChunk = Object.values(sectionGraphs).slice(1);
  //     chunkArray(valuesToChunk, 3);
  //   }
  // }, [sectionGraphs]);

  const printDocument = async () => {
    setPdfGenerating(true);
  };

  const EmailDocument = () => {
    setShowModal(true); // Show the modal
    setEmailStatusMessage(""); // Reset the message
  };

  const ExportCSV = async () => {
    let exportData = await exportSingleResToCSV(UID, UAID, AID);
    setUserRes(exportData);
    setBodyName(
      `${userRespObj.first_name} ${userRespObj.last_name}'s response is generated as .csv file and ready to download!`
    );
    setCSVfilename(
      `${UID} ${UAID} ${userRespObj.first_name} ${
        userRespObj.last_name
      } ${new Date(userRespObj.end_Tstamp).toLocaleDateString()}.csv`
    );
    handleShow();
  };

  return (
    <div className="BySection">
      {loginStatus === true ? (
        <>
          <Helmet>
            <title>
              Helix Employability Tool | Sectionwise Assessment Analysis
            </title>
          </Helmet>
          <Navigate />
          <Stack direction="horizontal" gap={3}>
            <h6>
              {loggedInUser.group === "Admin" ? (
                <>
                  <a
                    href="#"
                    onClick={() => navigate("/admin/viewAllResponse/" + AID)}
                  >
                    {" "}
                    &lt;Back{" "}
                  </a>
                </>
              ) : (
                <>
                  <a href="#" onClick={() => navigate("/user/results/" + UID)}>
                    &lt;Back
                  </a>
                </>
              )}
            </h6>
          </Stack>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>{assmtName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{bodyName}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <CSVLink
                data={userRes}
                headers={[
                  { label: "Section Name", key: "sec_name" },
                  { label: "Question", key: "question" },
                  { label: "Type", key: "type" },
                  { label: "Choice picked", key: "choice" },
                  { label: "Weightage of choice", key: "weightage" },
                  { label: "Weightage of response", key: "response" },
                ]}
                filename={csvFilename}
                target="_blank"
              >
                <Button variant="success" onClick={handleClose}>
                  Download
                </Button>
              </CSVLink>
            </Modal.Footer>
          </Modal>
          <Card>
            <Card.Body>
              <Stack direction={isMobile ? "vertical" : "horizontal"} gap={3}>
                <Stack direction="vertical" gap={2}>
                  <Card.Title
                    style={{
                      textAlign: "left",
                      font: "normal normal 700 18px/19px Montserrat",
                      letterSpacing: "0px",
                      color: "#111314",
                      opacity: "1",
                      marginTop: "0.4em",
                    }}
                  >
                    {userRespObj.first_name}&nbsp;{userRespObj.last_name}'s
                    response analysis
                  </Card.Title>
                  <label className="mb-2 text-left font-medium text-[12px] leading-[15px] tracking-normal text-[#68696B] opacity-100">
                    Assessment: "{assmtName}"
                  </label>
                </Stack>
                {isMobile ? (
                  <Stack direction="horizontal" gap={2}>
                    <Button
                      onClick={ExportCSV}
                      style={{
                        background:
                          "linear-gradient(180deg, #4375F4 0%, #1855F1 100%) no-repeat padding-box",
                        boxShadow: "0px 10px 20px #0000001A",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        height: "38px",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "center",
                          font: "normal normal 600 13px/16px Montserrat",
                          letterSpacing: "0px",
                        }}
                      >
                        <text>Export to CSV</text>
                      </div>
                    </Button>
                    <Button
                      onClick={printDocument}
                      style={{
                        background:
                          "linear-gradient(180deg, #4375F4 0%, #1855F1 100%) no-repeat padding-box",
                        boxShadow: "0px 10px 20px #0000001A",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        height: "38px",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "center",
                          font: "normal normal 600 13px/16px Montserrat",
                          letterSpacing: "0px",
                        }}
                      >
                        <text>Export to PDF</text>
                      </div>
                    </Button>
                  </Stack>
                ) : (
                  <>
                    <Button
                      onClick={ExportCSV}
                      style={{
                        background:
                          "linear-gradient(180deg, #4375F4 0%, #1855F1 100%) no-repeat padding-box",
                        boxShadow: "0px 10px 20px #0000001A",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        height: "38px",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "center",
                          font: "normal normal 600 13px/16px Montserrat",
                          letterSpacing: "0px",
                        }}
                      >
                        <text>Export to CSV</text>
                      </div>
                    </Button>
                    <Button
                      onClick={printDocument}
                      style={{
                        background:
                          "linear-gradient(180deg, #4375F4 0%, #1855F1 100%) no-repeat padding-box",
                        boxShadow: "0px 10px 20px #0000001A",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        height: "38px",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "center",
                          font: "normal normal 600 13px/16px Montserrat",
                          letterSpacing: "0px",
                        }}
                      >
                        <text>Export to PDF</text>
                      </div>
                    </Button>
                    <Button
                      onClick={EmailDocument}
                      style={{
                        background:
                          "linear-gradient(180deg, #4375F4 0%, #1855F1 100%) no-repeat padding-box",
                        boxShadow: "0px 10px 20px #0000001A",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        height: "38px",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "center",
                          font: "normal normal 600 13px/16px Montserrat",
                          letterSpacing: "0px",
                        }}
                      >
                        <text>Send to Email</text>
                      </div>
                    </Button>
                    {/* Modal Component */}
                    <Modal show={showModal} onHide={handleCancelEmail} centered>
                      <Modal.Header closeButton>
                        <Modal.Title>Send to Email</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form>
                          <Form.Group controlId="formRecipientEmail">
                            <Form.Label>Recipient Email:</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Enter email"
                              value={emailInput}
                              onChange={(e) => setEmailInput(e.target.value)}
                              autoFocus
                              disabled={pdfGenerating}
                            />
                          </Form.Group>
                        </Form>
                        {emailStatusMessage && (
                          <Alert
                            variant={emailStatusVariant}
                            style={{ marginTop: "1rem" }}
                          >
                            {emailStatusMessage}
                          </Alert>
                        )}
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleCancelEmail}>
                          Cancel
                        </Button>
                        <Button
                          variant="primary"
                          onClick={handleSendEmail}
                          disabled={pdfGenerating}
                        >
                          {pdfGenerating ? (
                            <>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />{" "}
                              Sending...
                            </>
                          ) : (
                            "Send"
                          )}
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </>
                )}
              </Stack>
            </Card.Body>
          </Card>
          <div
            className="response"
            style={{ marginTop: "25px", paddingBottom: "8rem" }}
            ref={componentRef}
          >
            <div style={{ width: isMobile ? "100%" : "25%" }}>
              <Stack gap={4}>
                <Card>
                  <Card.Body>
                    <Card.Title
                      style={{
                        textAlign: "left",
                        font: "normal normal 700 18px/19px Montserrat",
                        letterSpacing: "0px",
                        color: "#111314",
                        opacity: "1",
                      }}
                    >
                      Survey Information
                    </Card.Title>
                    <label className="mt-3 md:mt-2 text-muted text-left font-normal text-[12px] leading-[20px] tracking-normal text-[#68696B] opacity-100">
                      Email ID: {userRespObj.email_ID}
                    </label>
                    <br></br>
                    <label className="mt-2 md:mt-1 text-muted text-left font-normal text-[12px] leading-[20px] tracking-normal text-[#68696B] opacity-100">
                      Position: {userRespObj.positionOption}
                    </label>
                    <br></br>
                    <label className="mt-2 md:mt-1 text-muted text-left font-normal text-[12px] leading-[20px] tracking-normal text-[#68696B] opacity-100">
                      Title: {userRespObj.positionTitle}
                    </label>
                    <br></br>
                    <label className="mt-2 md:mt-1 text-muted text-left font-normal text-[12px] leading-[20px] tracking-normal text-[#68696B] opacity-100">
                      Start time: {userRespObj.start_Tstamp}
                    </label>
                    <br></br>
                    {loggedInUser.group !== "Admin" &&
                    assmtObj.url &&
                    assmtObj.url !== "" ? (
                      <>
                        <label className="mt-2 md:mt-1 text-muted text-left font-normal text-[12px] leading-[20px] tracking-normal text-[#68696B] opacity-100">
                          End time: {userRespObj.end_Tstamp}
                        </label>{" "}
                        <br></br>
                        <label
                          className="mt-2 md:mt-1 mb-2 md:mb-1 text-muted text-left font-normal text-[12px] leading-[20px] tracking-normal text-[#68696B] opacity-100"
                          id="cbsjcnh"
                        >
                          To learn about your results please visit{" "}
                          {assmtObj.url.split(",").map((url, index, array) => (
                            <React.Fragment key={index}>
                              <a
                                href={url.trim()}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(e) => {
                                  if (
                                    !window.confirm(
                                      "You will be leaving helix.com. Are you sure?"
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              >
                                {url.trim()}
                              </a>
                              {index !== array.length - 1 && ",   "}
                            </React.Fragment>
                          ))}
                        </label>
                        <br></br>
                      </>
                    ) : (
                      <>
                        <label className="mb-2 mt-2 text-muted text-left font-normal text-[12px] leading-[20px] tracking-normal text-[#68696B] opacity-100">
                          End time: {userRespObj.end_Tstamp}
                        </label>{" "}
                        <br></br>
                      </>
                    )}
                  </Card.Body>
                </Card>{" "}
                <Card>
                  <Card.Body
                    style={{ paddingBottom: "0px", paddingTop: "1rem" }}
                  >
                    <DataTable
                      title={
                        // <span>Analysis: <span style={{ marginRight: '20px' }}></span>{new Date(userRespObj.referenceDate).toLocaleDateString()}</span>
                        <div id="adbcdxhja" style={{ textAlign: "start" }}>
                          <div
                            style={{
                              textAlign: "left",
                              font: "normal normal 600 17px/19px Montserrat",
                              letterSpacing: "0px",
                              color: "#111314",
                            }}
                          >
                            Analysis
                          </div>
                          <div
                            style={{
                              color: "#68696B",
                              textAlign: "left",
                              fontSize: "12px",
                              lineHeight: "20px",
                              letterSpacing: "normal",
                              opacity: 1,
                              textAlign: "left",
                              marginTop: "5px",
                              wordWrap: "break-word",
                              overflowWrap: "break-word",
                            }}
                          >
                            Reference Date: {userRespObj.referenceDate}
                          </div>
                        </div>
                      }
                      columns={columns}
                      data={namedAnalysisData}
                      defaultSortField="Section Name"
                      pagination={false}
                      paginationComponentOptions={{ noRowsPerPage: false }}
                    />
                    <br></br>
                  </Card.Body>
                </Card>
              </Stack>
            </div>
            <div
              className="res"
              style={{
                width: isMobile ? "100%" : "75%",
                maxHeight: "90vh",
                maxHeight: "1000px",
              }}
            >
              <div className="response-body">
                <Card style={{ height: "fit-content" }}>
                  <Card.Title>
                    <h5
                      style={{
                        textAlign: "left",
                        marginTop: "0.9rem",
                        font: "normal normal 600 18px/19px Montserrat",
                        letterSpacing: "0px",
                        color: "#111314",
                        marginLeft: "1rem",
                      }}
                    >
                      {assmtName}
                    </h5>
                  </Card.Title>
                  <Card.Body id="aewudn">
                    <Radar
                      data={radarData}
                      options={radarOptions}
                      style={{ marginBottom: "29px" }}
                    />
                  </Card.Body>
                </Card>
              </div>
              <div className="response-body-2">
                <Stack gap={4}>
                  {hcsEmpty === false && hcsValue.length !== 0 ? (
                    <Card
                      style={{
                        width: "100%",
                        height: "fit-content",
                        maxHeight: isMobile ? "15.5rem" : "13.5rem",
                      }}
                    >
                      <Card.Body>
                        <div style={{ height: "100%", width: "100%" }}>
                          <h5
                            style={{
                              textAlign: "left",
                              marginBottom: "26px",
                              font: "normal normal 600 17px/19px Montserrat",
                              letterSpacing: "0px",
                              color: "#111314",
                            }}
                          >
                            Big Picture Thinking
                          </h5>
                          <div
                            style={{
                              display: "flex",
                              position: "relative",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            <Stack
                              direction="vertical"
                              gap={3}
                              style={{ alignItems: "center" }}
                            >
                              <div
                                style={{
                                  width: "75%",
                                  height: "34%",
                                  alignSelf: "center",
                                  position: "relative",
                                }}
                              >
                                <RoundyWrapper
                                  value={hcsValue[0].score}
                                  min={0}
                                  max={5}
                                  arcSize={180}
                                  rotationOffset={0}
                                  color="rgba(242, 242, 242, 1)"
                                  strokeWidth={12}
                                  rounded={true}
                                  thumbSize={20}
                                  radiusPercentage={50}
                                  overrideStyle={`.sliderHandle{
                                          width: ${
                                            isMobile ? "53%" : "54%"
                                          }!important;
                                          background: ${hcsValue[0].color};
                                          pointer-events: none;
                                        }
                                        .sliderHandle::after{
                                          width: 20px;
                                          height: 20px;
                                          background: ${hcsValue[0].color};
                                        }
                                        svg path {
                                          opacity: 1;
                                          stroke: rgba(242, 242, 242, 1);
                                          stroke-linecap: round;
                                        }`}
                                />
                                <div
                                  style={{
                                    position: "relative",
                                    width: "26%",
                                    paddingTop: "26%",
                                    left: "37%",
                                    top: "-45%",
                                    transform: "translateY(-50%)",
                                    backgroundColor: "#2F55E81A",
                                    borderRadius: "50%",
                                    overflow: "visible",
                                  }}
                                >
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      right: 0,
                                      bottom: 0,
                                      left: 0,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      textAlign: "center",
                                      font: "normal normal medium 16px/19px Montserrat",
                                      color: "#68696B",
                                    }}
                                  >
                                    {hcsValue[0].score.toFixed(1)}
                                  </div>
                                </div>
                              </div>
                              <Stack
                                direction="horizontal"
                                gap={5}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <h6
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "12px",
                                    lineHeight: "15px",
                                    fontFamily: "Montserrat, sans-serif",
                                    letterSpacing: "0px",
                                    color: "#68696B",
                                    opacity: 1,
                                    position: "absolute",
                                    top: "30%",
                                    left: "22%",
                                  }}
                                >
                                  0.0
                                </h6>
                                <h6
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "12px",
                                    lineHeight: "15px",
                                    fontFamily: "Montserrat, sans-serif",
                                    letterSpacing: "0px",
                                    color: "#68696B",
                                    opacity: 1,
                                  }}
                                >
                                  more analytic
                                </h6>
                                <h6
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "12px",
                                    lineHeight: "15px",
                                    fontFamily: "Montserrat, sans-serif",
                                    letterSpacing: "0px",
                                    color: "#68696B",
                                    opacity: 1,
                                  }}
                                >
                                  more holistic
                                </h6>
                                <h6
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "12px",
                                    lineHeight: "15px",
                                    fontFamily: "Montserrat, sans-serif",
                                    letterSpacing: "0px",
                                    color: "#68696B",
                                    opacity: 1,
                                    position: "absolute",
                                    top: "30%",
                                    left: "70%",
                                  }}
                                >
                                  5.0
                                </h6>
                              </Stack>
                            </Stack>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  ) : (
                    <></>
                  )}
                  {Object.keys(sectionGraphs).length > 0 &&
                    Object.values(sectionGraphs).map((graphData, i) => (
                      <Card>
                        <Card.Body style={{ marginBottom: "10px" }}>
                          <div>
                            <Card.Title
                              style={{
                                textAlign: "left",
                                marginBottom: "10px",
                                font: "normal normal 600 17px/19px Montserrat",
                                letterSpacing: "0px",
                                color: "#111314",
                              }}
                            >
                              {" "}
                              {graphData.sectionLabel ||
                                graphData.sectionName ||
                                "Section Analysis"}
                            </Card.Title>
                            <Stack direction="vertical" gap={3}>
                              {Object.keys(graphData.labels).map(
                                (key, index) => (
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <h6
                                        style={{
                                          marginBottom: "0",
                                          textAlign: "left",
                                          font: "normal normal normal 12px/15px Montserrat",
                                          letterSpacing: "0px",
                                          color: "#68696B",
                                        }}
                                      >
                                        {key}
                                      </h6>
                                      <h6
                                        style={{
                                          marginBottom: "0",
                                          textAlign: "left",
                                          font: "normal normal normal 12px/15px Montserrat",
                                          letterSpacing: "0px",
                                          color: "#68696B",
                                        }}
                                      >
                                        {graphData.labels[key]}/5.0
                                      </h6>
                                    </div>
                                    <ProgressBar
                                      now={(graphData.labels[key] / 5) * 100}
                                      variant="info"
                                      style={{ height: "10px" }}
                                    />
                                  </div>
                                )
                              )}
                            </Stack>
                          </div>
                        </Card.Body>
                      </Card>
                    ))}
                  <br></br>
                </Stack>
              </div>
            </div>
          </div>
          <div
            className="response"
            style={{
              position: "absolute",
              top: "-9999px",
              marginTop: "25px",
              paddingBottom: "8rem",
              width: "1296px",
            }}
            id="divToPrint"
            ref={componentRef}
          >
            <div className="w-25">
              <Stack gap={4}>
                <Card>
                  <Card.Body>
                    <Card.Title
                      style={{
                        textAlign: "left",
                        font: "normal normal 700 18px/19px Montserrat",
                        letterSpacing: "0px",
                        color: "#111314",
                        opacity: "1",
                      }}
                    >
                      Survey Information
                    </Card.Title>
                    <label className="mt-3 md:mt-2 text-muted text-left font-normal text-[12px] leading-[20px] tracking-normal text-[#68696B] opacity-100">
                      Email ID: {userRespObj.email_ID}
                    </label>
                    <br></br>
                    <label className="mt-2 md:mt-1 text-muted text-left font-normal text-[12px] leading-[20px] tracking-normal text-[#68696B] opacity-100">
                      Position: {userRespObj.positionOption}
                    </label>
                    <br></br>
                    <label className="mt-2 md:mt-1 text-muted text-left font-normal text-[12px] leading-[20px] tracking-normal text-[#68696B] opacity-100">
                      Title: {userRespObj.positionTitle}
                    </label>
                    <br></br>
                    <label className="mt-2 md:mt-1 text-muted text-left font-normal text-[12px] leading-[20px] tracking-normal text-[#68696B] opacity-100">
                      Start time: {userRespObj.start_Tstamp}
                    </label>
                    <br></br>
                    {loggedInUser.group !== "Admin" &&
                    assmtObj.url &&
                    assmtObj.url !== "" ? (
                      <>
                        <label className="mt-2 md:mt-1 text-muted text-left font-normal text-[12px] leading-[20px] tracking-normal text-[#68696B] opacity-100">
                          End time: {userRespObj.end_Tstamp}
                        </label>{" "}
                        <br></br>
                        <label
                          className="mt-2 md:mt-1 mb-2 md:mb-1 text-muted text-left font-normal text-[12px] leading-[20px] tracking-normal text-[#68696B] opacity-100"
                          id="cbsjcnh"
                        >
                          To learn about your results please visit{" "}
                          {assmtObj.url.split(",").map((url, index, array) => (
                            <React.Fragment key={index}>
                              <a
                                href={url.trim()}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(e) => {
                                  if (
                                    !window.confirm(
                                      "You will be leaving helix.com. Are you sure?"
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              >
                                {url.trim()}
                              </a>
                              {index !== array.length - 1 && ",   "}
                            </React.Fragment>
                          ))}
                        </label>
                        <br></br>
                      </>
                    ) : (
                      <>
                        <label className="mb-2 mt-2 text-muted text-left font-normal text-[12px] leading-[20px] tracking-normal text-[#68696B] opacity-100">
                          End time: {userRespObj.end_Tstamp}
                        </label>{" "}
                        <br></br>
                      </>
                    )}
                  </Card.Body>
                </Card>{" "}
                <Card>
                  <Card.Body
                    style={{ paddingBottom: "0px", paddingTop: "1rem" }}
                  >
                    <DataTable
                      title={
                        // <span>Analysis: <span style={{ marginRight: '20px' }}></span>{new Date(userRespObj.referenceDate).toLocaleDateString()}</span>
                        <div id="adbcdxhja" style={{ textAlign: "start" }}>
                          <div
                            style={{
                              textAlign: "left",
                              font: "normal normal 600 17px/19px Montserrat",
                              letterSpacing: "0px",
                              color: "#111314",
                            }}
                          >
                            Analysis
                          </div>
                          <div
                            style={{
                              color: "#68696B",
                              textAlign: "left",
                              fontSize: "12px",
                              lineHeight: "20px",
                              letterSpacing: "normal",
                              opacity: 1,
                              textAlign: "left",
                              marginTop: "5px",
                              wordWrap: "break-word",
                              overflowWrap: "break-word",
                            }}
                          >
                            Reference Date: {userRespObj.referenceDate}
                          </div>
                        </div>
                      }
                      columns={columns}
                      data={namedAnalysisData}
                      defaultSortField="Section Name"
                      pagination={false}
                      paginationComponentOptions={{ noRowsPerPage: false }}
                    />
                    <br></br>
                  </Card.Body>
                </Card>
              </Stack>
            </div>
            <div
              className="res"
              style={{ width: "75%", maxHeight: "90vh", maxHeight: "1000px" }}
            >
              <div className="response-body">
                <Card style={{ height: "fit-content" }}>
                  <Card.Title>
                    <h5
                      style={{
                        textAlign: "left",
                        marginTop: "0.9rem",
                        font: "normal normal 600 18px/19px Montserrat",
                        letterSpacing: "0px",
                        color: "#111314",
                        marginLeft: "1rem",
                      }}
                    >
                      {assmtName}
                    </h5>
                  </Card.Title>
                  <Card.Body id="aewudn">
                    <Radar
                      data={radarData}
                      options={radarOptions}
                      style={{ marginBottom: "29px" }}
                    />
                  </Card.Body>
                </Card>
              </div>
              <div className="response-body-2">
                <Stack gap={4}>
                  {hcsEmpty === false && hcsValue.length !== 0 ? (
                    <Card
                      style={{
                        width: "100%",
                        height: "fit-content",
                        maxHeight: "13.5rem",
                      }}
                    >
                      <Card.Body>
                        <div style={{ height: "100%", width: "100%" }}>
                          <h5
                            style={{
                              textAlign: "left",
                              marginBottom: "26px",
                              font: "normal normal 600 17px/19px Montserrat",
                              letterSpacing: "0px",
                              color: "#111314",
                            }}
                          >
                            Big Picture Thinking
                          </h5>
                          <div
                            style={{
                              display: "flex",
                              position: "relative",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            <Stack
                              direction="vertical"
                              gap={3}
                              style={{ alignItems: "center" }}
                            >
                              <div
                                style={{
                                  width: "75%",
                                  height: "34%",
                                  alignSelf: "center",
                                  position: "relative",
                                }}
                              >
                                <Roundy
                                  value={hcsValue[0].score}
                                  min={0}
                                  max={5}
                                  arcSize={180}
                                  rotationOffset={0}
                                  color="rgba(242, 242, 242, 1)"
                                  strokeWidth={12}
                                  rounded={true}
                                  thumbSize={20}
                                  radius={101}
                                  overrideStyle={`.sliderHandle{
                                          width: 54%!important;
                                          background: ${hcsValue[0].color};
                                          pointer-events: none;
                                        }
                                        .sliderHandle::after{
                                          width: 20px;
                                          height: 20px;
                                          background: ${hcsValue[0].color};
                                        }
                                        svg path {
                                          opacity: 1;
                                          stroke: rgba(242, 242, 242, 1);
                                          stroke-linecap: round;
                                        }`}
                                />
                                <div
                                  style={{
                                    position: "relative",
                                    width: "26%",
                                    paddingTop: "26%",
                                    left: "37%",
                                    top: "-145%",
                                    transform: "translateY(-50%)",
                                    backgroundColor: "#2F55E81A",
                                    borderRadius: "50%",
                                    overflow: "visible",
                                  }}
                                >
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      right: 0,
                                      bottom: 0,
                                      left: 0,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      textAlign: "center",
                                      font: "normal normal medium 16px/19px Montserrat",
                                      color: "#68696B",
                                    }}
                                  >
                                    {hcsValue[0].score.toFixed(1)}
                                  </div>
                                </div>
                              </div>
                              <Stack
                                direction="horizontal"
                                gap={5}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <h6
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "12px",
                                    lineHeight: "15px",
                                    fontFamily: "Montserrat, sans-serif",
                                    letterSpacing: "0px",
                                    color: "#68696B",
                                    opacity: 1,
                                    position: "absolute",
                                    top: "30%",
                                    left: "22%",
                                  }}
                                >
                                  0.0
                                </h6>
                                <h6
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "12px",
                                    lineHeight: "15px",
                                    fontFamily: "Montserrat, sans-serif",
                                    letterSpacing: "0px",
                                    color: "#68696B",
                                    opacity: 1,
                                  }}
                                >
                                  more analytic
                                </h6>
                                <h6
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "12px",
                                    lineHeight: "15px",
                                    fontFamily: "Montserrat, sans-serif",
                                    letterSpacing: "0px",
                                    color: "#68696B",
                                    opacity: 1,
                                  }}
                                >
                                  more holistic
                                </h6>
                                <h6
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "12px",
                                    lineHeight: "15px",
                                    fontFamily: "Montserrat, sans-serif",
                                    letterSpacing: "0px",
                                    color: "#68696B",
                                    opacity: 1,
                                    position: "absolute",
                                    top: "30%",
                                    left: "70%",
                                  }}
                                >
                                  5.0
                                </h6>
                              </Stack>
                            </Stack>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  ) : (
                    <></>
                  )}
                  {Object.keys(sectionGraphs).length > 0 &&
                    Object.values(sectionGraphs).map((graphData, i) => (
                      <Card>
                        <Card.Body style={{ marginBottom: "10px" }}>
                          <div>
                            <Card.Title
                              style={{
                                textAlign: "left",
                                marginBottom: "10px",
                                font: "normal normal 600 17px/19px Montserrat",
                                letterSpacing: "0px",
                                color: "#111314",
                              }}
                            >
                              {" "}
                              {graphData.sectionLabel ||
                                graphData.sectionName ||
                                "Section Analysis"}
                            </Card.Title>
                            <Stack direction="vertical" gap={3}>
                              {Object.keys(graphData.labels).map(
                                (key, index) => (
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <h6
                                        style={{
                                          marginBottom: "0",
                                          textAlign: "left",
                                          font: "normal normal normal 12px/15px Montserrat",
                                          letterSpacing: "0px",
                                          color: "#68696B",
                                        }}
                                      >
                                        {key}
                                      </h6>
                                      <h6
                                        style={{
                                          marginBottom: "0",
                                          textAlign: "left",
                                          font: "normal normal normal 12px/15px Montserrat",
                                          letterSpacing: "0px",
                                          color: "#68696B",
                                        }}
                                      >
                                        {graphData.labels[key]}/5.0
                                      </h6>
                                    </div>
                                    <ProgressBar
                                      now={(graphData.labels[key] / 5) * 100}
                                      variant="info"
                                      style={{ height: "10px" }}
                                    />
                                  </div>
                                )
                              )}
                            </Stack>
                          </div>
                        </Card.Body>
                      </Card>
                    ))}
                  <br></br>
                </Stack>
              </div>
            </div>
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
};

export default AnalyzeBySection;
