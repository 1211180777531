import React, { useEffect, useState, useContext } from "react";
import EditChoiceSet from "./EditChoiceSet";
import { EditAssmtContext } from "../../Contexts/EditAssmtContext";
import { Form, Stack, Button } from "react-bootstrap";
import { ImCross } from "react-icons/im";
import { GoArrowDown, GoArrowUp } from "react-icons/go";

const EditQuestionSet = (props) => {
  const { sectionList } = useContext(EditAssmtContext);
  const [choiceList, setChoiceList] = useState(
    sectionList[props.sidx].qIDs[props.qidx].cIDs
  );
  const [tooltipText, setTooltipText] = useState(
    sectionList[props.sidx].qIDs[props.qidx]?.tooltip || ""
  );
  const [tooltipEnabled, setTooltipEnabled] = useState(!!tooltipText);
  const [tooltipWarning, setTooltipWarning] = useState("");

  let editing = props.editing;
  let textOnly = props.textOnly;

  // Set initial choice list or update from props
  useEffect(() => {
    if (props.qbChoiceList.length > 0) {
      setChoiceList(props.qbChoiceList);
    } else {
      props.handleChoiceList(choiceList, props.qidx);
    }
  }, [choiceList]);

  // Update choice list when section data changes
  useEffect(() => {
    setChoiceList(sectionList[props.sidx].qIDs[props.qidx].cIDs);
  }, [sectionList[props.sidx].qIDs[props.qidx].cIDs]);

  // Handle tooltip text change with character limit validation
  const handleTooltipChange = (e) => {
    let value = e.target.value;
    if (value.length > 250) {
      value = value.slice(0, 250);
      setTooltipWarning(
        "Tooltip text exceeded 250 characters and has been truncated."
      );
    } else {
      setTooltipWarning("");
    }
    setTooltipText(value);

    // Ensure the tooltip change is passed back to EditSectionSet
    props.handleQuestionChange(
      { target: { name: "tooltip", value } },
      props.qidx
    );
  };

  // Toggle tooltip input visibility based on checkbox
  const handleTooltipCheckboxChange = () => {
    setTooltipEnabled(!tooltipEnabled);
    if (!tooltipEnabled) {
      setTooltipText("");
    }
  };

  const handleChoiceAdd = (qidx, sidx) => {
    setChoiceList([
      ...choiceList,
      {
        qidx: qidx,
        sidx: sidx,
        cName: "",
        weight: "",
        communication: false,
        motivType: null,
        tooltip: null,
        placeholder: "",
        options_list: null,
        opt_type: '3'
      },
    ]);
  };

  const handleChoiceRemove = (idx) => {
    const newChoiceList = [...choiceList];
    newChoiceList.splice(idx, 1);
    setChoiceList(newChoiceList);
  };

  const handleChoiceChange = (e, index) => {
    const { name, value } = e.target;
    const newChoiceList = [...choiceList];
    newChoiceList[index][name] = value;

    setChoiceList(newChoiceList);
  };

  const moveUp = (key) => {
    if (key === 0) return;
    const index = key - 1;
    const newChoiceList = [...choiceList];
    const itemAbove = newChoiceList[index];
    newChoiceList[key - 1] = newChoiceList[key];
    newChoiceList[key] = itemAbove;
    setChoiceList(newChoiceList);
  };

  const moveDown = (key) => {
    if (key === choiceList.length - 1) return;
    const index = key + 1;
    const newChoiceList = [...choiceList];
    const itemBelow = newChoiceList[index];
    newChoiceList[key + 1] = newChoiceList[key];
    newChoiceList[key] = itemBelow;
    setChoiceList(newChoiceList);
  };

  return (
    <div className="q-main">
      <Stack direction="horizontal" gap={3}>
        {/* Question Text Input */}
        <Form.Control
          disabled={editing}
          className="q-text"
          type="text"
          placeholder="Enter Question"
          name="qQuestion"
          value={sectionList[props.sidx].qIDs[props.qidx].qQuestion}
          onChange={(e) => {
            props.handleQuestionChange(e, props.qidx);
          }}
        />

        {/* Remove Button */}
        <div className="vr" />
        <Button
          disabled={editing || textOnly}
          className="sec-x-btn"
          variant="outline-light"
          onClick={() => {
            props.handleQuestionRemove(props.qidx);
          }}
        >
          <ImCross style={{ color: "#BD4B4B", fontSize: "15px" }} />
        </Button>
      </Stack>

      {/* Tooltip Input Field - Displayed below Question Field if enabled */}
      {tooltipEnabled && (
        <Stack direction="horizontal" gap={3} style={{ marginTop: "10px" }}>
          <Form.Control
            disabled={editing}
            className="q-tooltip"
            type="text"
            placeholder="Your Text Here (Max 250 characters)"
            name="tooltip"
            value={tooltipText}
            onChange={handleTooltipChange}
          />
          {/* <small style={{ color: "#666" }}>Max 250 characters</small> */}
          {tooltipWarning && (
            <div style={{ color: "red", fontSize: "small" }}>
              {tooltipWarning}
            </div>
          )}
        </Stack>
      )}

      <Stack direction="horizontal" style={{ paddingTop: "3px", width: "91%" }}>
        <div className="sec-btn">
          <Form.Select
            className="q-type"
            disabled={editing || textOnly}
            value={sectionList[props.sidx].qIDs[props.qidx].qType}
            name="qType"
            onChange={(e) => {
              props.handleQuestionChange(e, props.qidx);
            }}
          >
            <option value="" disabled selected>
              Type
            </option>
            <option value="Inventory">Inventory</option>
            <option value="Likert">Likert</option>
            <option value="Likert-6">Likert-6</option>
            <option value="Multiple">Multiple</option>
            <option value="Pick One">Pick One</option>
            <option value="Slider">Slider</option>
          </Form.Select>
        </div>

        {/* Tooltip Checkbox */}
        <Form.Check
          type="checkbox"
          name="tooltipEnabled"
          label="Tooltip"
          checked={tooltipEnabled}
          onChange={handleTooltipCheckboxChange}
          style={{ marginLeft: "10px" }}
          disabled={editing}
        />
      </Stack>

      {/* Choices Management */}
      <br />
      <div>
        <Button
          disabled={editing || textOnly}
          variant="outline-light"
          style={{ color: "#163172", fontSize: "15px", marginLeft: "10px" }}
          className="sec-btn"
          onClick={() => handleChoiceAdd(props.qidx, props.sidx)}
        >
          ✚ Choice
        </Button>
      </div>  
      <br />
      <ol type="a">
        <Stack gap={3}>
          {sectionList[props.sidx].qIDs[props.qidx].cIDs?.map(
            (singleChoice, cidx) => (
              <li key={cidx}>
                <EditChoiceSet
                  cidx={cidx}
                  qidx={props.qidx}
                  sidx={props.sidx}
                  handleChoiceRemove={(id) => handleChoiceRemove(id)}
                  singleChoice={singleChoice}
                  handleChoiceChange={(e, index) =>
                    handleChoiceChange(e, index)
                  }
                  editing={editing}
                  textOnly={textOnly}
                  moveUp={(key) => moveUp(key)}
                  moveDown={(key) => moveDown(key)}
                />
              </li>
            )
          )}
        </Stack>
      </ol>
    </div>
  );
};

export default EditQuestionSet;
