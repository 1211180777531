import React, { useEffect, useState } from "react";
import ChoiceSet from "./ChoiceSet";

import { Form, Stack, Button } from "react-bootstrap";
import { ImCross } from "react-icons/im";
import { GoArrowDown, GoArrowUp } from "react-icons/go";

const QuestionSet = (props) => {
  const [choiceList, setChoiceList] = useState([]);

  let editing = props.editing;

  // useEffect(() => {
  //   props.handleChoiceList(choiceList, props.qidx);
  // }, [choiceList]);

  useEffect(() => {
    if (props.qbChoiceList.length > 0) {
      setChoiceList(props.qbChoiceList);
    } else {
      props.handleChoiceList(choiceList, props.qidx);
    }
  }, [choiceList]);

  const handleChoiceAdd = (qidx, sidx) => {
    setChoiceList([
      ...choiceList,
      {
        qidx: qidx,
        sidx: sidx,
        cName: "",
        weight: "",
        communication: false,
        motivType: null,
      },
    ]);
  };

  const handleChoiceRemove = (idx) => {
    const newChoiceList = [...choiceList];
    newChoiceList.splice(idx, 1);
    setChoiceList(newChoiceList);
  };

  const handleChoiceChange = (e, index) => {
    let { name, value } = e.target;
    if (value === "null") value = null;
    const newChoiceList = [...choiceList];
    newChoiceList[index][name] = value;
    setChoiceList(newChoiceList);
  };

  const moveUp = (key) => {
    if (key === 0) return;
    const index = key - 1;
    const newChoiceList = [...choiceList];
    const itemAbove = newChoiceList[index];
    newChoiceList[key - 1] = newChoiceList[key];
    newChoiceList[key] = itemAbove;
    setChoiceList(newChoiceList);
  };

  const moveDown = (key) => {
    if (key === choiceList.length - 1) return;
    const index = key + 1;
    const newChoiceList = [...choiceList];
    const itemBelow = newChoiceList[index];
    newChoiceList[key + 1] = newChoiceList[key];
    newChoiceList[key] = itemBelow;
    setChoiceList(newChoiceList);
  };

  return (
    <div className="q-main">
      <Stack direction="horizontal" gap={3}>
        <Form.Control
          disabled={editing}
          className="q-text"
          type="text"
          placeholder="Enter Question"
          name="qQuestion"
          value={props.singleQuestion.qQuestion}
          onChange={(e) => {
            props.handleQuestionChange(e, props.qidx);
          }}
        />

        <div className="vr" />

        <Button
          disabled={editing}
          className="sec-x-btn"
          variant="outline-light"
          onClick={() => {
            props.handleQuestionRemove(props.qidx);
          }}
        >
          <ImCross style={{ color: "#BD4B4B", fontSize: "15px" }} />
        </Button>
      </Stack>
      <Stack direction="horizontal" style={{ paddingTop: "3px", width: "91%" }}>
        <div className="sec-btn">
          <Form.Select
            className="q-type"
            disabled={editing}
            value={props.singleQuestion.qType}
            name="qType"
            onChange={(e) => {
              props.handleQuestionChange(e, props.qidx);
            }}
          >
            <option value="" disabled selected>
              Type
            </option>
            <option value="Inventory">Inventory</option>
            <option value="Likert">Likert</option>
            <option value="Likert-6">Likert-6</option>
            <option value="Multiple">Multiple</option>
            <option value="Pick One">Pick One</option>
          </Form.Select>
        </div>
        <div style={{ marginLeft: "10px" }}>
          <Form.Select
            disabled={editing}
            name="CuArType"
            style={{ width: "fit-content" }}
            onChange={(e) => {
              props.handleQuestionChange(e, props.qidx);
            }}
          >
            <option value="" disabled selected>
              Select CuAr Type
            </option>
            <option key={"joyous_exploration"} value={"joyous_exploration"}>
              Joyous Exploration
            </option>
            <option key={"deprivation_sensitivity"} value={"deprivation_sensitivity"}>
              Deprivation Sensitivity
            </option>
            <option key={"stress_tolerance"} value={"stress_tolerance"}>
              Stress Tolerance
            </option>
            <option key={"thrill_seeking"} value={"thrill_seeking"}>
              Thrill Seeking
            </option>
            <option key={"social_curiosity"} value={"social_curiosity"}>
              Social Curiosity
            </option>
          </Form.Select>
        </div>

        <Form.Check
          disabled={editing}
          type="checkbox"
          name="hcs"
          label={"HCS"}
          onChange={(e) => {
            if (e.target.checked === true) e.target.value = "1";
            else e.target.value = "0";
            props.handleQuestionChange(e, props.qidx);
          }}
          style={{ marginLeft: "10px" }}
        />
        {/* <Form.Check
          disabled={editing}
          type="checkbox"
          name="motiv"
          label={"Motiv"}
          onChange={(e) => {
            if (e.target.checked === true) e.target.value = "1";
            else e.target.value = "0";
            props.handleQuestionChange(e, props.qidx);
          }}
          style={{ marginLeft: "10px" }}
        /> */}
        {/* <Form.Check
          disabled={editing}
          type="checkbox"
          name="CuAr"
          label={"CuAr"}
          onChange={(e) => {
            if (e.target.checked === true) e.target.value = "1";
            else e.target.value = "0";
            props.handleQuestionChange(e, props.qidx);
          }}
          style={{ marginLeft: "10px" }}
        /> */}

        <div className="ms-auto">
          <Button disabled={editing} variant="outline-light" onClick={() => props.onMoveUp(props.qidx)}>
            <GoArrowUp style={{ color: "#163172", fontSize: "20px" }} />{" "}
          </Button>
          <Button disabled={editing} variant="outline-light" onClick={() => props.onMoveDown(props.qidx)}>
            <GoArrowDown style={{ color: "#163172", fontSize: "20px" }} />{" "}
          </Button>
        </div>
      </Stack>
      <br />
      <div>
        <Button
          disabled={editing}
          variant="outline-light"
          style={{ color: "#163172", fontSize: "15px", marginLeft: "10px" }}
          className="sec-btn"
          onClick={() => handleChoiceAdd(props.qidx, props.sidx)}
        >
          ✚ Choice
        </Button>
      </div>
      <br />
      <ol type="a">
        <Stack gap={3}>
          {props.singleQuestion.cIDs?.map((singleChoice, cidx) => (
            <li key={cidx}>
              <ChoiceSet
                cidx={cidx}
                qidx={props.qidx}
                sidx={props.sidx}
                handleChoiceRemove={(id) => {
                  handleChoiceRemove(id);
                }}
                singleChoice={singleChoice}
                handleChoiceChange={(e, index) => {
                  handleChoiceChange(e, index);
                }}
                editing={editing}
                moveUp={(key) => moveUp(key)}
                moveDown={(key) => moveDown(key)}
              />
            </li>
          ))}
        </Stack>
      </ol>
    </div>
  );
};

export default QuestionSet;
