import React, { useContext } from "react";
import Navigate from "../../Navigate";
import confirm from "../../../image/confirm.svg";
import { Image } from "react-bootstrap";
import { AccountContext } from "../../Contexts/AccountContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AccessDenied from "../../AccessDenied";
import { Button, Stack } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";

const Confirmation = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { assmtName, AID, UAID } = state;

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isLandscapeMobile = useMediaQuery({
    query: "(orientation: landscape) and (max-height: 431px",
  });

  const { loginStatus, loggedInUser } = useContext(AccountContext);
  return (
    <div>
      {loginStatus === true && loggedInUser.group === "User" ? (
        <>
          <Helmet>
            <title>Helix Employability Tool | Confirmation</title>
          </Helmet>
          <Navigate />
          <div className="confirm">
            <Image
              className="flex justify-content-center"
              src={confirm}
              alt="No Image"
              width={isMobile ? "388" : "450"}
              height="450"
            />
            <br />
            <br />
            <h3>
              Congratulation {loggedInUser.first_name} {loggedInUser.last_name}{" "}
              🎊
            </h3>
            <p>
              You have submitted the assessment "{assmtName}" successfully! An
              email of your responses has been sent
            </p>
            <Stack
              direction="horizontal"
              gap={4}
              justifyContent="center"
              sx={{ mb: 2 }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  navigate(
                    `/user/viewUserResult/${AID}/${loggedInUser.UID}/${UAID}`
                  );
                }}
              >
                View Response
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  navigate(`/analyze/bysection/${AID}/${loggedInUser.UID}`, {
                    state: { UAID },
                  });
                }}
              >
                View Analysis
              </Button>
            </Stack>
            <p>
              Take me to{" "}
              <Link to={`/user/dashboard/${loggedInUser.UID}`}>Dashboard</Link>{" "}
            </p>
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
};

export default Confirmation;
