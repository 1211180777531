import React from "react";
import { Form, Button } from "react-bootstrap";

const ViewPickOneResult = (props) => {
	let q = props.q;
	let qQID = q.QID;
	let UAID = props.UAID;
	let allResponse = props.allResponse;
	let isMobile = props.isMobile;
	let isLandscapeMobile = props.isLandscapeMobile;

	return (
		<div className="v-mult-opt">
			<ol type="a">
				{q.choices.map((c, ccid) => (
					<li key={ccid} className="single-mult-opt">
						{isMobile ? (
							<Button
								variant={
									allResponse.find(
										(i) =>
											i.CID === c.CID &&
											i.QID === c.QID &&
											i.SecID === c.SecID &&
											i.AID === c.AID &&
											i.UAID === UAID
									)
										? "primary"
										: "outline-primary"
								}
								style={{ width: "-webkit-fill-available" }}
							>
								{c.choice}
							</Button>
						) : (
							<Form.Check
								disabled
								type="radio"
								id={c.CID}
								checked={allResponse.find(
									(i) =>
										i.CID === c.CID &&
										i.QID === c.QID &&
										i.SecID === c.SecID &&
										i.AID === c.AID &&
										i.UAID === UAID
								)}
								label={c.choice}
							/>
						)}
					</li>
				))}
			</ol>
		</div>
	);
};

export default ViewPickOneResult;
