import React, { useContext } from "react";
import { Form, Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import { UserContext } from "../../Contexts/UserContext";

const Inventory = (props) => {
  let q = props.q;
  let qqid = props.qqid;
  let allQuestion = props.allQuestion;
  let isMobile = props.isMobile;
  const { userResponseList, setUserResponseList, userHCSList, setUserHCSList } =
    useContext(UserContext);

  const handleSelectChange = (e, c) => {
    c["checked"] = 1;
    const { value, checked, id } = e.target;
    let resObj = {
      response: value,
      type: allQuestion[qqid].type,
      CID: c.CID,
      QID: c.QID,
      SecID: c.SecID,
    };
    if (checked || isMobile) {
      setUserResponseList(userResponseList.filter((i) => i.CID !== id));
      if (allQuestion[qqid].hcs === "1") {
        let list = [...userHCSList];
        let newUserHCSList = list.filter((i) => i.CID !== id);
        let resObj = {
          QID: allQuestion[qqid].QID,
          CID: id,
          weight: value * c.weightage,
        };
        newUserHCSList.push(resObj);
        setUserHCSList(newUserHCSList);
      }
    }
    setUserResponseList((prev) => {
      return [...prev, resObj];
    });
  };

  return (
    <div>
      {isMobile ? (
        <Form>
          {allQuestion[qqid].choices.map((c, ccid) => (
            <Form.Group key={ccid} controlId={c.CID}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Form.Label style={{ marginBottom: "0.5rem" }}>
                  {c.choice}
                </Form.Label>
                {c.tooltip && c.tooltip !== "" && (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{c.tooltip}</Tooltip>}
                  >
                    <span
                      style={{
                        marginLeft: "8px",
                        cursor: "pointer",
                        color: "#31708f",
                      }}
                    >
                      <i className="fas fa-info-circle"></i>
                    </span>
                  </OverlayTrigger>
                )}
              </div>
              <Form.Select
                value={
                  userResponseList.find((i) => i.CID === c.CID)?.response || ""
                }
                onChange={(e) => handleSelectChange(e, c)}
                style={{ marginBottom: "1rem" }}
              >
                <option value="">Select an option</option>
                <option value="1">"A Novice"</option>
                <option value="2">"A Practitioner"</option>
                <option value="3">"An Expert"</option>
                <option value="4">"A Leader"</option>
              </Form.Select>
            </Form.Group>
          ))}
        </Form>
      ) : (
        <Table borderless>
          <tbody>
            <tr>
              <td></td>
              <td>"A Novice"</td>
              <td>"A Practitioner"</td>
              <td>"An Expert"</td>
              <td>"A Leader"</td>
            </tr>
            {allQuestion[qqid].choices.map((c, ccid) => (
              <tr key={ccid}>
                <td>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {c.choice}
                    {c.tooltip && c.tooltip !== "" && (
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{c.tooltip}</Tooltip>}
                      >
                        <span
                          style={{
                            marginLeft: "8px",
                            cursor: "pointer",
                            color: "#31708f",
                          }}
                        >
                          <i className="fas fa-info-circle"></i>
                        </span>
                      </OverlayTrigger>
                    )}
                  </div>
                </td>
                {[1, 2, 3, 4].map((value) => (
                  <td key={value}>
                    <Form.Check
                      value={value}
                      type="radio"
                      name={c.CID}
                      id={c.CID}
                      checked={userResponseList.find(
                        (i) => i.CID === c.CID && i.response === String(value)
                      )}
                      onChange={(e) => handleSelectChange(e, c)}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default Inventory;
