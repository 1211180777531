import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccountContext } from "./Contexts/AccountContext";
import { AssmtContext } from "./Contexts/AssmtContext";
import { UserContext } from "./Contexts/UserContext";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import ToastMessage from "./ToastMessage";
import {
  Navbar,
  Nav,
  Container,
  Stack,
  Form,
  Button,
  Row,
  Col,
  NavDropdown,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../image/Helix-logo.png";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { useMediaQuery } from "react-responsive";
import menu from "../image/menu.svg";
import { Helmet } from "react-helmet";
import * as AWS from "aws-sdk";
import axios from "axios";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

//configuration for SES service
const ses = new AWS.SES({
  region: process.env.REACT_APP_SES_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_SES_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SES_SECRET_ACCESS_KEY,
  },
});

let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();

const Register = () => {
  const { Pool } = useContext(AccountContext);

  const { toast, setToast, message, setMessage, status, setStatus } =
    useContext(AssmtContext);

  const { createUser } = useContext(UserContext);

  const navigate = useNavigate();

  const setCognitoUserAttribute = (attributeKey, attributeValue) => {
    let data = {
      Name: attributeKey,
      Value: attributeValue,
    };

    return new CognitoUserAttribute(data);
  };

  const group_name = "User";
  const [userObj, setUserObj] = useState({
    name:"",
    family_name: "",
    email: "",
    password: "",
    confirmPassword: "",
    passState: false,
    passShow: false,
  })
  const [consent, setConsent] = useState(true);
  const [join, setJoin] = useState(false);
  const [obj, setObj] = useState({
    code: "",
    msg: "",
    GID: "",
  });
  const [load, setLoad] = useState({
    groupCodeLoad: false,
    registerLoad: false
  });
  const [errMsg, setErrMsg] = useState("");
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isLandscapeMobile = useMediaQuery({
    query: "(orientation: landscape) and (max-height: 431px",
  });

  useEffect(() => {
    if (
      userObj.password !== "" &&
      userObj.confirmPassword !== "" &&
      userObj.password === userObj.confirmPassword
    ) {
      setUserObj((obj)=> {
        return {...obj,passState: true}
      });
    } else {
      setUserObj((obj)=> {
        return {...obj,passState: false}
      });
    }
  }, [userObj.password, userObj.confirmPassword]);

  const togglePassword = () => {
    setUserObj((obj)=> {
      return {...obj,passShow: !obj.passShow}
    });
  };

  const verifyGroupCode = async (code) => {
    try {
      setLoad((obj)=>{
        return {...obj, groupCodeLoad: true}
      });
      const resp = await axios.post(
        `${window.location.protocol}//${process.env.REACT_APP_SERVER_IP}:${
          process.env.REACT_APP_SERVER_PORT
        }/user/verifyGroupCode/${null}`,
        { code }
      );

      if (resp?.response?.data || resp?.data?.error) {
        setObj((val) => {
          return { ...val, code: "", msg: "" };
        });
        throw {message: "Something went wrong!"};
      }

      if (!resp?.data.group) {
        setObj((val) => {
          return { ...val, msg: resp?.data.message, code: "" };
        });
        return;
      }

      setObj((val) => {
        return { ...val, msg: resp?.data.message, GID: resp?.data.group };
      });
      return;
    } catch (err) {
      console.log("Error in verifying group code: ", err.message);
      setMessage(err.message);
      setStatus(false);
      setToast(true);
      return;
    } finally {
      setLoad((obj)=>{
        return {...obj, groupCodeLoad: false}
      });
    }
  };

  const onRegister = (event) => {
    event.preventDefault();

    if (
      userObj.name === "" ||
      userObj.family_name === "" ||
      userObj.email === "" ||
      userObj.password === "" ||
      userObj.confirmPassword === ""
    ) {
      setMessage("Please input all the details");
      setStatus(false);
      setToast(true);
      return;
    }

    if (userObj.password !== userObj.confirmPassword) {
      setErrMsg("Password do not match!");
      return;
    }
    
    setLoad((obj)=>{
      return {...obj, registerLoad: true}
    });

    let attributeList = [];

    attributeList.push(setCognitoUserAttribute("name", userObj.name));
    attributeList.push(setCognitoUserAttribute("family_name", userObj.family_name));

    Pool.signUp(userObj.email, userObj.password, attributeList, null, async (err, data) => {
      try {
        if (err) {
          console.error(err.message);
          setErrMsg(err.message);
          return;
        }
        console.log("DATA in SIGNUP : ", data);
        let userInfo = {};
        userInfo["UID"] = data.userSub;
        userInfo["first_name"] = userObj.name;
        userInfo["last_name"] = userObj.family_name;
        userInfo["email_ID"] = userObj.email;
        userInfo["group_name"] = group_name;
        userInfo["email_verified"] = false;
        userInfo["consent"] = consent;
        userInfo["group"] = obj.GID;
        let addUser = await createUser(userInfo);
        if (addUser?.data?.error === false) {
          setMessage(
            `Sign-Up Successful!
Congratulations, your account has been created!
A verification email has been sent to your email.`
          );
          setStatus(true);
          setToast(true);
          navigate("/login");
        } 
        else {
          if (data.userSub) {
            //1. delete the user from cognito
            await cognitoidentityserviceprovider
              .adminDeleteUser({
                UserPoolId: process.env.REACT_APP_USERPOOLID,
                Username: data.userSub,
              })
              .promise();

            //2. send email through SES regarding technical issue
            let params = {
              Destination: {
                ToAddresses: [userObj.email],
              },
              Message: {
                Body: {
                  Html: {
                    Charset: "UTF-8",
                    Data: `Hey ${userObj.name} ${userObj.family_name}, <br/> We noticed that there was an issue completing your sign-up process. We are sorry for the inconvenience, and we are here to help you get started as soon as possible!
                   <br />
                   Please try again and if you encounter the same issue, please contact our support team.
                   <br /><br/>
                   Thanks, <br/>
                   Helix Team <br/><br/>
                   Note: Ignore account verification email if already received.
                   `,
                  },
                },
                Subject: {
                  Charset: "UTF-8",
                  Data: "Action Needed: Complete Your Sign-Up Process",
                },
              },
              Source: "Helix Team <info@helix-se.org>"
            };

            await ses.sendEmail(params).promise();
            setMessage(
              `Sign-Up Failed
We are sorry, something went wrong with your sign-up process.
Please try again or contact support.`
            );
            setStatus(false);
            setToast(true);
          }
        }
      } catch (err) {
        console.log("Cannot process the user creation request...  : ", err);
        setMessage(
          `Sign-Up Failed
We are sorry, something went wrong with your sign-up process.
Please try again or contact support.`
        );
        setStatus(false);
        setToast(true);
      }finally{
        setLoad((obj)=>{
          return {...obj, registerLoad: false}
        });
        setUserObj({
          name:"",
          family_name:"",
          email:"",
          password:"",
          confirmPassword:"",
          passShow: false,
          passState: false
        });
      }
    });
  };

  return (
    <div>
      <Helmet>
        <title>Helix Employability Tool | Sign Up</title>
      </Helmet>
      <Navbar fixed="top" className="navbar">
        <Container>
          <Navbar.Brand href="/">
            <img
              alt=""
              src={logo}
              width="auto"
              height="40"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          {isMobile ? (
            <NavDropdown
              title={<img src={menu} alt="Menu" />}
              id="basic-nav-dropdown"
              align="end"
              style={{ marginRight: "-5px" }}
              className="custom-dropdown"
            >
              <NavDropdown.Item href="/">Home</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/about-us">About Us</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/contact-us">Contact Us</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/login">Sign in</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/register">Sign up</NavDropdown.Item>
            </NavDropdown>
          ) : isLandscapeMobile ? (
            <NavDropdown
              title={<img src={menu} alt="Menu" />}
              id="basic-nav-dropdown"
              align="end"
              style={{ marginRight: "-5px" }}
              className="custom-dropdown"
            >
              <NavDropdown.Item href="/">Home</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/about-us">About Us</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/contact-us">Contact Us</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/login">Sign in</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/register">Sign up</NavDropdown.Item>
            </NavDropdown>
          ) : (
            <Nav className="nav-right-gap">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/about-us">About Us</Nav.Link>
              <Nav.Link href="/contact-us">Contact Us</Nav.Link>
              <Nav.Link href="/login">
                <Button>Sign in</Button>
              </Nav.Link>
              <Nav.Link href="/register">
                <Button className="custom-button">Sign up</Button>
              </Nav.Link>
            </Nav>
          )}
        </Container>
      </Navbar>
      <ToastMessage
        toast={toast}
        onClose={() => setToast(!toast)}
        message={message}
        status={status}
      />
      <br />
      <div className="register-div">
        <Stack gap={3}>
          <h1 className="text-muted text-center">
            Register with <span style={{ color: "#163172" }}>Helix</span>
          </h1>
          <Form onSubmit={onRegister}>
            <Form.Group className="mb-3">
              <Row>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="First name"
                    value={userObj.name}
                    onChange={(e) => setUserObj((obj)=> {
                      return {...obj, name:e.target.value}
                    })}
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Last name"
                    value={userObj.family_name}
                    onChange={(e) => setUserObj((obj)=> {
                      return {...obj, family_name:e.target.value}
                    })}
                  />
                </Col>
              </Row>
              <br />
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={userObj.email}
                onChange={(e) => setUserObj((obj)=> {
                  return {...obj, email:e.target.value}
                })}
              />
              <div className="mb-3">
                <Form.Text className="text-muted">
                  Your email address will be your username.
                </Form.Text>
              </div>

              <div style={{ display: "flex" }}>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <Form.Control
                    // className="mt-3"
                    type={userObj.passShow ? "text" : "password"}
                    placeholder="Enter password"
                    value={userObj.password}
                    onChange={(e) => setUserObj((obj)=> {
                      return {...obj, password:e.target.value}
                    })}
                  />
                  {/* {passState === true ? (
                    <BsFillCheckCircleFill
                      style={{
                        position: "absolute",
                        top: "0.65rem",
                        right: "1rem",
                      }}
                    />
                  ) : (
                    <></>
                  )} */}
                </div>
                {userObj.password.length ? (
                  <Button
                    variant="outline-light"
                    style={{ color: "#163172" }}
                    onClick={togglePassword}
                  >
                    {userObj.passShow === false ? (
                      <BsFillEyeFill />
                    ) : (
                      <BsFillEyeSlashFill />
                    )}
                  </Button>
                ) : (
                  <></>
                )}
              </div>
              <div style={{ position: "relative" }}>
                <Form.Control
                  className="mt-3"
                  type={userObj.passShow ? "text" : "password"}
                  placeholder="Confirm password"
                  value={userObj.confirmPassword}
                  onChange={(e) => setUserObj((obj)=> {
                    return {...obj, confirmPassword:e.target.value}
                  })}
                />

                {userObj.passState === true ? (
                  <BsFillCheckCircleFill
                    style={{
                      position: "absolute",
                      top: "0.65rem",
                      right: "1rem",
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>

              {/* <Form.Control
                className="mt-3"
                type="password"
                placeholder="Confirm password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              /> */}
              {errMsg.length > 0 ? (
                <Form.Text
                  // className="text-muted"
                  style={{
                    color: "#ff0000",
                    fontSize: "10px",
                  }}
                >
                  {errMsg}
                </Form.Text>
              ) : (
                <></>
              )}
            </Form.Group>
            <Form.Check
              type="checkbox"
              label="I want to join a group."
              defaultChecked={join}
              className="mb-2"
              onClick={() => setJoin(!join)}
            />
            {join && (
              <Stack direction="vertical">
                <Form.Label>Group Code:</Form.Label>
                <Stack direction="vertical" className="mb-2">
                  <Stack direction="horizontal">
                    <Form.Control
                      type="text"
                      placeholder={"Enter your group code here"}
                      value={obj.code}
                      onChange={(e) => {
                        setObj((val) => {
                          return { ...val, code: e.target.value };
                        });
                      }}
                    />
                    <Button
                      variant="outline-primary"
                      size="sm"
                      className="mx-2"
                      disabled={
                        obj.code.trim() === "" || obj.GID !== "" || load.groupCodeLoad     
                      }
                      onClick={() => verifyGroupCode(obj.code)}
                    >
                      {load.groupCodeLoad ? "Wait..." : "Verify"}
                    </Button>
                  </Stack>
                  {obj.msg.length > 0 && (
                    <>
                      <Stack direction="horizontal">
                        <Form.Text
                          className=""
                          // className="text-muted"
                          style={{
                            color: obj.GID === "" ? "#ff0000" : "#147812",
                            fontSize: "10px",
                          }}
                        >
                          {obj.msg}
                        </Form.Text>
                      </Stack>
                    </>
                  )}
                </Stack>
              </Stack>
            )}
            <Form.Check
              style={{ textAlign: "left" }}
              type="checkbox"
              label="I allow Helix to use the anonymized data for research."
              defaultChecked={consent}
              onClick={() =>
                consent === true ? setConsent(false) : setConsent(true)
              }
            />{" "}
            <br />
            <Button
              className="mb-3"
              variant="light"
              style={{ color: "#163172", width: "100%" }}
              type="submit"
            >
              {load.registerLoad ? "Wait..." : "Register"}
            </Button>
          </Form>
          {/* <p style={{ color: "#163172", textAlign: "center" }}>or</p>
          <Button
            className="mb-3"
            variant="light"
            style={{ color: "#163172", width: "100%" }}
            type="submit"
            onClick={() => {
              // window.location.href = `https://helix-login-system.auth.us-east-2.amazoncognito.com/login?client_id=${process.env.REACT_APP_CLIENTID}&response_type=token&scope=email+openid+profile&redirect_uri=http://${process.env.REACT_APP_SERVER_IP}:${process.env.REACT_APP_CLIENT_PORT}/`;
              window.location.href = `https://helix-login-system.auth.us-east-2.amazoncognito.com/oauth2/authorize?identity_provider=Google&redirect_uri=http://${process.env.REACT_APP_SERVER_IP}:${process.env.REACT_APP_CLIENT_PORT}/&response_type=TOKEN&client_id=${process.env.REACT_APP_CLIENTID}&scope=email+openid+profile`;
            }}
          >
            <FcGoogle />
            &nbsp;&nbsp; Continue with Google
          </Button> */}
          <p className="text-center">
            Already have an account? <Link to="/login">Login</Link>
          </p>
        </Stack>
      </div>
    </div>
  );
};

export default Register;
