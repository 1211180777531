import React from "react";
import { Dropdown, Form } from "react-bootstrap";

const CheckboxMenu = React.forwardRef(({ children, style, className }, ref) => {
	return (
		<div ref={ref} style={style} className={`${className} CheckboxMenu`}>
			<div
				className="d-flex flex-column"
				style={{ maxHeight: "500px", overflow: "none" }}
			>
				<div className="p-2" style={{ overflow: "auto" }}>
					<Form>
						<Form.Group controlId="group">{children}</Form.Group>
					</Form>
				</div>
			</div>
		</div>
	);
});

const CheckDropdownItem = React.forwardRef(({ label, id, onChange }, ref) => {
	return (
		<>
			<Form.Check
				type="checkbox"
				name={label}
				label={label}
				id={id}
				defaultChecked={true}
				onChange={(e) => {
					onChange(e.target);
				}}
			/>
		</>
	);
});

export const CheckboxDropdown = ({ items, onChange }) => {
	return (
		<Dropdown>
			<Dropdown.Toggle
				id="dropdown-basic"
				className="form-select"
				style={{
					backgroundColor: "#fff",
					padding: ".375rem 2.25rem .375rem .75rem",
					color: "#212529",
					backgroundImage:
						"url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e)",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "right 0.75rem center",
					backgroundSize: "16px 12px",
					border: "1px solid #ced4da",
					borderRadius: "0.25rem",
					transition:
						"border-color .15s ease-in-out, box-shadow .15s ease-in-out",
				}}
			>
				Select
			</Dropdown.Toggle>
			<Dropdown.Menu as={CheckboxMenu}>
				{items.map((i, index) => (
					<Dropdown.Item
						key={index}
						as={CheckDropdownItem}
						id={i}
						label={i}
						onChange={onChange}
					/>
				))}
			</Dropdown.Menu>
		</Dropdown>
	);
};
