import React, { useEffect, useRef, useState, useContext } from "react";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import { AssmtContext } from "../../Contexts/AssmtContext";
import { GroupContext } from "../../Contexts/GroupContext";
import { Button, Stack, Form, Modal } from "react-bootstrap";
import { AccountContext } from "../../Contexts/AccountContext";
import AddModal from "../../Common/AddModal";
import CheckModal from "../../Common/CheckModal";

// This page will help in modifying permissions attached to this assessment
const ModifyVisibilityModal = ({
  showModal,
  onHideModal,
  assessmentStatus,
  updateAssessmentStatus,
  groups,
  setGroups,
  AID
}) => {
  const { setToast, setMessage, setStatus } =
    useContext(AssmtContext);

  const { groupList } = useContext(GroupContext);
  const { apiCall } = useContext(AccountContext);
  const [groupsToRemove, setGroupsToRemove] = useState([]); //it represents the groups which have been selected for revoking access
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showVisibilityModal, setShowVisibilityModal] = useState(false);
  const [show, setShow] = useState(false);
  const [newGroups, setNewGroups] = useState([]); //it represents the groups that does not have the access to this assessment
  const [newGroupsToAdd, setNewGroupsToAdd] = useState([]); //it represents the groups that are selected for granting access
  const [load, setLoad] = useState(false);
  const [isPublic, setPublic] = useState(Boolean(assessmentStatus)); //it will change based on the radio button option selected by the admin

  $.DataTable = require("datatables.net");
  //for displaying the members of the group
  const tableRef = useRef();
  //for displaying the groups when you open the modal for adding new groups
  const newGroupsRef = useRef();

  //functions for updating state for removing groups
  const addRecordToDeletionList = (GID) => {
    setGroupsToRemove((list) => {
      return [...list, GID];
    });
  };

  const removeRecordFromDeletionList = (GID) => {
    setGroupsToRemove((list) => {
      return list.filter((val) => {
        return val !== GID;
      });
    });
  };

  //functions for updating state for adding new groups to access this assessment
  const addGroupToAdditionList = (obj) => {
    setNewGroupsToAdd((list) => {
      return [...list, obj];
    });
  };

  const removeGroupFromAdditionList = (obj) => {
    setNewGroupsToAdd((list) => {
      return list.filter((obj_) => {
        return obj_.GID !== obj.GID;
      });
    });
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleNewGroupsModal = () => {
    setShow(false);
  };

  const handleCloseVisibilityModal = () => {
    setShowVisibilityModal(false);
  };

  //API call for removing groups who has access to this assessment
  const removeGroupsPermissionToAccess = async () => {
    try {
      setLoad(true);
      const response = await apiCall(
        `admin/removeGroupsForAssessment/${AID}`,
        "POST",
        {
          GIDs: groupsToRemove,
        }
      );

      console.log("RESPONSE : ", response);

      if (response?.data?.error || response?.name === 'AxiosError') {
        throw response?.data?.error || 'Error';
      }

      setMessage(response.data?.message);
      setToast(true);
      setStatus(true);

      // update the array
      setGroups((list) => {
        return list.filter((ele) => {
          return !groupsToRemove.includes(ele.GID);
        });
      });
      setGroupsToRemove([]);
      handleCloseDeleteModal();
    } catch (err) {
      console.log("Error in removing groups: ", err);
      setMessage("Sorry, something went wrong!");
      setToast(true);
      setStatus(false);
      handleCloseDeleteModal();
      return;
    } finally {
      setLoad(false);
    }
  };

  //API call for adding new groups for accessing this assessment
  const addGroupsPermissionToAccess = async () => {
    // console.log("state : ",state);
    try {
      setLoad(true);
      console.log("hhh : ",newGroupsToAdd);
      const response = await apiCall(
        `admin/addGroupsToAssessment/${AID}`,
        "POST",
        {
          Groups: newGroupsToAdd,
        }
      );

      console.log("ADDED : ", response);
      if (response?.data?.error || response?.name === 'AxiosError') {
        throw response?.data?.error || 'Error';
      }

      setMessage(response.data?.message);
      setToast(true);
      setStatus(true);

      //update the array
      setGroups((list) => {
        return [...list, ...newGroupsToAdd];
      });
      setNewGroupsToAdd([]);
      handleNewGroupsModal();
    } catch (err) {
      console.log("Error in adding groups: ", err);
      setMessage("Sorry, something went wrong!");
      setToast(true);
      setStatus(false);
      handleNewGroupsModal();
      return;
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    // groups : list of groups that have access to this assessment
    // newGroups : list of groups that dont have the access to this assessment
    // groupList: it is the list of all groups created by admins
    console.log("grps not permitted...");
    let ids = groups.map((grp) => {
      return grp.GID;
    });

    // Excluding GID: 1 (Admin Group), GID: 2 (Disabled User Group)
    let grpsNotPermitted = groupList.filter((grp) => {
      return !(ids.includes(grp.GID) || grp.GID == 1 || grp.GID == 3);
    });

    setNewGroups(grpsNotPermitted);
  }, [groups]);

  useEffect(() => {
    console.log("GROUPS : ",groups);
    const table = $(tableRef.current).DataTable({
      data: groups,
      columns: [
        {
          className: "dt-body-center",
          title: "",
          defaultContent: `<input type='checkbox' class="delete-check" style="cursor:pointer;" />`,
        },
        {
          className: "dt-body-left",
          title: "GID",
          data: "GID",
        },
        {
          className: "dt-body-left",
          title: "Group Name",
          data: "g_name",
        },
        {
          className: "dt-body-left",
          title: "Group Desc",
          data: "g_desc",
        },
        {
          className: "dt-body-left",
          title: "Group Code",
          data: "g_code",
        },
      ],
      destroy: true,
    });

    //checkbox for deletion
    $("#example4 tbody").on("click", ".delete-check", function (event) {
      const data = table.row($(this).parents("tr")).data();
      console.log("DATTATAA :", data);
      if (data?.GID) {
        event.target.checked
          ? addRecordToDeletionList(data?.GID)
          : removeRecordFromDeletionList(data?.GID);
      }
    });

    return function () {
      table.destroy();
    };
  }, [groups]);

  useEffect(() => {
    let table;
    if (show && newGroupsRef.current) {
      table = $(newGroupsRef.current).DataTable({
        data: newGroups,
        columns: [
          {
            className: "dt-body-center",
            title: "",
            defaultContent: `<input type='checkbox' class="add-check" style="cursor:pointer;" />`,
          },
          {
            className: "dt-body-left",
            title: "GID",
            data: "GID",
          },
          {
            className: "dt-body-left",
            title: "Group Name",
            data: "g_name",
          },
          {
            className: "dt-body-left",
            title: "Group Desc",
            data: "g_desc",
          },
          {
            className: "dt-body-left",
            title: "Group Code",
            data: "g_code",
          },
        ],
        destroy: true,
      });

      //checkbox for insertion
      $("#example5 tbody").on("click", ".add-check", function (event) {
        const data = table.row($(this).parents("tr")).data();
        console.log("hehe : ", data);
        if (data?.GID) {
          event.target.checked
            ? addGroupToAdditionList(data)
            : removeGroupFromAdditionList(data);
        }
      });
    }

    return function () {
      if (show && newGroupsRef.current) table?.destroy();
    };
  }, [show, newGroups]);

  //handling radio button : visibility
  const handleOptionChange = (e) => {
    setPublic(!isPublic);
  };

  //updating visibility
  const updateAssessmentVisibility = async () => {
    try {
      setLoad(true);
      const response = await apiCall(
        `/admin/updateVisibility/${AID}`,
        "PUT",
        {
          isPublic,
        }
      );
      console.log("resppp : ", response);
      if (response?.data?.error || response?.name === 'AxiosError') {
        throw response?.data?.error || 'Error';
      }

      setMessage(response.data?.message);
      setToast(true);
      setStatus(true);

      //if the assessment visibility is changed to public
      if (isPublic) {
        setGroups([]); //no specific group will have access to this assessment
        setGroupsToRemove([]);
      }

      updateAssessmentStatus(isPublic);
      return;
    } catch (err) {
      console.log("Error in modifying visibility : ", err);
      setMessage("Sorry, unable to change the visibility mode at this moment!");
      setToast(true);
      setStatus(false);
      return;
    }finally{
      setLoad(false);
      handleCloseVisibilityModal();
    }
  };

  return (
    <>  
      <Modal
        show={showModal}
        onHide={onHideModal}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
       <Modal.Header closeButton>
              <Modal.Title>Update Access</Modal.Title>
       </Modal.Header>

       <Modal.Body>
      {/* Delete Modal  */}
      <CheckModal
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        load={load}
        btnText={"Delete"}
        checkText={"delete"}
        title={"Remove Groups"}
        bodyText={`Do you want to remove access for these selected groups?`}
        callback={removeGroupsPermissionToAccess}
      />

      {/* Visibility change Modal  */}
      <CheckModal
        show={showVisibilityModal}
        handleClose={handleCloseVisibilityModal}
        load={load}
        btnText={"Modify"}
        checkText={isPublic ? "public" : "restricted"}
        title={"Modify Visibility"}
        bodyText={`Do you want to update visibility access for the selected assessment?`}
        callback={updateAssessmentVisibility}
      />

      {/* Add Members Modal  */}
      <AddModal
        show={show}
        handleClose={handleNewGroupsModal}
        array={newGroupsToAdd}
        load={load}
        title={"Add New Groups"}
        callback={addGroupsPermissionToAccess}
      >
        <div className="user-table">
          <table
            className="hover dt[-head|-body]-left"
            width="100%"
            id="example5"
            ref={newGroupsRef}
          >
            <thead>
              <tr>
                <th></th>
                <th>Group ID</th>
                <th>Group Name</th>
                <th>Group Desc</th>
                <th>Group Code</th>
              </tr>
            </thead>
          </table>
        </div>
      </AddModal>
      <div>
        <p className="mb-0 mt-1">Select the visibility mode for this assessment : </p>
        <Stack direction="horizontal">
          <Form.Check
            checked={isPublic === true}
            disabled={isPublic === true}
            label={"Public"}
            type="radio"
            name={"isPublic"}
            value={true}
            //  style={{ marginLeft: "auto" }}
            onChange={handleOptionChange}
          />
          <Form.Check
            checked={isPublic === false}
            disabled={isPublic === false}
            label={"Restricted"}
            type="radio"
            name={"isPublic"}
            value={false}
            style={{ marginLeft: "10px", marginRight: "auto" }}
            onChange={handleOptionChange}
          />

          {/* button for changing the accessibility  */}
          <Button
            variant="outline-primary"
            className="mx-1"
            onClick={() => setShowVisibilityModal(true)}
            disabled={isPublic == assessmentStatus}
          >
            Change Visibility
          </Button>
        </Stack>
        {assessmentStatus ? (
          <p>NOTE: This is a public assessment accessible to all groups.</p>
        ) : (
          <p>
            NOTE: This is a private assessment restricted to specific groups.
          </p>
        )}
      </div>
      {!assessmentStatus && (
        <>
        <div  style={{pointerEvents: isPublic && 'none' ,opacity: isPublic && 0.5}}>
          <div className="d-flex justify-content-end my-3">
            <Button
              variant="outline-primary"
              className="mx-1"  
              onClick={() => setShow(true)}
            >
              Add Groups
            </Button>

            {groupsToRemove.length !== 0 && (
              <Button
                variant="outline-danger"
                className="mx-1"
                onClick={() => setShowDeleteModal(true)}
              >
                Remove Groups
              </Button>
            )}
          </div>
          <div className="user-table">
            <table
              className="hover dt[-head|-body]-left"
              width="100%"
              id="example4"
              ref={tableRef}
            >
              <thead>
                <tr>
                  <th></th>
                  <th>Group ID</th>
                  <th>Group Name</th>
                  <th>Group Desc</th>
                  <th>Group Code</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
        </>
      )}
       </Modal.Body>

       <Modal.Footer>
              <Button variant="secondary" onClick={onHideModal}>
                Close
              </Button>
        </Modal.Footer>

      </Modal>
    </>
  );
};

export default ModifyVisibilityModal;
