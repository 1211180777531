import React, { useContext } from "react";
import { Navbar, Container, Stack, Card, Form, Button, Nav, Image, NavDropdown } from "react-bootstrap";
import logo from "../image/Helix-logo.png";
import pageImage from "../image/Main-Graphic.png";
import icon1 from "../image/Icon-individual-1.svg"
import icon6 from "../image/Icon-individual-6.svg"
import icon3 from "../image/Icon-individual-3.svg"
import icon4 from "../image/Icon-individual-4.svg"
import icon5 from "../image/Icon-individual-5.svg"
import icon2 from "../image/Icon-individual-2.svg"
import { useMediaQuery } from 'react-responsive';
import menu from "../image/menu.svg";
import {Helmet} from "react-helmet";

const Landing = () => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isLandscapeMobile = useMediaQuery({ query: '(orientation: landscape) and (max-height: 431px' });
    return(
    <>
        <Helmet>
            <title>Helix Employability Tool | Homepage</title>
        </Helmet>
        <Navbar fixed="top" className="navbar">
            <Container>
                <Navbar.Brand href="/">
                    <img
                      alt=""
                      src={logo}
                      width="auto"
                      height="40"
                      className="d-inline-block align-top"
                    />
                </Navbar.Brand>
                {isMobile ? (
                        <NavDropdown
                         title={<img src={menu} alt="Menu" />}
                         id="basic-nav-dropdown"
                         align="end"
                         style={{ marginRight: "-5px" }}
                         className="custom-dropdown"
                        >
                           <NavDropdown.Item href="/">Home</NavDropdown.Item>
                           <NavDropdown.Divider />
                           <NavDropdown.Item href="/about-us">About Us</NavDropdown.Item>
                           <NavDropdown.Divider />
                           <NavDropdown.Item href="/contact-us">Contact Us</NavDropdown.Item>
                           <NavDropdown.Divider />
                           <NavDropdown.Item href="/login">Sign in</NavDropdown.Item>
                           <NavDropdown.Divider />
                           <NavDropdown.Item href="/register">Sign up</NavDropdown.Item>
                        </NavDropdown>
                    ) : isLandscapeMobile? (<NavDropdown
                        title={<img src={menu} alt="Menu" />}
                        id="basic-nav-dropdown"
                        align="end"
                        style={{ marginRight: "-5px" }}
                        className="custom-dropdown"
                       >
                          <NavDropdown.Item href="/">Home</NavDropdown.Item>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="/about-us">About Us</NavDropdown.Item>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="/contact-us">Contact Us</NavDropdown.Item>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="/login">Sign in</NavDropdown.Item>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="/register">Sign up</NavDropdown.Item>
                       </NavDropdown>):(
                        <Nav className="nav-right-gap">
                            <Nav.Link href="/">Home</Nav.Link>
                            <Nav.Link href="/about-us">About Us</Nav.Link>
                            <Nav.Link href="/contact-us">Contact Us</Nav.Link>
                            <Nav.Link href="/login"><Button>Sign in</Button></Nav.Link>
                            <Nav.Link href="/register"><Button className="custom-button">Sign up</Button></Nav.Link>
                        </Nav>
                    )}
            </Container>
        </Navbar>
        <div id="nbuhjk" style={{ display: 'flex', flexDirection: 'column', position: "absolute", width: "100%", left: "0vh", maxHeight: '200rem', top: '4.3rem' }}>
            <div id="top-hald" style={{ backgroundColor: '#FFFFFF' }}>
                <h2 id='oiub' style={{ textAlign: 'center', font: "normal normal 600 32px/39px Montserrat", letterSpacing: "0px", color: "#111314"}}>Helix
                    <sup style={{ verticalAlign: 'super', font: "normal normal 600 16px/39px Montserrat", letterSpacing: "0px", color: "#111314" }}>EMP</sup>
                </h2>
                <div id="abdcuhj" style={{ display:"flex", justifyContent:"center", alignItems: "center", width: "100%"}}>
                    <div style={{ width: isMobile? '90%': isLandscapeMobile? '90%':'45%', padding: '20px', borderRadius: '8px' }}>
                        <h4 style={{ textAlign: 'center', font: "normal normal normal 14px/24px Montserrat", letterSpacing: "0px", color: "#68696B"}}>
                        Helix<sup style={{ verticalAlign: 'super', font: "normal normal 600 7px/24px Montserrat", letterSpacing: "0px", color: "#111314" }}>EMP</sup> provides insight into some of the most in-demand skills across disciplines and domains: self-leadership and learning, team leadership and collaboration, and complex problem-solving. These are what the world now calls “employability skills.”
                        </h4>
                    </div>
                </div>
            </div>
            <div id="bottom-hald" style={{ background: 'linear-gradient(to bottom, #EFF7FC, #FFFFFF)', display: 'flex', justifyContent: 'flex-end' }}>
            <Stack id="stocky" gap={4} direction= {isMobile? "vertical":isLandscapeMobile? "vertical":"horizontal"} style={{ position: 'relative', right: "10%", height: '50%', width: '80%' }}>
                    <Stack gap={isMobile? 4:isLandscapeMobile? 4:5} direction="vertical" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', width: isMobile?"80%":isLandscapeMobile?"80%":"33%", alignSelf: isMobile? "end":isLandscapeMobile?"end":"normal" }}>
                        <Card style={{ width: '100%', height: 'fit-content', borderRadius: '8px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                            <Card.Body>
                                <Card.Title style={{ textAlign: 'left', font: "normal normal 600 16px/19px Montserrat", letterSpacing: "0px", color: "#111314" }}><Image className="flex justify-content-left" src={icon1} alt="No Image" width="30" height="30" style={{marginBottom:"0.5rem"}} /><br></br> Why should I use this tool?</Card.Title>
                                <Card.Text style={{ textAlign: 'left', font: "normal normal normal 14px/24px Montserrat", letterSpacing: "0px", color: "#68696B" }}>
                                This tool can provide you with critical insights into your abilities for problem-solving and success in the workplace. You will gain insight into your self-leadership and learning capabilities; your ability to collaborate with others and lead teams; and solve complex problems. The tool was developed as an extension of an eight-year global investigation on systems engineering effectiveness.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card style={{ width: '100%', height: 'fit-content', borderRadius: '8px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', marginBottom: isMobile? '0rem': isLandscapeMobile? '0rem':'2rem' }}>
                            <Card.Body>
                                <Card.Title style={{ textAlign: 'left', font: "normal normal 600 16px/19px Montserrat", letterSpacing: "0px", color: "#111314" }}><Image className="flex justify-content-left" src={icon6} alt="No Image" width="30" height="30" style={{marginBottom:"0.5rem"}} /><br></br> How will my data be used?</Card.Title>
                                <Card.Text style={{ textAlign: 'left', font: "normal normal normal 14px/24px Montserrat", letterSpacing: "0px", color: "#68696B" }}>
                                Anonymized data will be periodically analyzed to understand overall trends and also make improvements to the tool. Users have the ability to opt out of this analysis.<br></br><br></br> If you are a student in the Defense Civilian Training Corps (DCTC), you will be asked to export your data to share it with your instructors, but the Helix team will not share this data directly.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Stack>
                    <Stack gap={isMobile? 4:isLandscapeMobile? 4:5} direction="vertical" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', width: isMobile?"80%":isLandscapeMobile?"80%":"33%", alignSelf: isMobile? "end":isLandscapeMobile?"end":"normal" }}>
                        <Card style={{ width: '100%', height: 'fit-content', borderRadius: '8px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                            <Card.Body>
                                <Card.Title style={{ textAlign: 'left', font: "normal normal 600 16px/19px Montserrat", letterSpacing: "0px", color: "#111314" }}><Image className="flex justify-content-left" src={icon3} alt="No Image" width="30" height="30" style={{marginBottom:"0.5rem"}} /><br></br>What is this tool?</Card.Title>
                                <Card.Text style={{ textAlign: 'left', font: "normal normal normal 14px/24px Montserrat", letterSpacing: "0px", color: "#68696B" }}>
                                This tool is built on findings from over 600 individuals in systems engineering and related fields across over 30 organizations across 3 countries combined with world-class frameworks and research around employability.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card style={{ width: '100%', height: 'fit-content', borderRadius: '8px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                            <Card.Body>
                                <Card.Title style={{ textAlign: 'left', font: "normal normal 600 16px/19px Montserrat", letterSpacing: "0px", color: "#111314" }}><Image className="flex justify-content-left" src={icon4} alt="No Image" width="30" height="30" style={{marginBottom:"0.5rem"}} /><br></br>How will my data be protected?</Card.Title>
                                <Card.Text style={{ textAlign: 'left', font: "normal normal normal 14px/24px Montserrat", letterSpacing: "0px", color: "#68696B" }}>
                                Your name and email will be required to use this tool. Protecting your data is important to the Helix team. We do not share your data with third parties. We follow generally accepted standards to protect the data submitted to us, both during transmission and once it is received. Most common attacks such as XSS, SQL injection, and CSRF will be detected and handled. Your data will be stored in a private database that can only be accessed by authorized Helix representatives.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Stack>
                    <Stack gap={isMobile? 4:isLandscapeMobile? 4:5} direction="vertical" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', width: isMobile?"80%":isLandscapeMobile?"80%":"33%", alignSelf: isMobile? "end":isLandscapeMobile?"end":"normal" }}>
                        <Card style={{ width: '100%', height: 'fit-content', borderRadius: '8px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                            <Card.Body>
                                <Card.Title style={{ textAlign: 'left', font: "normal normal 600 16px/19px Montserrat", letterSpacing: "0px", color: "#111314" }}><Image className="flex justify-content-left" src={icon5} alt="No Image" width="30" height="30" style={{marginBottom:"0.5rem"}} /><br></br>How long will it take to complete the assessment?</Card.Title>
                                <Card.Text style={{ textAlign: 'left', font: "normal normal normal 14px/24px Montserrat", letterSpacing: "0px", color: "#68696B" }}>
                                There are different assessments available in this tool. The DCTC Intake Assessment provides a brief overview, while others provide more detail into specific aspects of employability: <br></br><br></br> • Foundations <br></br>• Self-Leadership & Learning <br></br>• Team Leadership & Collaboration <br></br>• Complex Problem Solving <br></br><br></br>You can build your profile in one or all of these areas. Each area takes an average of 10 minutes to complete. Progress can be saved, so you can come back to complete your assessments later.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card style={{ width: '100%', height: 'fit-content', borderRadius: '8px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', marginBottom: isMobile? '2rem':'0rem' }}>
                            <Card.Body>
                                <Card.Title style={{ textAlign: 'left', font: "normal normal 600 16px/19px Montserrat", letterSpacing: "0px", color: "#111314" }}><Image className="flex justify-content-left" src={icon2} alt="No Image" width="30" height="30" style={{marginBottom:"0.5rem"}} /><br></br>Who can use this tool?</Card.Title>
                                <Card.Text style={{ textAlign: 'left', font: "normal normal normal 14px/24px Montserrat", letterSpacing: "0px", color: "#68696B" }}>
                                This tool is freely available to anyone who has an interest in understanding and improving their employability skills.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Stack>
                </Stack>
            </div>
            <div id="abdcuhj" style={{ position: "absolute", left: "50%", transform: "translate(-50%, -50%)", width: "100%"}}>
                    <img 
                    alt=""
                    src={pageImage}
                    width="90%"
                    style={{ position:"relative", left: "5%" }}
                    />                
            </div>
        </div>
    </>
    )
}

export default Landing;