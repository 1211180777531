import React from "react";
import { Card, Stack, FormLabel, Button, Badge, Modal } from "react-bootstrap";
import { AiTwotoneDelete } from "react-icons/ai";
import { GoArrowDown, GoArrowUp } from "react-icons/go";

const AssmtSet = (props) => {

    return(
        <div key={props.singleAssmt["AID"]}>
            <Card key={props.singleAssmt.AID}>
                <Card.Body>
                    <Stack
                    className="assmt-card-list"
                    direction="horizontal"
                    >
                        <div className="assmt-details">
                            <Card.Text>{props.singleAssmt.name}</Card.Text>
                        </div>
                        <div className="assmt-options">
                            <Stack
                            gap={3}
                            direction="horizontal"
                            >
                                <Button
                                variant="outline-light"
                                onClick={() => props.changePosUp(props.aIdx)}
                                >
                                    <GoArrowUp style={{ color: "#163172", fontSize: "20px" }} />{" "}
                                </Button>
                                <Button
                                variant="outline-light"
                                onClick={() => props.changePosDown(props.aIdx)}
                                >
                                    <GoArrowDown style={{ color: "#163172", fontSize: "20px" }} />{" "}
                                </Button>
                                <Button
                                variant="outline-light"
                                onClick={() => {
                                    props.addRemoveAssmtFromList(props.singleAssmt, props.aIdx)
                                }}
                                >
                                    <AiTwotoneDelete
                                    style={{ color: "#BD4B4B", fontSize: "20px" }}
                                    />
                                </Button>
                            </Stack>
                        </div>
                    </Stack>
                </Card.Body>
            </Card>
        </div>
    );
}

export default AssmtSet;