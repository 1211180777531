import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navigate from "../../Navigate";
import { Button, Stack, Row, Col, Card, Badge } from "react-bootstrap";
import { RiSurveyLine } from "react-icons/ri";
import { FaEye } from "react-icons/fa";
import { AccountContext } from "../../Contexts/AccountContext";
import AccessDenied from "../../AccessDenied";
import { AssmtContext } from "../../Contexts/AssmtContext";
import ToastMessage from "../../ToastMessage";
import assmtImage from "../../../image/takeAssmt.png";
import resultImage from "../../../image/result.png";
import { Alert } from 'react-bootstrap';
import { FaExclamationTriangle } from 'react-icons/fa';
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";

const Dashboard = () => {
	const { loginStatus, loggedInUser, apiCall } = useContext(AccountContext);

	const {
		toast,
		setToast,
		message,
		setMessage,
		status,
		setStatus,
		setAssmt,
	} = useContext(AssmtContext);

	const [incompleteNumber, setIncompleteNumber] = useState(0);
	const isMobile = useMediaQuery({ maxWidth: 767 });
    const isLandscapeMobile = useMediaQuery({ query: '(orientation: landscape) and (max-height: 431px' });

	useEffect(() => {
		const getIncompleteAssmt = async () => {
			const { data } = await apiCall(
				`user/getIncompleteAssmtNumber/${loggedInUser.UID}`
			);
			setIncompleteNumber(data[0].count);
		};
		getIncompleteAssmt();
	}, [loggedInUser.UID]);

	const navigate = useNavigate();
	return (
		<div>
			{loginStatus === true && loggedInUser.group === "User" ? (
				<>
			<Helmet>
				<title>Helix Employability Tool | User Dashboard</title>
			</Helmet>
					<Navigate />
					<ToastMessage
						toast={toast}
						onClose={() => setToast(!toast)}
						message={message}
						status={status}
					/>
					<br />
					<br />
					<Stack direction={isMobile? "vertical":"horizontal"} gap={3}>
						<h1 className="text-muted">
							Welcome! {isMobile? <></>:<br />}{" "}
							<span style={{ color: "#163172" }}>
								{loggedInUser.first_name}{" "}
								{loggedInUser.last_name}
							</span>
						</h1>
						<div className="ms-auto">
							{incompleteNumber > 0 && (
								<Alert variant="warning" onClick={() => navigate(`/user/results/${loggedInUser.UID}`)} style={{ cursor: 'pointer' }}>
								<FaExclamationTriangle /> You have incomplete/in-progress assessment. Click here to continue.
								</Alert>
							)}
							<Row xs={1} md={2} className="g-4">
								<Col>
									<Card
										className="text-center"
										style={{ maxWidth: "25rem", width: "100%" }}
									>
										{/* <Card.Header>Assessments</Card.Header> */}
										<Card.Img
											variant="top"
											src={assmtImage}
										/>
										<Card.Footer className="text-muted">
											<Button
												variant="outline-secondary"
												onClick={() => {
													navigate(
														`/user/userAssmt/${loggedInUser.UID}`
													);
												}}
											>
												<RiSurveyLine /> Take Assessment
											</Button>
										</Card.Footer>
									</Card>
								</Col>
								<Col>
									<Card
										className="text-center"
										style={{ maxWidth: "25rem", width: "100%" }}
									>
										{/* <Card.Header>Results</Card.Header> */}
										<Card.Img
											variant="top"
											src={resultImage}
										/>
										<Card.Footer className="text-muted">
											<Button
												variant="outline-secondary"
												onClick={() => {
													navigate(
														`/user/results/${loggedInUser.UID}`
													);
												}}
											>
												<FaEye /> Past Assessments
											</Button>
											{incompleteNumber > 0 ? (
												<Badge
													bg="danger"
													text="light"
													style={{
														position: "absolute",
														top: "0",
														right: "0",
														fontSize: "1em", // Increase the font size
														padding: "8px 14px" // Increase the padding
													}}
													>
													{incompleteNumber}
												</Badge>
											) : (
												<></>
											)}
										</Card.Footer>
									</Card>
								</Col>
							</Row>
						</div>
						{isMobile? (<><br/><br/></>):(<></>)}
					</Stack>
					{isLandscapeMobile? (<><br/><br/><br/></>):(<></>)}
				</>
			) : (
				<AccessDenied />
			)}
		</div>
	);
};

export default Dashboard;
