import React, { useEffect, useState } from "react";
import { Form, Badge } from "react-bootstrap";
import axios from "axios";
import { getBaseUrl } from "../../../base";

const ViewMultiple = (props) => {
  let q = props.q;
  let qQID = q.QID;
  let UAID = props.UAID;
  let allResponse = props.allResponse;
  // const [score, setScore] = useState(0);

  // useEffect(() => {
  //   if (!qQID || !UAID) return;
  //   const getQuestion = async (UAID, qQID) => {
  //     const { data } = await axios.get(
  //       `${getBaseUrl()}/admin/getResponseByQID/${UAID}/${qQID}`
  //     );
  //     let sum = 0;
  //     if (data.length) {
  //       data.forEach((fw) => {
  //         sum += fw.finalWeightage;
  //       });
  //     }
  //     setScore(sum);
  //   };
  //   getQuestion(UAID, qQID);
  // });

  return (
    <div className="v-mult-opt">
      <ol type="a">
        {q.choices.map((c, ccid) => (
          <li key={ccid} className="single-mult-opt">
            <Form.Check
              disabled
              type="checkbox"
              id={c.CID}
              checked={allResponse.find(
                (i) =>
                  i.CID === c.CID &&
                  i.QID === c.QID &&
                  i.SecID === c.SecID &&
                  i.AID === c.AID &&
                  i.UAID === UAID &&
                  i.response === 1
              )}
              label={c.choice}
            />
          </li>
        ))}
      </ol>
      {/* <Badge pill bg="primary">
        Score: {score}
      </Badge> */}
    </div>
  );
};

export default ViewMultiple;
