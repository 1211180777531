import React from "react";
import { Form,Stack, Table, OverlayTrigger, Tooltip} from "react-bootstrap";
import Select from "react-select";

const ViewSliderResult = (props) => {
  let q = props.q;
  let UAID = props.UAID;
  let allResponse = props.allResponse;
  let isMobile = props.isMobile;
  
  return (
    <>
      {isMobile ? (
        <Form>
          {q.choices.map((c, ccid) => {
            let value =
              allResponse.find(
                (i) =>
                  i.CID === c.CID &&
                  i.QID === c.QID &&
                  i.SecID === c.SecID &&
                  i.AID === c.AID &&
                  i.UAID === UAID
              );
            return (
              <>
                <Form.Group
                  key={ccid}
                  style={{ marginBottom: "0.5rem" }}
                >
                  <Form.Label style={{ marginBottom: "0.25rem" }}>
                {c.choice}
              </Form.Label>
              {c.tooltip && c.tooltip !== "" && (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{c.tooltip}</Tooltip>}
                  >
                    <span
                      style={{
                        marginLeft: "8px",
                        cursor: "pointer",
                        color: "#31708f",
                      }}
                    >
                      <i className="fas fa-info-circle"></i>
                    </span>
                  </OverlayTrigger>
                )}
              <div className="my-1">
                {c.opt_type == "1" ? (
                  <Select
                    value={
                     value?.additionalChoice?.split(",").map((val)=> ({value:val,label:val})) || {value:'No Option Selected', label:'No Option Selected'}
                    }
                    isMulti={true}
                    isDisabled={true}
                    styles={{
                      control: (base) => ({
                        ...base,
                        border: "1px solid #ced4da",
                        boxShadow: "none",
                      }),
                      multiValueRemove: (styles) => ({
                        ...styles,
                        ":hover": {
                          backgroundColor: "#0d6efd",
                          color: "white",
                        },
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: "gray",
                        fontWeight: 400,
                        fontSize: "0.75rem",
                        textAlign: "left",
                      }),
                    }}
                  />
                ) : c.opt_type == "2" ? (
                  <>
                    <Form.Control
                      className="sliderInput"
                      type="text"
                      id={c.CID}
                      name="additionalChoiceText"
                      value={
                       value?.additionalChoice || 'No Option Listed'
                      }
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
              <p className="mb-1">
                Score:{" "}
                {`${value?.response}.0`}
              </p>
              <input
                type="range"
                className="custom-range"
                min="0"
                max="5"
                step="1"
                style={{ width: "100%", maxWidth: "400px", cursor: "pointer" }}
                value={
                  value?.response || 0
                }
                id={c.CID}
                disabled={true}
              />
                </Form.Group>
              </>
            );
          })}
        </Form>
      ) : (
        <Table borderless>
          <tbody>
            {q.choices.map((c, ccid) => {
              let value =
                allResponse.find(
                  (i) =>
                    i.CID === c.CID &&
                    i.QID === c.QID &&
                    i.SecID === c.SecID &&
                    i.AID === c.AID &&
                    i.UAID === UAID
                );
                
              return (
                <>
                  <tr key={ccid}>
                    {/* sub question  */}
                    <td style={{ width: "25%" }}>
                      {c.choice}
                      {c.tooltip && c.tooltip !== "" && (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{c.tooltip}</Tooltip>}
                  >
                    <span
                      style={{
                        marginLeft: "8px",
                        cursor: "pointer",
                        color: "#31708f",
                      }}
                    >
                      <i className="fas fa-info-circle"></i>
                    </span>
                  </OverlayTrigger>
                )}
                      
                      </td>
                    <td style={{ width: "75%" }}>
                      <Stack direction="horizontal" gap={3}>
                        {/* if text input or multi dropdown is selected  */}
                        <div style={{ width: "60%" }}>
                          {c.opt_type == "1" ? (
                            <Select
							  isDisabled={true}
                              value={value?.additionalChoice?.split(",").map((val)=> ({value:val,label:val})) || {value:'No Option Selected', label:'No Option Selected'}}
                              isMulti={true}
                              styles={{
                                control: (base) => ({
                                  ...base,
                                  border: "1px solid #ced4da",
                                  boxShadow: "none",
                                }),
                                multiValueRemove: (styles) => ({
                                  ...styles,
                                  ":hover": {
                                    backgroundColor: "#0d6efd",
                                    color: "white",
                                  },
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: "gray",
                                  fontWeight: 400,
                                  fontSize: "0.75rem",
                                  textAlign: "left",
                                }),
                              }}
                            />
                          ) : (
                            <>
                              <Form.Control
                                className="sliderInput"
                                type="text"
                                id={c.CID}
                                name="additionalChoiceText"
                                value={value?.additionalChoice || 'No Option Listed'}
                                style={{
                                  visibility: `${
                                    c.opt_type == "2" ? "visible" : "hidden"
                                  }`,
                                }}
                              />
                            </>
                          )}
                        </div>
                        {/* corresponding slider  */}
                        <input
                          type="range"
                          className="custom-range"
                          min="0"
                          max="5"
                          step="1"
                          style={{
                            width: "30%",
                            maxWidth: "400px",
                            cursor: "pointer",
                          }}
                          value={value?.response || 0}
                          id={c.CID}
                          disabled={true}
                        />
                        <div>{`${value?.response}.0`}</div>
                      </Stack>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default ViewSliderResult;
