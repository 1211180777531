import React, { useContext, useEffect, useState } from "react";
import { Form, Button, Stack, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../Contexts/UserContext";
import Navigate from "../../Navigate";
import ToastMessage from "../../ToastMessage";
import { AssmtContext } from "../../Contexts/AssmtContext";
import axios from "axios";
import { getBaseUrl } from "../../../base";
import { AccountContext } from "../../Contexts/AccountContext";
import AccessDenied from "../../AccessDenied";
import { useMediaQuery } from "react-responsive";
import {Helmet} from "react-helmet";

const UserAssmt = () => {
	const { loginStatus, loggedInUser, apiCall } = useContext(AccountContext);

	const { toast, setToast, message, setMessage, status, setStatus } = useContext(AssmtContext);

	const {
		AssmtID,
		setAssmtID,
		setUserAssmtID,
		userObj,
		userAssmtObj,
		setUserAssmtObj,
		submitUserAssmt,
		setAssmtType,
		setGrpAssmtID,
		setFetchIndividualAssmt
	} = useContext(UserContext);

	const [assmt, setAssmt] = useState([]);
	const [aDetails, setADetails] = useState({});
	const isMobile = useMediaQuery({ maxWidth: 767 });
    const isLandscapeMobile = useMediaQuery({ query: '(orientation: landscape) and (max-height: 431px' });
	const isIpad = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
	const isLandscapeIpad = useMediaQuery({ query: '(orientation: landscape) and (min-height: 768px) and (max-height: 1024px)' });

	const navigate = useNavigate();

	useEffect(() => {
		getPublishedAssmtList()
	}, [loggedInUser.jwtToken])

	const getPublishedAssmtList = async () => {
		const { data } = await apiCall("admin/getPublishedAssmt");
		// axios.get(
		//   `${getBaseUrl()}/admin/getPublishedAssmt`,
		//   {
		//     headers: {
		//       Authorization: `Bearer ${loggedInUser.jwtToken}`,
		//     },
		//   }
		// );
		setAssmt(data);
	};

	const getAssmtDetails = async (AID) => {
		const { data } = await apiCall(`admin/getAssmtDetailsByID/${AID}`);
		// axios.get(
		//   `${getBaseUrl()}/admin/getAssmtDetailsByID/${AID}`,
		//   {
		//     headers: {
		//       Authorization: `Bearer ${loggedInUser.jwtToken}`,
		//     },
		//   }
		// );
		setADetails(data[0]);
	};

	return (
		<div>
			<Helmet>
				<title>Helix Employability Tool | User Assessment</title>
			</Helmet>
			{loginStatus === true && loggedInUser.group === "User" ? (
				<>
					<Navigate />
					<ToastMessage
						toast={toast}
						onClose={() => setToast(!toast)}
						message={message}
						status={status}
					/>
					<h6><a href="#" onClick={() => navigate("/user/dashboard/"+loggedInUser.UID)}>&lt;Back</a></h6>
					<Form.Label>{`Welcome ${loggedInUser.first_name}  ${loggedInUser.last_name}`}</Form.Label>
					<br />
					<br />
					<div>
						<Stack className="userAssmt-page" gap={3}>
							<Stack direction="horizontal" gap={isIpad? 1:0}>
								<Form.Label
									column
									sm={2}
									className="userAssmt-label"
								>
									Position Title
								</Form.Label>
								<Form.Control
									type="text"
									placeholder="Enter position title"
									onChange={(e) => {
										setUserAssmtObj((preValue) => {
											return {
												...preValue,
												positionTitle: e.target.value,
											};
										});
									}}
								/>
							</Stack>
							<Stack direction="horizontal">
								<Form.Label column sm={2}>
									Reference Date
								</Form.Label>
								<Form.Control
									type="date"
									onChange={(e) => {
										setUserAssmtObj((preValue) => {
											return {
												...preValue,
												referenceDate: e.target.value,
											};
										});
									}}
								/>
							</Stack>
							{isMobile? (
							<>
								<Stack direction="horizontal">
									<>
										<Form.Label column sm={2}>
											Position Option
										</Form.Label>
										<div
											key={"inline-radio"}
											onChange={(e) => {
												setUserAssmtObj((prevValue) => {
													return {
														...prevValue,
														positionOption:
															e.target.value,
													};
												});
											}}
										>
											<Form.Check
												inline
												label="Past Position"
												name="positionOption"
												value="Past Position"
												type="radio"
												style={{ marginRight: "0.5rem" }}
											/>
											<Form.Check
												inline
												label="Present Position"
												name="positionOption"
												value="Present Position"
												type="radio"
												style={{ marginRight: "0.5rem" }}
											/>
											<Form.Check
												inline
												label="Future Position"
												name="positionOption"
												value="Future Position"
												type="radio"
												style={{ marginRight: "0.5rem" }}
											/>
										</div>
									</>
									{/* <Form.Label column sm={1}></Form.Label> */}
								</Stack>
								<label
									className="text-muted ms-auto"
									style={{ width: "100%" }}
								>
									Note: Reference Date and Position Option
									should match*
								</label>
							</>
							):(
								<Stack direction="horizontal">
								<>
									<Form.Label column sm={2}>
										Position Option
									</Form.Label>
									<div
										key={"inline-radio"}
										onChange={(e) => {
											setUserAssmtObj((prevValue) => {
												return {
													...prevValue,
													positionOption:
														e.target.value,
												};
											});
										}}
									>
										<Form.Check
											inline
											label="Past Position"
											name="positionOption"
											value="Past Position"
											type="radio"
										/>
										<Form.Check
											inline
											label="Present Position"
											name="positionOption"
											value="Present Position"
											type="radio"
										/>
										<Form.Check
											inline
											label="Future Position"
											name="positionOption"
											value="Future Position"
											type="radio"
										/>
									</div>
								</>
								{/* <Form.Label column sm={1}></Form.Label> */}
								<label
									className="text-muted ms-auto"
									style={{ width: isLandscapeMobile? "40%": isIpad? "40%": isLandscapeIpad? "45%": "25%" }}
								>
									Note: Reference Date and Position Option
									should match*
								</label>
							</Stack>
							)}
							{/* <Stack direction="horizontal" gap={0}>
                <Form.Label column sm={3}></Form.Label>
                <label className="text-muted">
                  Recommended: Reference Date and Position Option should match*
                </label>
              </Stack>
              <br /> */}
							<Stack direction="horizontal" gap={isMobile? 1:0}>
								<Form.Label
									column
									sm={2}
									className="userAssmt-label"
								>
									Assessment
								</Form.Label>
								<Form.Select
									onChange={(e) => {
										let data = e.target.value.split(" ");
										getAssmtDetails(data[0]);
										setAssmtID(data[0]);
										setGrpAssmtID(data[0]);
										setAssmtType(data[1]);
										if(data[1] === "individual") setFetchIndividualAssmt(true);
									}}
								>
									<option value="" disabled selected>
										Select assessment you want to attempt
									</option>
									{assmt.length > 0 ? (
										assmt.map((val, idx) => (
											<option key={idx} value={`${val.AID} ${val.type}`}>
												{val.name}
											</option>
										))
									) : (
										<></>
									)}
								</Form.Select>
							</Stack>

							{aDetails.AID &&
							aDetails.name &&
							aDetails.description ? (
								<Stack gap={0}>
									<Alert>
										<p>Assessment details:</p>
										<div>
											<Stack direction="horizontal" gap={isMobile? 1:0}>
												<Form.Label column sm={3}>
													Name:
												</Form.Label>
												<Form.Label column>
													{aDetails.name}
												</Form.Label>
											</Stack>
											<Stack direction="horizontal" gap={isMobile? 1:0}>
												<Form.Label column sm={3}>
													Description:
												</Form.Label>
												<Form.Label column>
													{aDetails.description}
												</Form.Label>
											</Stack>
										</div>
									</Alert>
								</Stack>
							) : (
								<></>
							)}

							<div className="userpage-btn">
								<Button
									variant="light"
									style={{
										color: "#163172",
										fontSize: "14px",
									}}
									onClick={async () => {
										let today = new Date();
										if (
											userAssmtObj.positionTitle === "" ||
											userAssmtObj.referenceDate === "" ||
											userAssmtObj.positionOption === "" ||
											AssmtID === ""
										) {
											setMessage("Input missing!");
											setStatus(false);
											setToast(true);
											return;
										}
										// else if (
										//   new Date(userAssmtObj.referenceDate) > today &&
										//   userAssmtObj.positionOption !== "Future Position"
										// ) {
										//   setMessage(
										//     "Reference Date and Position Option dosen't match!"
										//   );
										//   setStatus(false);
										//   setToast(true);
										//   return;
										// } else if (
										//   new Date(userAssmtObj.referenceDate) < today &&
										//   userAssmtObj.positionOption !== "Past Position"
										// ) {
										//   setMessage(
										//     "Reference Date and Position Option dosen't match!"
										//   );
										//   setStatus(false);
										//   setToast(true);
										//   return;
										//   // } else if (
										//   //   new Date(userAssmtObj.referenceDate) === today &&
										//   //   userAssmtObj.positionOption !== "Present Position"
										//   // ) {
										//   //   setMessage(
										//   //     "Reference Date and Position Option dosen't match!"
										//   //   );
										//   //   setStatus(false);
										//   //   setToast(true);
										//   //   return;
										// }
										else {
											if(aDetails["type"] === "individual") {
												navigate(
													`/user/userResponse/${loggedInUser.UID}`
												);
											} else if(aDetails["type"] === "group") {
												navigate(
													`/user/userResponse/grpAssmt/${loggedInUser.UID}`
												);
											}
											const { data } =
												await submitUserAssmt(
													loggedInUser.UID,
													userAssmtObj,
													AssmtID
												);
											setUserAssmtID(data);
										}
									}}
								>
									Save & Continue
								</Button>
							</div>
						</Stack>
					</div>
				</>
			) : (
				<AccessDenied />
			)}
		</div>
	);
};

export default UserAssmt;
