import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Stack, Form } from "react-bootstrap";
import AccessDenied from "../../AccessDenied";
import { AccountContext } from "../../Contexts/AccountContext";
import Navigate from "../../Navigate";
import {
  CognitoUserPool,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";
import { useNavigate, useLocation } from "react-router-dom";
import ToastMessage from "../../ToastMessage";
import { AssmtContext } from "../../Contexts/AssmtContext";
import { UserContext } from "../../Contexts/UserContext";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { Helmet } from "react-helmet";

const EditProfile = () => {
  const { loginStatus, loggedInUser, getSession, authenticate, Pool, apiCall, setLoggedInUser } =
    useContext(AccountContext);

  const { toast, setToast, message, setMessage, status, setStatus, setAssmt } =
    useContext(AssmtContext);

  const { updateUser } = useContext(UserContext);

  const [newFirstName, setNewFirstName] = useState("");
  const [newLastName, setNewLastName] = useState("");
  const [newEmail_ID, setNewEmail_ID] = useState("");
  const [pass, setPass] = useState("");
  const [passShow, setPassShow] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [errMsg, setErrMsg] = useState("");
  const [code, setCode] = useState("");
  const [load, setLoad] = useState(false);
  const [msg, setMsg] = useState("");
  const [GID, setGID] = useState("");
  const [groupName,setGroupName] = useState("")

  const navigate = useNavigate();

  const togglePassword = () => {
    setPassShow(!passShow);
  };


  useEffect(() => {
    setNewFirstName(loggedInUser.first_name);
    setNewEmail_ID(loggedInUser.email_ID);
    setNewLastName(loggedInUser.last_name);
  }, [loggedInUser]);

  const setCognitoUserAttribute = (attributeKey, attributeValue) => {
    let data = {
      Name: attributeKey,
      Value: attributeValue,
    };

    return new CognitoUserAttribute(data);
  };

  const onUpdate = (event) => {
    event.preventDefault();
    if (pass.trim().length === 0) {
      setMessage("Please enter the password to update your profile!");
      setStatus(false);
      setToast(true);
      return;
    }

    let attributeList = [];

    attributeList.push(setCognitoUserAttribute("email", newEmail_ID));
    attributeList.push(setCognitoUserAttribute("name", newFirstName));
    attributeList.push(setCognitoUserAttribute("family_name", newLastName));
    setLoad(true);
    getSession().then(({ user, userInfo }) => {
      authenticate(userInfo.email_ID, pass)
        .then(() => {
          user.updateAttributes(attributeList, (err, results) => {
            if (err) {
              setErrMsg(err.message);
              return;
            } else {
              authenticate(newEmail_ID, pass).then(async (data) => {
                let updateObj = {};
                updateObj["first_name"] = data.first_name;
                updateObj["last_name"] = data.last_name;
                updateObj["email_ID"] = data.email_ID;
                updateObj["GID"] = GID;
                let updateToDB = await updateUser(data.UID, updateObj);
                // console.log("UPDATE : ",updateToDB);
                if (updateToDB?.data?.error === false) {
                  setLoggedInUser((user)=>{
                    return {
                      ...user,
                      groupNames: [...user.groupNames,groupName],
                      gids: [...user.gids,GID]
                    }
                  })
                  setMessage("Profile details updated successfully!");
                  setStatus(true);
                  setToast(true);
                  setLoad(false);
                  navigate(`/user/profile/${data.UID}`);
                }
                else{
                  throw {message: "Something went wrong!"}
                }
              });
            }
          });
        })
        .catch((err) => {
          console.log("Error in editing profile : ", err);
          setErrMsg(err.message);
          setLoad(false);
          return;
        });
    });
  };

  const verifyGroupCode = async (code) => {
    try {
      setLoad(true);
      const resp = await apiCall(
        `/user/verifyGroupCode/${loggedInUser.UID}`,
        "POST",
        { code }
      );
      console.log("RESPONSE : ", resp);
      if (!resp?.data || resp?.data?.error) {
        setCode("");
        throw "Something went wrong!";
      }

      if (!resp?.data.group) {
        setMsg(resp?.data.message);
        setCode("");
        return;
      }

      setGID(resp?.data.group);
      setGroupName(resp?.data.gname);
      setMsg(resp?.data.message);
      return;
    } catch (err) {
      console.log("Error in verifying group code: ", err);
      setMessage(err);
      setStatus(false);
      setToast(true);
      setCode("");
      return;
    } finally {
      setLoad(false);
    }
  };

  return (
    <div>
      {loginStatus === true && loggedInUser.group === "User" ? (
        <>
          <Helmet>
            <title>Helix Employability Tool | Edit User Profile</title>
          </Helmet>
          <Navigate />
          <ToastMessage
            toast={toast}
            onClose={() => setToast(!toast)}
            message={message}
            status={status}
          />
          <br />
          <br />
          <div className="edit-card">
            <Form onSubmit={onUpdate}>
              <Card>
                <Card.Header className="text-center">
                  Edit Your Profile Details
                </Card.Header>
                <Card.Body>
                  <Stack gap={3} className="profile-details">
                    <Stack direction="horizontal">
                      <Form.Label column sm={3} className="userAssmt-label">
                        <b>First Name:</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={"First Name"}
                        value={newFirstName}
                        onChange={(e) => {
                          setNewFirstName(e.target.value);
                        }}
                      />
                    </Stack>
                    <Stack direction="horizontal">
                      <Form.Label column sm={3} className="userAssmt-label">
                        <b>Last Name:</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={"Last Name"}
                        value={newLastName}
                        onChange={(e) => {
                          setNewLastName(e.target.value);
                        }}
                      />
                    </Stack>
                    <Stack direction="horizontal">
                      <Form.Label column sm={3} className="userAssmt-label">
                        <b>Email ID:</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={"Email ID"}
                        value={newEmail_ID}
                        onChange={(e) => {
                          setNewEmail_ID(e.target.value);
                        }}
                      />
                    </Stack>
                    <Stack direction="horizontal">
                      <Form.Label column sm={3} className="userAssmt-label">
                        <b>Groups:</b>
                      </Form.Label>
                      <label>
                        {loggedInUser.groupNames.length !== 0
                          ? loggedInUser.groupNames.join(",")
                          : "None"}
                      </label>
                    </Stack>

                    {/* verifying group code for joining new group  */}
                    <Stack direction="horizontal">
                      <Form.Label column sm={3} className="userAssmt-label">
                        <b>Code:</b>
                      </Form.Label>
                      <Stack direction="vertical">
                        <Stack direction="horizontal">
                          <Form.Control
                            type="text"
                            placeholder={"Enter Group Code"}
                            value={code}
                            onChange={(e) => {
                              setCode(e.target.value);
                            }}
                          />
                          <Button
                            variant="outline-primary"
                            size="sm"
                            className="mx-2"
                            disabled={code.trim() === "" || GID !== "" || load}
                            onClick={() => verifyGroupCode(code)}
                          >
                            {load && GID === "" && code.trim().length !== 0
                              ? "Wait..."
                              : "Verify"}
                          </Button>
                        </Stack>
                        {msg.length > 0 && (
                          <>
                            <Stack direction="horizontal">
                              <Form.Text
                                className=""
                                // className="text-muted"
                                style={{
                                  color: GID === "" ? "#ff0000" : "#147812",
                                  fontSize: "10px",
                                }}
                              >
                                {msg}
                              </Form.Text>
                            </Stack>
                          </>
                        )}
                      </Stack>
                    </Stack>
                    <Stack direction="horizontal">
                      <Form.Label column sm={3} className="userAssmt-label">
                        <b>Password:</b>
                      </Form.Label>
                      <Stack direction="vertical">
                        <Stack direction="horizontal">
                          <Form.Control
                            type={passShow ? "text" : "password"}
                            placeholder="Enter your password to save changes"
                            onChange={(e) => {
                              setPass(e.target.value);
                            }}
                          />
                          {pass.length ? (
                            <Button
                              variant="outline-light"
                              style={{ color: "#163172" }}
                              onClick={togglePassword}
                            >
                              {passShow === false ? (
                                <BsFillEyeFill />
                              ) : (
                                <BsFillEyeSlashFill />
                              )}
                            </Button>
                          ) : (
                            <></>
                          )}
                        </Stack>
                        {errMsg.length > 0 ? (
                          <>
                            <Stack direction="horizontal">
                              <Form.Text
                                className=""
                                // className="text-muted"
                                style={{
                                  color: "#ff0000",
                                  fontSize: "10px",
                                }}
                              >
                                {errMsg}
                              </Form.Text>
                            </Stack>
                          </>
                        ) : (
                          <></>
                        )}
                      </Stack>
                    </Stack>
                  </Stack>
                </Card.Body>
                <Card.Footer className="d-flex justify-content-between">
                  <Button
                    variant="outline-secondary"
                    onClick={() => {
                      setNewFirstName("");
                      setNewLastName("");
                      setNewEmail_ID("");
                      navigate(`/user/profile/${loggedInUser.UID}`);
                    }}
                  >
                    Cancel
                  </Button>{" "}
                  <Button
                    type="submit"
                    variant="outline-success"
                    disabled={
                      (loggedInUser.first_name === newFirstName &&
                      loggedInUser.last_name === newLastName &&
                      loggedInUser.email_ID === newEmail_ID &&
                      GID === "") || load
                    }
                  >
                    {load && (code.trim() === "" || GID !== "")
                      ? "Wait..."
                      : "Save"}
                  </Button>
                </Card.Footer>
              </Card>
            </Form>
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
};

export default EditProfile;
