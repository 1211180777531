import React, { useEffect, useRef, useState, useContext } from "react";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import { AssmtContext } from "../../Contexts/AssmtContext";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { GroupContext } from "../../Contexts/GroupContext";
import CheckModal from "../../Common/CheckModal";

const GroupListTable = (props) => {
  const { setToast, setMessage, setStatus } = useContext(AssmtContext);
  const { groupList, setGroupList } = useContext(GroupContext);
  const [show, setShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [group, setGroup] = useState({
    GID: "",
    g_name: "",
    g_code: "",
    g_desc: "",
    isEditing: false,
  });
  const navigate = useNavigate();

  //for keeping a track while editing info of a group
  const [edit_Group, setEditGroup] = useState({
    g_name: "",
    g_code: "",
    g_desc: "",
    GID: ""
  });

  $.DataTable = require("datatables.net");
  const tableRef = useRef();
  const apiCall = props.apiCall;

  const handleClose = () => {
    setShow(false);
    setGroup({
      GID: "",
      g_name: "",
      g_code: "",
      g_desc: "",
      isEditing: false,
    });
  };
  const [groupsToRemove, setGroupsToRemove] = useState([]);

  const createGroup = async () => {
    try {
      if (!group.g_name.trim() || !group.g_code.trim()) {
        setMessage("Fill in the required details!");
        setToast(true);
        setStatus(false);
        return;
      }

      const response = await apiCall("admin/createGroup", "POST", group);

      if (response.response?.data?.error) {
        setMessage(response.response?.data?.error);
        setToast(true);
        setStatus(false);
        return;
      }

      setMessage(response.data?.message);
      setToast(true);
      setStatus(true);

      //update the array
      let grp = { ...group };
      grp["GID"] = response.data?.GID;
      grp["no_members"] = 0;
      setGroupList((list) => {
        return [...list, grp];
      });

      handleClose();
    } catch (err) {
      console.log("Error in creating groups: ", err);
      setMessage("Sorry, something went wrong!");
      setToast(true);
      setShow(false);
    }
  };

  const deleteGroups = async () => {
    try {
      const response = await apiCall(
        `admin/deleteGroups/`,
        "POST",
       { GIDs: groupsToRemove}
      );

      if (response.response?.data?.error) {
        setMessage(response.response?.data?.error);
        setToast(true);
        setStatus(false);
        return;
      }

      setMessage(response.data?.message);
      setToast(true);
      setStatus(true);

      //update the array
      setGroupList((list) => {
        return list.filter((ele) => {
          if(groupsToRemove.includes(ele.GID))
            return false;
          return true;
        });
      });
      setGroupsToRemove([]);
    } catch (err) {
      console.log("Error in creating groups: ", err);
      setMessage("Sorry, something went wrong!");
      setToast(true);
    } finally {
    setShowDeleteModal(false);
    }
  };

  const editGroup = async () => {
    try {
      if (!edit_Group.g_name.trim() || !edit_Group.g_code.trim()) {
        setMessage("Fill in the required details!");
        setToast(true);
        setStatus(false);
        return;
      }

      const response = await apiCall(
        `admin/editGroup/${group.GID}`,
        "PUT",
        edit_Group
      );

      if (response.response?.data?.error) {
        setMessage(response.response?.data?.error);
        setToast(true);
        setStatus(false);
        return;
      }

      setMessage(response.data?.message);
      setToast(true);
      setStatus(true);

      //update the array
      setGroupList((list) => {
        return list.map((e) => {
          if (e.GID === edit_Group.GID) {
            return {
              GID: edit_Group.GID,
              g_name: edit_Group.g_name,
              g_code: edit_Group.g_code,
              g_desc: edit_Group.g_desc,
              no_members: e.no_members,
              isEditing: false,
            };
          }

          return e;
        });
      });

      handleClose();
    } catch (err) {
      console.log("Error in creating groups: ", err);
      setMessage(err);
      setToast(true);
      setShow(false);
    }
  };

  useEffect(() => {
    const table = $(tableRef.current).DataTable({
      order: [[1,'asc',[4,'desc']]],
      data: groupList,
      columns: [
        {
          className: "dt-body-center",
          title: "",
          render: function (data, type, row) {
            return [1, 3].includes(row?.GID)
              ? null
              : `<input type='checkbox' class="delete-check" ${row.no_members != 0 ? "disabled" : null} style="cursor:pointer;" />`;
          },
        },
        {
          className: "dt-body-left",
          title: "Group Name",
          data: "g_name",
        },
        {
          className: "dt-body-left",
          title: "Group Description",
          data: "g_desc",
          render: function (data) {
            if (data.length > 30) {
              return data.substring(0, 30) + "....";
            }

            return data.length === 0 ? "----" : data;
          },
        },
        {
          className: "dt-body-left",
          title: "Group Code",
          data: "g_code",
          render: function (data) {
            return data ? data : "----";
          },
        },
        {
          className: "dt-body-center",
          title: "Members",
          data: "no_members",
          render: function (data) {
            return data < 10 ? `0${data}` : data;
          },
        },
        //conditional disabling for Admin, Disabled and Standard User Group
        // GID: 1 (Admins)
        // GID: 2 (Users)
        // GID: 3 (Disabled Users)
        {
          className: "dt-body-left",
          title: "Action",
          data: "GID",
          render: function (data) {
            return `<Button class="edit-btn btn btn-outline-secondary btn-sm ${
              [1, 2, 3].includes(data) && "disabled"
            }">
                Edit
              </Button>`;
          },
        },
      ],
      destroy: true,
    });

    $("#example4 tbody").on("click", ".edit-btn", function (e) {
      e.stopPropagation();
      const data = table.row($(this).parents("tr")).data();
      if (data !== undefined) {
        setGroup({
          GID: data.GID,
          g_name: data.g_name,
          g_desc: data.g_desc,
          g_code: data.g_code,
          isEditing: true,
        });
        setEditGroup({
          GID: data.GID,
          g_name: data.g_name,
          g_code: data.g_code,
          g_desc: data.g_desc,
        });
        setShow(true);
      }
    });

    $("#example4 tbody").on("click", ".delete-check", function (e) {
      e.stopPropagation();
      const data = table.row($(this).parents("tr")).data();
      if (data !== undefined) {
        e.target.checked
          ? setGroupsToRemove((groups) => [...groups, data.GID])
          : setGroupsToRemove((groups) => {
              return groups.filter((grp_id) => {
                return grp_id != data.GID;
              });
            });
      }
    });

    $("#example4 tbody").on("click", "tr", function () {
      const data = table.row($(this)).data();
      if (!data) return;
      if (data) {
        navigate(`/admin/group/${data.GID}`, {
          state: data,
        });
      }
    });

    return function () {
      table.destroy();
    };
  }, [groupList]);

  return (
    <>
      {/* modal for creating and editing group */}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {group.isEditing
              ? "Edit Group"
              : `Create New Group`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {group.isEditing ? (
            <>
              {/* group name  */}
              <label htmlFor="g_name">
                Group Name <sup style={{ color: "red" }}>*</sup>
              </label>
              <div style={{ width: "100%" }} className="my-1">
                <input
                  name="g_name"
                  type="text"
                  id="g_name"
                  value={edit_Group.g_name}
                  style={{ width: "90%" }}
                  onChange={(e) => {
                    setEditGroup((obj) => {
                      return { ...obj, [e.target.name]: e.target.value };
                    });
                  }}
                />
              </div>
              {/* group description  */}
              <label htmlFor="g_desc">Group Description</label>
              <div style={{ width: "100%" }} className="my-1">
                <input
                  name="g_desc"
                  type="text"
                  id="g_desc"
                  value={edit_Group.g_desc}
                  style={{ width: "90%" }}
                  onChange={(e) => {
                    setEditGroup((obj) => {
                      return { ...obj, [e.target.name]: e.target.value };
                    });
                  }}
                />
              </div>
              {/* group code  */}
              <label htmlFor="g_code">
                Group Code <sup style={{ color: "red" }}>*</sup>
                {"   "}{" "}
                <span style={{ fontSize: "10px" }}>
                  (This is case insensitive.)
                </span>
              </label>
              <div style={{ width: "100%" }} className="my-1">
                <input
                  name="g_code"
                  type="text"
                  id="g_code"
                  style={{ width: "90%" }}
                  value={edit_Group.g_code}
                  onChange={(e) => {
                    setEditGroup((obj) => {
                      return { ...obj, [e.target.name]: e.target.value };
                    });
                  }}
                />
              </div>
            </>
          ) : (
            <>
              {/* group name  */}
              <label htmlFor="g_name">
                Group Name <sup style={{ color: "red" }}>*</sup>
              </label>
              <div style={{ width: "100%" }} className="my-1">
                <input
                  name="g_name"
                  type="text"
                  id="g_name"
                  value={group.g_name}
                  style={{ width: "90%" }}
                  onChange={(e) => {
                    setGroup((obj) => {
                      return { ...obj, [e.target.name]: e.target.value };
                    });
                  }}
                />
              </div>
              {/* group description  */}
              <label htmlFor="g_desc">Group Description</label>
              <div style={{ width: "100%" }} className="my-1">
                <input
                  name="g_desc"
                  type="text"
                  id="g_desc"
                  value={group.g_desc}
                  style={{ width: "90%" }}
                  onChange={(e) => {
                    setGroup((obj) => {
                      return { ...obj, [e.target.name]: e.target.value };
                    });
                  }}
                />
              </div>
              {/* group code  */}
              <label htmlFor="g_code">
                Group Code <sup style={{ color: "red" }}>*</sup>{" "}
                <span style={{ fontSize: "10px" }}>
                  (This is case insensitive.)
                </span>
              </label>
              <div style={{ width: "100%" }} className="my-1">
                <input
                  name="g_code"
                  type="text"
                  id="g_code"
                  value={group.g_code}
                  style={{ width: "90%" }}
                  onChange={(e) => {
                    setGroup((obj) => {
                      return { ...obj, [e.target.name]: e.target.value };
                    });
                  }}
                />
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {group.isEditing ? (
            <>
              <Button
                disabled={
                  group.g_name === edit_Group.g_name &&
                  group.g_desc === edit_Group.g_desc &&
                  group.g_code === edit_Group.g_code
                }
                variant="primary"
                onClick={() => {
                  editGroup();
                }}
              >
                Save
              </Button>
            </>
          ) : (
            <>
              <Button variant="primary" onClick={createGroup}>
                Create Group
              </Button>
            </>
          )}
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for deleting the group */}
      <CheckModal 
       show={showDeleteModal}
       handleClose={()=> setShowDeleteModal(false)}
       title={'Remove Groups'}
       bodyText={'Are you sure to remove all of the selected groups? This action cannot be reverted.'}
       btnText={'Delete'}
       callback={deleteGroups}
      />

      <div className="d-flex justify-content-end my-3">
        <Button variant="outline-primary" onClick={() => setShow(true)}>
          Create Group
        </Button>
        {groupsToRemove.length > 0 && (
          <Button
            variant="outline-danger"
            className="mx-2"
            onClick={() => setShowDeleteModal(true)}
          >
            Remove Groups
          </Button>
        )}
      </div>
      <div className="user-table">
        <table
          className="hover dt[-head|-body]-left cell-border"
          width="100%"
          id="example4"
          ref={tableRef}
        >
          <thead>
            <tr>
              <th></th>
              <th>Group Name</th>
              <th>Group Description</th>
              <th>Group Code</th>
              <th style={{ textAlign: "center" }}>Members</th>
              <th>Action</th>
            </tr>
          </thead>
        </table>
      </div>
    </>
  );
};

//memoized the component to prevent unnecessary renderings
export default React.memo(GroupListTable);
