import React, { useContext, useState, useEffect } from "react";
import $ from "jquery";
import { useNavigate, useParams } from "react-router-dom";
import { Stack, Form, Card, Button, Accordion, Badge, Table, Modal } from "react-bootstrap";
import { AssmtContext } from "../../Contexts/AssmtContext";
import Navigate from "../../Navigate";
import NFCListSet from "../Assmt/NFCList.js";
import ToastMessage from "../../ToastMessage";
import { FaEye } from "react-icons/fa";
import { AiOutlineRadarChart, AiOutlineCloseCircle, AiTwotoneDelete } from "react-icons/ai";
import { MdArrowBack } from "react-icons/md";
import { RiSaveFill } from "react-icons/ri";
import {
  TbFileSymlink,
  TbFileCheck,
  TbFileX,
  TbFiles,
  TbFileDownload,
  TbFileAnalytics,
  TbFileSettings,
  TbCheckbox,
} from "react-icons/tb";
import { AccountContext } from "../../Contexts/AccountContext";
import AccessDenied from "../../AccessDenied";
import { CSVLink } from "react-csv";
import { FaFolderMinus, FaFolderPlus } from "react-icons/fa";
import { default as RModal } from "react-modal/lib/components/Modal";
import PreviewAssmt from "../ViewAssmt/PreviewAssmt";

RModal.setAppElement("#root");

const ViewEachAssmt = (props) => {
  const { loginStatus, loggedInUser, apiCall } = useContext(AccountContext);

  const { toast, setToast, message, setMessage, status, setStatus, setAssmt } = useContext(AssmtContext);

  const navigate = useNavigate();

  const AID = props.AID;

  const [assmtObj, setAssmtObj] = useState({});
  const [sectionList, setSectionList] = useState([]);
  const [deletedNFCList, setDeletedNFCList] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [analysisBtnDisabled, setAnalysisBtnDisabled] = useState(false);
  const [editNFCRange, setEditNFCRange] = useState(true);
  const [currNFCRangeIdx, setCurrNFCRangeIdx] = useState(0);
  const [prevBool, setPrevBool] = useState(false);
  const [nfcEditing, setNfcEditing] = useState(false);
  const [nfcCheckedList, setNfcCheckedList] = useState([]);
  const [hcsEditing, setHcsEditing] = useState(false);
  const [hcsCheckedList, setHcsCheckedList] = useState([]);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [weightList, setWeightList] = useState([]);
  const [analysisTypeList, setAnalysisTypeList] = useState([]);
  const [allUserRes, setAllUserRes] = useState([]);
  const [isOpenNFCModal, setIsOpenNFCModal] = useState(false);

  const [deleteOpr, setDeleteOpr] = useState("");

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const toggleBtn = () => {
    setBtnDisabled(!btnDisabled);
  };

  const toggleprev = () => {
    setPrevBool(!prevBool);
  };

  const toggleAnalysisBtn = () => {
    setAnalysisBtnDisabled(!analysisBtnDisabled);
  };

  const editNFCRangefunc = () => {
    setEditNFCRange(!editNFCRange);
  };

  const getAssmtdetails = async (AID) => {
    const { data } = await apiCall(`admin/getAssmtDetailsByID/${AID}`);

    const cnt = await apiCall(`admin/getResponseCount/${AID}`);

    data[0]["hasResponse"] = cnt.data > 0 ? true : false;
    setAssmtObj(data[0]);
  };

  const getAssmt = async (AID) => {
    const { data } = await apiCall(`admin/getAssmt/${AID}`);
    let sectionList = [];
    data.forEach(async (sec, sidx, sArray) => {
      let sObj = {};
      sObj["sName"] = sec.sec_name;
      sObj["sDesc"] = sec.sec_desc;
      sObj["sType"] = sec.sec_analysisType;
      sObj["SecID"] = sec.SecID;
      sObj["nfc"] = sec.nfc;
      sObj["nfcData"] = sec.nfcData;
      let qIDs = [];
      if (sec.questions.length === 0) sObj["qIDs"] = qIDs;
      sec.questions.forEach(async (que, qidx, qArray) => {
        let qObj = {};
        qObj["sidx"] = sidx;
        qObj["QID"] = que.QID;
        qObj["qQuestion"] = que.question;
        qObj["qType"] = que.type;
        qObj["inQBank"] = que.inQBank;
        qObj["hcs"] = que.hcs;
        let cIDs = [];
        if (que.choices.length === 0) qObj["cIDs"] = cIDs;
        que.choices.forEach(async (ch, cidx, cArray) => {
          let cObj = {};
          cObj["CID"] = ch.CID;
          cObj["qidx"] = qidx;
          cObj["sidx"] = sidx;
          cObj["cName"] = ch.choice;
          cObj["weight"] = ch.weightage;
          cIDs.push(cObj);
          if (cidx === cArray.length - 1) {
            qObj["cIDs"] = cIDs;
          }
        });
        qIDs.push(qObj);
        if (qidx === qArray.length - 1) {
          sObj["qIDs"] = qIDs;
        }
      });
      sectionList.push(sObj);
      if (sidx === sArray.length - 1) {
        setSectionList(sectionList);
      }
    });
  };

  useEffect(() => {
    if (!AID || AID === "" || !loggedInUser.jwtToken) return;
    getAssmtdetails(AID);
  }, [AID, loggedInUser.jwtToken]);

  useEffect(() => {
    if (!AID || AID === "" || !loggedInUser.jwtToken) return;
    getAssmt(AID);
  }, [AID, loggedInUser.jwtToken]);

  const handleOnClickView = (id) => {
    let assmtName = { assmtName: assmtObj.name };
    localStorage.setItem("viewResAssmtName", JSON.stringify(assmtName));
    navigate(`/admin/viewAllResponse/${id}`);
  };

  const handleWeightChange = (CID, weightage) => {
    if (weightList.length) {
      let newWeightList = weightList.filter((i) => i.CID !== CID);
      let newWeight = {};
      newWeight["CID"] = CID;
      newWeight["weightage"] = weightage;

      newWeightList.push(newWeight);
      setWeightList(newWeightList);
    } else {
      let newWeight = {
        CID: CID,
        weightage: weightage,
      };
      let newWeightList = [...weightList, newWeight];
      setWeightList(newWeightList);
    }
  };

  const handleAnalysisTypeChange = (SecID, sec_analysisType) => {
    if (analysisTypeList.length) {
      let newAnalysisTypeList = analysisTypeList.filter((i) => i.SecID !== SecID);
      let newType = {};
      newType["SecID"] = SecID;
      newType["sec_analysisType"] = sec_analysisType;

      newAnalysisTypeList.push(newType);
      setAnalysisTypeList(newAnalysisTypeList);
    } else {
      let newType = {
        SecID: SecID,
        sec_analysisType: sec_analysisType,
      };
      let newAnalysisTypeList = [...analysisTypeList, newType];
      setAnalysisTypeList(newAnalysisTypeList);
    }
  };

  const updateQBank = async (QID, setInQBank) => {
    await apiCall(`admin/AddRemoveFrmQBank/${QID}`, "PUT", {
      inQBank: setInQBank,
    }).then(() => {
      getAssmt(AID);
    });
  };

  const handleOnClickEdit = (id, deleteOldAssmt) => {
    navigate(`/admin/editAssmt/${id}`);
    let data = { editType: deleteOldAssmt };
    localStorage.setItem("assEditType", JSON.stringify(data));
  };

  const handleOnClickPublish = async (id) => {
    let flag = true;
    for (let i = 0; i < sectionList.length; i++) {
      if (sectionList[i].nfc === "1" && sectionList[i].nfcData.length === 0) {
        flag = false;
        setMessage(`NFC checked but range not provided in Section #${i + 1}`);
        setStatus(false);
        setToast(true);
        break;
      }
      if (sectionList[i].sType === "") {
        flag = false;
        setMessage(`Analysis type not selected for Section #${i + 1}`);
        setStatus(false);
        setToast(true);
        break;
      }
      if (sectionList[i].qIDs.length === 0) {
        flag = false;
        setMessage(`No question found in Section #${i + 1}, to publish add atleast one question and choice`);
        setStatus(false);
        setToast(true);
      } else {
        let questions = sectionList[i].qIDs;
        for (let j = 0; j < questions.length; j++) {
          if (questions[j].cIDs.length === 0) {
            flag = false;
            setMessage(
              `No choices found in Section #${i + 1}, question #${
                j + 1
              }, to publish add atleast one choice and it's weightage`
            );
            setStatus(false);
            setToast(true);
          }
        }
      }
    }
    if (flag) {
      let publish = await apiCall(`admin/publishAssmt/${id}`, "PUT");
      if (publish) {
        const { data } = await apiCall(`admin/`);
        setAssmt(data);
        setMessage("Assessment published!");
        setStatus(true);
        setToast(true);

        navigate("/admin");
      }
    }
  };

  const handleOnClickUnpublish = async (id) => {
    let unpublish = await apiCall(`admin/unpublishAssmt/${id}`, "PUT");
    if (unpublish) {
      const { data } = await apiCall(`admin/`);
      //   axios.get(`${getBaseUrl()}/admin/`, {
      //     headers: {
      //       Authorization: `Bearer ${loggedInUser.jwtToken}`,
      //     },
      //   });
      setAssmt(data);
      setMessage("Assessment unpublished!");
      setStatus(true);
      setToast(true);

      navigate("/admin");
    }
  };

  // const handleOnClickDelete = async (id) => {
  //   let deleteAssmt = apiCall(`admin/deleteAssmt/${id}`, "DELETE");
  //   if (deleteAssmt) {
  //     const { data } = await apiCall("admin/");
  //     //   axios.get(`${getBaseUrl()}/admin/`, {
  //     //     headers: {
  //     //       Authorization: `Bearer ${loggedInUser.jwtToken}`,
  //     //     },
  //     //   });
  //     setMessage("Assessment deleted!");
  //     setStatus(true);
  //     setToast(true);
  //     setAssmt(data);
  //     navigate("/admin");
  //   }
  // };

  function showNFCModal() {
    setIsOpenNFCModal(!isOpenNFCModal);
  }

  function nfcCheckBoxShow() {
    setNfcEditing(!nfcEditing);
    //   if(nfcEditing) {
    //     $('#nfccheckbox').attr('disabled', 'disabled');
    //     setNfcEditing(false);
    //   }
    //   else {
    //     $('#nfccheckbox').removeAttr('disabled')
    //     setNfcEditing(true);
    //   }
  }

  function handleNFCCheckedChange(SecID, value, secIdx) {
    if (value !== true || sectionList[secIdx].nfcData.length !== 0 || assmtObj.published !== "Yes") {
      if (nfcCheckedList.length) {
        let newNfcCheckedList = nfcCheckedList.filter((i) => i.SecID !== SecID);
        let newType = {};
        newType["SecID"] = SecID;
        newType["nfc"] = value;

        newNfcCheckedList.push(newType);
        setNfcCheckedList(newNfcCheckedList);
      } else {
        let newType = {
          SecID: SecID,
          nfc: value,
        };
        let newNfcCheckedList = [...nfcCheckedList, newType];
        setNfcCheckedList(newNfcCheckedList);
      }
    } else {
      setMessage(`Please add a NFC Range before checking the NFC checkbox`);
      setStatus(false);
      setToast(true);
      document.getElementById("nfccheckbox" + secIdx).checked = false;
    }
  }

  function hcsCheckBoxShow() {
    setHcsEditing(!hcsEditing);
  }

  function handleHCSCheckedChange(QID, value) {
    if (hcsCheckedList.length) {
      let newHcsCheckedList = hcsCheckedList.filter((i) => i.QID !== QID);
      let newType = {};
      newType["QID"] = QID;
      newType["hcs"] = value;

      newHcsCheckedList.push(newType);
      setHcsCheckedList(newHcsCheckedList);
    } else {
      let newType = {
        QID: QID,
        hcs: value,
      };
      let newHcsCheckedList = [...hcsCheckedList, newType];
      setHcsCheckedList(newHcsCheckedList);
    }
  }

  const updateHCSCheckBox = async (hcsCheckBoxList, AID) => {
    try {
      if (hcsCheckBoxList.length) {
        let hcsCheckBoxObj = { hcsCheckBoxList: hcsCheckBoxList };
        await apiCall(`admin/updatehcscheckbox`, "PUT", hcsCheckBoxObj);

        if (assmtObj.hasResponse === true) {
          await apiCall(`analysis/updatehcsscore/${AID}`, "PUT");
        }
      }
      return true;
    } catch (error) {
      return false;
    }
  };

  const customStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "#f0f7fc",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      maxHeight: "50%",
      width: "50%",
      transform: "translate(-50%, -50%)",
      overflow: "auto",
      backgroundColor: "white",
    },
  };

  function handleNFCAdd(sidx) {
    let newSectionList = [...sectionList];
    let newNFCList = newSectionList[sidx].nfcData;
    let nfc = {
      startWeight: "",
      endWeight: "",
      eqWeight: "",
    };
    newNFCList.push(nfc);
    newSectionList[sidx].nfcData = newNFCList;
    setSectionList(newSectionList);
  }

  function handleNFCRemove(sidx, NFCIdx) {
    let newSectionList = [...sectionList];
    let newNFCList = newSectionList[sidx].nfcData;
    if (newNFCList.length === 1 && newSectionList[sidx].nfc === "1") {
      setMessage(`Since NFC is checked, one range required in this section`);
      setStatus(false);
      setToast(true);
    } else {
      if (newNFCList[NFCIdx].NFCID) {
        let newDeletedNFCList = [...deletedNFCList];
        newDeletedNFCList.push(newNFCList[NFCIdx].NFCID);
        setDeletedNFCList(newDeletedNFCList);
      }
      newNFCList.splice(NFCIdx, 1);
      setSectionList(newSectionList);
    }
  }

  function handleNFCChange(e, sidx, NFCIdx) {
    let newSectionList = [...sectionList];
    let newNFCList = newSectionList[sidx].nfcData;
    const { name, value } = e.target;
    newNFCList[NFCIdx][name] = value;
    newSectionList[sidx].nfcData = newNFCList;
    setSectionList(newSectionList);
  }

  let valid = true;

  function checkNFCOverlap() {
    let newSectionList = [...sectionList];
    valid = true;
    for (let i = 0; i < newSectionList.length; i++) {
      validateNFCOverlap("save", i);
    }
  }

  function sortFunction(a, b) {
    if (a[0] === b[0]) {
      return 0;
    } else {
      return a[0] < b[0] ? -1 : 1;
    }
  }

  function validateNFCOverlap(from, sidx) {
    let newSectionList = [...sectionList];
    let newNFCList = newSectionList[sidx].nfcData;

    for (let i = 0; i < newNFCList.length; i++) {
      if (newNFCList[i]["startWeight"] === "" || newNFCList[i]["eqWeight"] === "") {
        valid = false;
        if (from === "save") {
          setMessage(`Complete all the fields in the NFC range before submitting`);
          setStatus(false);
          setToast(true);
        }
        break;
      }
    }

    if (valid) {
      let eqWeightList = [];
      const arr2D = [];

      for (let i = 0; i < newNFCList.length; i++) {
        let tempArr = [];
        if (newNFCList[i]["endWeight"] === "")
          tempArr = [Number(newNFCList[i]["startWeight"]), -1000, Number(newNFCList[i]["eqWeight"])];
        else
          tempArr = [
            Number(newNFCList[i]["startWeight"]),
            Number(newNFCList[i]["endWeight"]),
            Number(newNFCList[i]["eqWeight"]),
          ];
        arr2D.push(tempArr);
      }

      if (valid) {
        arr2D.sort(sortFunction);
        console.log(arr2D);
        for (let i = 0; i < arr2D.length; i++) {
          if (!eqWeightList.includes(arr2D[i][2])) {
            eqWeightList.push(arr2D[i][2]);
            if (arr2D[i][1] !== "" && arr2D[i][1] !== -1000) {
              if (arr2D[i][0] > arr2D[i][1]) {
                valid = false;
                break;
              }
              let end = arr2D[i][1];
              if (i !== arr2D.length - 1 && arr2D[i + 1][0] <= end) {
                valid = false;
                break;
              }
            } else {
              if (i !== arr2D.length - 1) {
                if (arr2D[i][0] === arr2D[i + 1][0]) {
                  valid = false;
                  break;
                }
              }
            }
          } else {
            valid = false;
            break;
          }
        }
      }
      if (!valid) {
        setMessage(`NFC range not valid in section ${sidx + 1}`);
        setStatus(false);
        setToast(true);
      } else {
        if (from === "save" && sidx === newSectionList.length - 1) {
          showNFCModal();
          updateNFC();
        }
      }
    }
  }

  async function updateNFC() {
    let updateDone = await apiCall(`admin/updateNfc/${AID}`, "PUT", { sectionList, deletedNFCList });
    if (assmtObj.hasResponse === true) {
      await apiCall(`analysis/updatesecscore/${AID}`, "GET");
    }
    if (updateDone) {
      //make an endpoint only to update NFC data
      setMessage(`NFC data updated successfully!`);
      setStatus(true);
      setToast(true);
    }
  }

  return (
    <div>
      {loginStatus === true && loggedInUser.group === "Admin" ? (
        <>
          <div>
            {props.preview === false ? (
              <>
                {sectionList.map((singleSec, sidx) => (
                  <div key={sidx}>
                    <Accordion>
                      <Accordion.Item eventKey={sidx}>
                        <Accordion.Header>
                          Section #{sidx + 1}
                          <div
                            key={sidx}
                            style={{
                              width: "45%",
                              position: "absolute",
                              right: "0",
                              marginRight: "5%",
                              display: "flex",
                              justifyContent: "left",
                            }}
                          >
                            <RModal isOpen={isOpenNFCModal} style={customStyles}>
                              <ol key={sidx}>
                                {sectionList[currNFCRangeIdx].nfcData.map((NFCItem, NFCIdx) => (
                                  <Stack gap={3} key={NFCIdx}>
                                    <li>
                                      <NFCListSet
                                        editing={editNFCRange}
                                        NFCIdx={NFCIdx}
                                        sidx={currNFCRangeIdx}
                                        NFCItem={NFCItem}
                                        handleNFCRemove={(id) => handleNFCRemove(currNFCRangeIdx, id)}
                                        handleNFCChange={(e, index) => handleNFCChange(e, currNFCRangeIdx, index)}
                                        validateNFCOverlap={() => validateNFCOverlap("", currNFCRangeIdx)}
                                      />
                                    </li>
                                  </Stack>
                                ))}
                              </ol>
                              <div style={{ width: "100%", paddingLeft: "30px", display: "flex" }}>
                                <div>
                                  <Button
                                    // disabled={editing}
                                    className="ms-auto"
                                    variant="outline-light"
                                    style={{ color: "#163172", fontSize: "14px" }}
                                    onClick={() => {
                                      handleNFCAdd(currNFCRangeIdx);
                                    }}
                                  >
                                    ✚ Set
                                  </Button>
                                </div>
                                <div style={{ marginLeft: "10px" }}>
                                  {editNFCRange === false ? (
                                    <Button
                                      className="ms-auto"
                                      variant="outline-light"
                                      style={{ color: "#163172", fontSize: "14px" }}
                                      onClick={() => {
                                        editNFCRangefunc();
                                        checkNFCOverlap();
                                      }}
                                    >
                                      <RiSaveFill style={{ color: "#163172", fontSize: "20px" }} />
                                      &nbsp;&nbsp; Save
                                    </Button>
                                  ) : (
                                    <Button
                                      className="ms-auto"
                                      variant="outline-light"
                                      style={{ color: "#163172", fontSize: "14px" }}
                                      onClick={() => {
                                        editNFCRangefunc();
                                      }}
                                    >
                                      <TbFileSettings style={{ color: "#163172", fontSize: "20px" }} />
                                      &nbsp;&nbsp; Edit
                                    </Button>
                                  )}
                                </div>
                                <div style={{ marginLeft: "10px" }}>
                                  <Button
                                    // disabled={editing}
                                    className="ms-auto"
                                    variant="outline-light"
                                    style={{ color: "#163172", fontSize: "14px" }}
                                    onClick={() => {
                                      showNFCModal();
                                    }}
                                  >
                                    <AiOutlineCloseCircle style={{ color: "#BD4B4B", fontSize: "20px" }} />
                                    &nbsp;&nbsp; Close
                                  </Button>
                                </div>
                              </div>
                            </RModal>

                            <form style={{ marginTop: "auto", marginBottom: "auto", marginRight: "10px" }}>
                              {/* style={{marginTop: "auto", marginBottom: "auto", marginRight: "10px"}} */}
                              <label>
                                <input
                                  type={"checkbox"}
                                  defaultChecked={singleSec && singleSec.nfc === "1" ? true : false}
                                  name={"nfc"}
                                  className={"nfccheckbox"}
                                  id={"nfccheckbox" + sidx}
                                  disabled={!nfcEditing}
                                  onChange={(e) => {
                                    handleNFCCheckedChange(singleSec.SecID, e.target.checked, sidx);
                                  }}
                                  style={{ marginRight: "5px" }}
                                />
                                NFC
                              </label>
                            </form>

                            <Button
                              // disabled={editing}
                              style={{ width: "125px", marginRight: "10px" }}
                              onClick={() => {
                                showNFCModal();
                                setCurrNFCRangeIdx(sidx);
                              }}
                            >
                              NFC Range
                            </Button>

                            {!singleSec || analysisBtnDisabled === false ? (
                              <>
                                {(() => {
                                  if (singleSec.sType === null || singleSec.sType === "") {
                                    return (
                                      <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                                        Analysis type: Not defined
                                      </div>
                                    );
                                  }
                                  return (
                                    <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                                      Analysis Type: {singleSec.sType}
                                    </div>
                                  );
                                })()}
                              </>
                            ) : (
                              <Form.Select
                                name="sAnalysisType"
                                style={{ width: "150px" }}
                                defaultValue={singleSec.sType}
                                onChange={(e) => {
                                  handleAnalysisTypeChange(singleSec.SecID, e.target.value);
                                }}
                              >
                                <option value="" disabled selected>
                                  Analysis Type
                                </option>
                                <option key={"weighted_avg"} value={"weighted_avg"}>
                                  Weighted Avg
                                </option>
                                <option key={"average"} value={"average"}>
                                  Average
                                </option>
                                <option key={"sum"} value={"sum"}>
                                  Sum
                                </option>
                              </Form.Select>
                            )}
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <Stack>
                            {singleSec.sName.length !== 0 ? <label>Name: {singleSec.sName}</label> : ""}
                            {singleSec.sDesc.length !== 0 ? <label>Description: {singleSec.sDesc}</label> : ""}
                            {singleSec.qIDs.length !== 0 ? (
                              <div>
                                <br /> <label>Questions:</label>
                              </div>
                            ) : (
                              ""
                            )}
                            <Stack>
                              <ol>
                                {singleSec.qIDs.map((singleQue, qidx) => (
                                  <li key={qidx}>
                                    <div className="view-question-body" key={qidx}>
                                      <div className="d-flex justify-content-between">
                                        <label>{singleQue.qQuestion}</label>
                                        {singleQue.inQBank === 1 ? (
                                          <Button
                                            size="sm"
                                            onClick={() => {
                                              updateQBank(singleQue.QID, 0);
                                            }}
                                          >
                                            <FaFolderMinus />
                                            &nbsp;&nbsp; QBank
                                          </Button>
                                        ) : (
                                          <Button
                                            size="sm"
                                            onClick={() => {
                                              updateQBank(singleQue.QID, 1);
                                            }}
                                          >
                                            <FaFolderPlus />
                                            &nbsp; QBank
                                          </Button>
                                        )}
                                      </div>
                                      <div style={{ display: "flex", textAlign: "center", height: "20px" }}>
                                        <Badge bg="secondary">Type: {singleQue.qType}</Badge>

                                        <Form.Check
                                          disabled={!hcsEditing}
                                          type="checkbox"
                                          name="hcs"
                                          label={"HCS"}
                                          id="hcscheckbox"
                                          defaultChecked={singleQue.hcs === "1" ? true : false}
                                          onChange={(e) => {
                                            handleHCSCheckedChange(singleQue.QID, e.target.checked);
                                          }}
                                          style={{ marginLeft: "10px" }}
                                        />
                                      </div>
                                      <br />
                                      <br />
                                      <div>
                                        <Table bordered>
                                          <tbody>
                                            <tr>
                                              <td>#</td>
                                              <td>Choices</td>
                                              <td>Weightage</td>
                                            </tr>

                                            {singleQue.cIDs.map((singleCho, cidx) => (
                                              <tr key={cidx}>
                                                <td>{cidx + 1}</td>
                                                <td className="choice-col">{singleCho.cName}</td>
                                                <td style={{ width: "15%" }}>
                                                  {btnDisabled === false ? (
                                                    <>{singleCho.weight}</>
                                                  ) : (
                                                    <Form.Control
                                                      type="number"
                                                      step="0.1"
                                                      placeholder="Weight"
                                                      name="weight"
                                                      defaultValue={singleCho.weight}
                                                      onChange={(e) => {
                                                        handleWeightChange(singleCho.CID, e.target.value);
                                                      }}
                                                    />
                                                  )}
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </Table>
                                      </div>
                                    </div>
                                    <br />
                                  </li>
                                ))}
                              </ol>
                            </Stack>
                          </Stack>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <br />
                  </div>
                ))}
              </>
            ) : (
              <PreviewAssmt sectionList={sectionList} />
            )}
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
};

export default ViewEachAssmt;
