import React, { useEffect, useState, useContext } from "react";
import Modal from "react-modal/lib/components/Modal";

import { AssmtContext } from "../../Contexts/AssmtContext";
import { EditAssmtContext } from "../../Contexts/EditAssmtContext";

import EditQuestionSet from "./EditQuestionSet";
import NFCListSet from "../Assmt/NFCList";

import { Form, Stack, Accordion, Button } from "react-bootstrap";
import { RiDeleteBin2Fill, RiSaveFill } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ModalListSet from "../Assmt/ModalListSet";
import { isNFCComplete, validateNFCOverlapHelper } from "../../../utils/helpers";

const EditSectionSet = (props) => {
  const [qbChoiceList, setQBChoiceList] = useState([]);
  const [prevNFCListState, setPrevNFCListState] = useState([]);
  const [isOpenNFCModal, setIsOpenNFCModal] = useState(false);
  const textOnly = props.textOnly;

  const { sectionList, handleSectionRemove, handleSectionChange, handleSectionList } = useContext(EditAssmtContext);

  const { questionBank, setToast, setMessage, setStatus } = useContext(AssmtContext);
  
  const [questionList, setQuestionList] = useState(sectionList[props.sidx].qIDs);
  const [NFCList, setNFCList] = useState(sectionList[props.sidx].nfcData);

  const [editing, setEditing] = useState(true);
  const toggleEdit = () => {
    setEditing(!editing);
  };

  useEffect(() => {
    setQuestionList(sectionList[props.sidx].qIDs);
    setNFCList(sectionList[props.sidx].nfcData);
  }, [sectionList[props.sidx].qIDs, sectionList[props.sidx].nfcData]);

  useEffect(() => {
    handleSectionList(questionList, props.sidx, "ques");
    handleSectionList(NFCList, props.sidx, "nfcData");
  }, [questionList, NFCList]);

  const handleQuestionAdd = (sidx) => {
    setQuestionList([
      ...questionList,
      {
        sidx: sidx,
        qQuestion: "",
        qType: "",
        cIDs: [],
        hcs: "0",
        nfc: "0",
        motiv: "0",
        CuAr: "0",
        CuArType: "",
      },
    ]);
  };

  const handleAddFromQBank = (sidx, nxtQidx, qbSet) => {
    const parsedQBset = JSON.parse(qbSet);
    let cList = parsedQBset.choices.map((i) => {
      return {
        qidx: nxtQidx,
        sidx: sidx,
        cName: i.choice,
        weight: i.weightage,
      };
    });
    setQBChoiceList(cList);
    const addToQlist = {
      sidx: sidx,
      qQuestion: parsedQBset.question,
      qType: parsedQBset.type,
      cIDs: cList,
    };
    setQuestionList([...questionList, addToQlist]);
  };

  const handleQuestionRemove = (idx) => {
    const newQuestionList = [...questionList];
    newQuestionList.splice(idx, 1);
    setQuestionList(newQuestionList);
  };

  const handleQuestionChange = (e, index) => {
    const { name, value } = e.target;
    const newQuestionList = [...questionList];
    newQuestionList[index][name] = value;
    setQuestionList(newQuestionList);
  };

  const handleChoiceList = (array, index) => {
    const newQuestionList = [...sectionList[props.sidx].qIDs];
    newQuestionList[index]["cIDs"] = array;
    setQuestionList(newQuestionList);
  };

  const onMoveUp = (key) => {
    if (key === 0) return;
    const index = key - 1;
    const newQuestionList = [...questionList];
    const itemAbove = newQuestionList[index];
    newQuestionList[key - 1] = newQuestionList[key];
    newQuestionList[key] = itemAbove;
    setQuestionList(newQuestionList);
  };

  const onMoveDown = (key) => {
    if (key === questionList.length - 1) return;
    const index = key + 1;
    const newQuestionList = [...questionList];
    console.log(newQuestionList);
    const itemBelow = newQuestionList[index];
    newQuestionList[key + 1] = newQuestionList[key];
    newQuestionList[key] = itemBelow;
    setQuestionList(newQuestionList);
  };

  function showNFCModal() {
    setPrevNFCListState(NFCList);
    setIsOpenNFCModal(!isOpenNFCModal);
  }

  const handleNFCAdd = (sidx) => {
    setNFCList([
      ...NFCList,
      {
        sidx: sidx,
        startWeight: "",
        endWeight: "",
        eqWeight: "",
      },
    ]);
  };

  const handleNFCRemove = (idx) => {
    const newNFCList = [...NFCList];
    newNFCList.splice(idx, 1);
    setNFCList(newNFCList);
  };

  const handleNFCChange = (e, index) => {
    const { name, value } = e.target;
    const newNFCList = [...NFCList];
    newNFCList[index][name] = value;
    setNFCList(newNFCList);
  };

  function sortFunction(a, b) {
    if (a[0] === b[0]) {
      return 0;
    } else {
      return a[0] < b[0] ? -1 : 1;
    }
  }

  function validateNFCOverlap(from) {
    const newNFCList = [...NFCList];
    let valid = true;

    const isComplete = isNFCComplete(newNFCList);

    if (from === "save" && !isComplete) {
      setMessage(`Complete all the fields in the NFC range before submitting`);
      setStatus(false);
      setToast(true);
    }

    if (isComplete) {
      const [isValid, reason] = validateNFCOverlapHelper(newNFCList);
      if (!isValid) {
        valid = false;
        setMessage(reason);
        setStatus(false);
        setToast(true);
        return false;
      }
    }

    if (from === "save" && isComplete && valid) showNFCModal();
  }

  const customStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "#f0f7fc",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      maxHeight: "50%",
      width: "50%",
      transform: "translate(-50%, -50%)",
      overflow: "auto",
      backgroundColor: "white",
    },
  };

  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Item>
        <Accordion.Header>
          Section #{props.i}
          <div
            style={{
              width: "84%",
              position: "absolute",
              right: "0",
              marginRight: "3rem",
              gap: "25px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Modal isOpen={isOpenNFCModal} style={customStyles}>
              <ol>
                {NFCList.map((NFCItem, NFCIdx) => (
                  <Stack gap={3} key={NFCIdx}>
                    <li>
                      <NFCListSet
                        NFCIdx={NFCIdx}
                        sidx={props.sidx}
                        NFCItem={NFCItem}
                        handleNFCRemove={(id) => handleNFCRemove(id)}
                        handleNFCChange={(e, index) => handleNFCChange(e, index)}
                        validateNFCOverlap={() => validateNFCOverlap()}
                      />
                    </li>
                  </Stack>
                ))}
              </ol>
              <div style={{ width: "100%", paddingLeft: "30px", display: "flex" }}>
                <div>
                  <Button
                    disabled={editing}
                    className="ms-auto"
                    variant="outline-light"
                    style={{ color: "#163172", fontSize: "14px" }}
                    onClick={() => {
                      handleNFCAdd(props.sidx);
                    }}
                  >
                    ✚ Set
                  </Button>
                </div>
                <div style={{ marginLeft: "10px" }}>
                  <Button
                    disabled={editing}
                    className="ms-auto"
                    variant="outline-light"
                    style={{ color: "#163172", fontSize: "14px" }}
                    onClick={() => {
                      validateNFCOverlap("save");
                    }}
                  >
                    <RiSaveFill style={{ color: "#163172", fontSize: "20px" }} />
                    &nbsp;&nbsp; Save
                  </Button>
                </div>
                <div style={{ marginLeft: "10px" }}>
                  <Button
                    // disabled={editing}
                    className="ms-auto"
                    variant="outline-light"
                    style={{ color: "#163172", fontSize: "14px" }}
                    onClick={() => {
                      setNFCList(prevNFCListState);
                      showNFCModal();
                    }}
                  >
                    <AiOutlineCloseCircle style={{ color: "#BD4B4B", fontSize: "20px" }} />
                    &nbsp;&nbsp; Close
                  </Button>
                </div>
              </div>
            </Modal>

            {props.singleSection?.nfc && (
              <Button
                disabled={true}
                style={{ width: "fit-content" }}
                onClick={(e) => {
                  e.stopPropagation();
                  showNFCModal();
                }}
              >
                NFC Range
              </Button>
            )}

            <Form.Check
              disabled={true}
              type="checkbox"
              name="nfc"
              label={"NFC"}
              id="nfc"
              checked={props.singleSection?.nfc === "1" ? true : false}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => {
                e.target.value = e.target.checked ? 1 : 0;
                handleSectionChange(e, props.sidx);
              }}
              style={{ alignSelf: "center" }}
            />

            <Form.Select
              disabled={true}
              name="sAnalysisType"
              style={{ width: "fit-content" }}
              value={props.singleSection.sAnalysisType}
              onChange={(e) => {
                handleSectionChange(e, props.sidx);
              }}
            >
              <option value="" disabled selected>
                Analysis Type
              </option>
              <option key={"weighted_avg"} value={"weighted_avg"}>
                Weighted Avg
              </option>
              <option key={"average"} value={"average"}>
                Average
              </option>
              <option key={"sum"} value={"sum"}>
                Sum
              </option>
              <option key={"weighted_sum"} value={"weighted_sum"}>
                Weighted Sum
              </option>
            </Form.Select>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <Form.Group controlId={props.sidx}>
            <Stack gap={3}>
              <Stack direction="horizontal" gap={3}>
                <Form.Control
                  disabled={editing}
                  className="me-auto"
                  type="text"
                  placeholder="Section Name"
                  name="sName"
                  value={props.singleSection.sName}
                  onChange={(e) => {
                    handleSectionChange(e, props.sidx);
                  }}
                />
              </Stack>
              <Form.Control
                disabled={editing}
                className="me-auto"
                as="textarea"
                placeholder="Section Decription"
                name="sDesc"
                value={props.singleSection.sDesc}
                onChange={(e) => {
                  handleSectionChange(e, props.sidx);
                }}
              />
            </Stack>
            <div className="assmt-q-body">
              <br />
              <ol>
                {sectionList[props.sidx].qIDs.map((singleQuestion, qidx) => (
                  <Stack gap={3} key={qidx}>
                    <li>
                      <EditQuestionSet
                        qidx={qidx}
                        sidx={props.sidx}
                        handleQuestionRemove={(id) => handleQuestionRemove(id)}
                        singleQuestion={singleQuestion}
                        handleQuestionChange={(e, index) => handleQuestionChange(e, index)}
                        handleChoiceList={(array, index) => handleChoiceList(array, index)}
                        onMoveUp={(key) => onMoveUp(key)}
                        onMoveDown={(key) => onMoveDown(key)}
                        editing={editing}
                        textOnly={textOnly}
                        qbChoiceList={qbChoiceList}
                      />
                    </li>
                  </Stack>
                ))}
              </ol>
              <Form.Select
                disabled={editing||textOnly}
                value=""
                onChange={(e) => {
                  handleAddFromQBank(props.sidx, questionList.length, e.target.value);
                }}
              >
                <option value="" disabled selected>
                  Add from question bank
                </option>
                {questionBank?.map((q, qindx) => (
                  <option key={qindx} value={JSON.stringify(q)}>
                    {q.question}
                  </option>
                ))}
              </Form.Select>
              <br/>
              <Stack direction="horizontal" gap={3}>
                <Button
                  disabled={editing||textOnly}
                  className="sec-x-btn"
                  variant="outline-light"
                  style={{ color: "#163172" }}
                  onClick={() => handleSectionRemove(props.sidx)}
                >
                  <RiDeleteBin2Fill style={{ color: "#BD4B4B", fontSize: "20px" }} />
                </Button>
                <Button
                  disabled={editing||textOnly}
                  className="ms-auto"
                  variant="outline-light"
                  style={{ color: "#163172", fontSize: "14px" }}
                  onClick={() => {
                    handleQuestionAdd(props.sidx);
                  }}
                >
                  ✚ Question
                </Button>
                <div className="vr" />

                {editing ? (
                  <Button className="sec-x-btn" variant="outline-light" onClick={toggleEdit} enabled>
                    <FaEdit style={{ color: "#163172", fontSize: "20px" }} />
                  </Button>
                ) : (
                  <Button
                    className="sec-x-btn"
                    variant="outline-light"
                    onClick={() => {
                      let flag = true;
                      if (sectionList[props.sidx]) {
                        if (sectionList[props.sidx].sName === "" || sectionList[props.sidx].sDesc === "") {
                          flag = false;
                          setMessage(`Please input all section details in section ${props.i}`);
                          setStatus(false);
                          setToast(true);
                        }
                        if (sectionList[props.sidx].qIDs.length) {
                          sectionList[props.sidx].qIDs.forEach((qObj, qidx) => {
                            if (qObj.qQuestion === "" || qObj.qType === "") {
                              flag = false;
                              setMessage(`Question details missing in section ${props.i}, question ${qidx + 1}`);
                              setStatus(false);
                              setToast(true);
                            }
                            if (qObj.cIDs.length) {
                              qObj.cIDs.forEach((cObj, cidx) => {
                                if (cObj.cName === "" || cObj.weight === "") {
                                  flag = false;
                                  setMessage(
                                    `Choice details missing in section ${props.i}, question ${qidx + 1}, choice ${
                                      cidx + 1
                                    }`
                                  );
                                  setStatus(false);
                                  setToast(true);
                                }
                              });
                            }
                          });
                        }
                      }
                      if (flag === true) {
                        toggleEdit();
                      }
                    }}
                    enabled
                  >
                    <RiSaveFill style={{ color: "#163172", fontSize: "20px" }} />
                  </Button>
                )}
              </Stack>
            </div>
          </Form.Group>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default EditSectionSet;
