import React, { useContext, useState } from "react";
import Navigate from "../../Navigate";
import ToastMessage from "../../ToastMessage";
import { useNavigate } from "react-router-dom";
import { AssmtContext } from "../../Contexts/AssmtContext";
import SectionSet from "./SectionSet";
import { Form, Stack, Button } from "react-bootstrap";
import { SiAddthis } from "react-icons/si";
import { GiDna1 } from "react-icons/gi";
import AccessDenied from "../../AccessDenied";
import { AccountContext } from "../../Contexts/AccountContext";
import { Helmet } from "react-helmet";
import Modal from "react-modal/lib/components/Modal";
import ModalListSet from "./ModalListSet";

import { RiSaveFill } from "react-icons/ri";
import { AiOutlineCloseCircle } from "react-icons/ai";

Modal.setAppElement("#root");

const NewAssmt = () => {
  const { loginStatus, loggedInUser, apiCall } = useContext(AccountContext);
  const {
    assmtObj,
    setAssmtObj,
    sectionList,
    handleSectionAdd,
    createAssmt,
    toast,
    setToast,
    message,
    setMessage,
    status,
    setStatus,
    setAssmt,
    validateInput,
  } = useContext(AssmtContext);

  let navigate = useNavigate();

  return (
    <div id="newAssmtMainDiv">
      {loginStatus === true && loggedInUser.group === "Admin" ? (
        <>
      <Helmet>
        <title>Helix Employability Tool | New Assessment</title>
      </Helmet>
          <Navigate />
          <h6>
            <a href="#" onClick={() => navigate("/admin")}>
              &lt;Back
            </a>
          </h6>
          <ToastMessage toast={toast} onClose={() => setToast(!toast)} message={message} status={status} />

          <Stack direction="horizontal" gap={3}>
            <label>Create Your Assessment</label>
          </Stack>
          <br />

          <div className="newAssmt">
            <div className="w-25">
              <Form>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Assessment Name"
                    onChange={(e) => {
                      setAssmtObj((prevValue) => {
                        return {
                          ...prevValue,
                          aName: e.target.value,
                        };
                      });
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    as="textarea"
                    placeholder="Assessment Description"
                    rows={5}
                    onChange={(e) => {
                      setAssmtObj((prevValue) => {
                        return {
                          ...prevValue,
                          aDesc: e.target.value,
                        };
                      });
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    as="textarea"
                    placeholder="Assessment URLs"
                    onChange={(e) => {
                      setAssmtObj((prevValue) => {
                        return {
                          ...prevValue,
                          aUrl: e.target.value,
                        };
                      });
                    }}
                  />
                </Form.Group>
                <Form.Group>
                  <Button
                    variant="light"
                    style={{ color: "#163172" }}
                    disabled={assmtObj.aName.trim() === ""}
                    className="createAssmt-btn"
                    onClick={async () => {
                      if (!assmtObj.aid || !assmtObj.aName || !assmtObj.aDesc) {
                        setMessage("Assessment details missing!");
                        setStatus(false);
                        setToast(true);
                        return;
                      }
                      const isValid = validateInput(assmtObj.aUrl);
                      if (!isValid) {
                        setMessage("Invalid URL!");
                        setStatus(false);
                        setToast(true);
                        return;
                      }
                      let flag = false;
                      sectionList.forEach((sec) => {
                        if (sec.sName === "" || sec.sDesc === "") {
                          flag = true;
                        }
                      });
                      if (flag === true) {
                        setMessage("Section details missing!");
                        setStatus(false);
                        setToast(true);
                        return;
                      }
                      // if (!editing) {
                      //   setMessage("Save all sections!");
                      //   setStatus(false);
                      //   setToast(true);
                      //   return;
                      // }
                      const result = await createAssmt(assmtObj, sectionList);
                      if (result) {
                        const { data } = await apiCall("admin/");
                        setAssmt(data);
                        setMessage(`New Assessment ${assmtObj.aName} created successfully!`);
                        setStatus(true);
                        setToast(true);
                        navigate(`/admin/editAssmt/${assmtObj.aid}`, {
                          replace: false,
                          state: { textOnly: false }
                        });
                      } else if (result === false) {
                        setMessage("Assessment could not be created!");
                        setStatus(false);
                        setToast(true);
                      }
                    }}
                  >
                    <GiDna1
                      style={{
                        color: "#163172",
                        fontSize: "20px",
                      }}
                    />
                    Create
                  </Button>
                </Form.Group>
              </Form>
              <br />
              <br />
            </div>
            <div className="assmt-b">
              {sectionList.map((singleSection, sidx) => (
                <div key={sidx}>
                  <SectionSet i={sidx + 1} sidx={sidx} singleSection={singleSection} />
                  <br />
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default NewAssmt;
