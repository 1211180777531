import React, { useContext, useEffect, useRef} from "react";
import {
  Form,
  Stack,
  Accordion,
  Card,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { UserContext } from "../../Contexts/UserContext";
import Inventory from "../QuestionType/Inventory";
import Likert from "../QuestionType/Likert";
import Likert6 from "../QuestionType/Likert6";
import Multiple from "../QuestionType/Multiple";
import PickOne from "../QuestionType/PickOne";
import Slider from "../QuestionType/Slider";

const UserResponseQuestion = ({
  isMobile,
  isLandscapeMobile,
  allQuestion,
  step,
}) => {
  const questionRef = useRef(null);

  const { incompleteQuestions } = useContext(UserContext);

  useEffect(() => {
    if (questionRef.current) {
      questionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [allQuestion]);


  useEffect(()=>{
	console.log("user response question re-redered...");
})



  const renderTooltip = (tooltip) => <Tooltip>{tooltip}</Tooltip>;

	return (<>
			{isMobile ? (
				<Accordion>
				 {allQuestion.map((q, qqid) => {
				   return(
				   <Card key={qqid}>
					 <Accordion.Item eventKey={(step*100)+qqid}>
					  { incompleteQuestions.includes(q.QID) ? (
					   <Accordion.Header style={{ "border": "ridge red" }}><span className="truncate-text">{q.question}</span></Accordion.Header>
					  ):(
					   <Accordion.Header><span className="truncate-text">{q.question}</span></Accordion.Header>
					  )}
					   <Accordion.Body>
						 <Form.Label style={{marginBottom: '1rem'}}>{q.question}</Form.Label>
               {/* Tooltip icon using Font Awesome */}
               <OverlayTrigger
                    placement="right"
                    overlay={renderTooltip(q.tooltip)}
                  >
                    <i
                      className="fas fa-info-circle"
                      style={{
                        fontSize: "0.85em", // Adjusts the size of the icon
                        marginLeft: "8px", // Adds horizontal gap from the text
                        cursor: "pointer",
                        color: "#31708f",
                      }}
                    ></i>
                  </OverlayTrigger>
						 <div>
						   {q.type === "Multiple" ? (
							 <Multiple q={q} qqid={qqid} allQuestion={allQuestion} isMobile={isMobile} isLandscapeMobile={isLandscapeMobile} />
						   ) : q.type === "Inventory" ? (
							 <Inventory q={q} qqid={qqid} allQuestion={allQuestion} isMobile={isMobile} isLandscapeMobile={isLandscapeMobile} />
						   ) : q.type === "Likert" ? (
							 <Likert q={q} qqid={qqid} allQuestion={allQuestion} isMobile={isMobile} isLandscapeMobile={isLandscapeMobile} />
						   ) : q.type === "Likert-6" ? (
							 <Likert6 q={q} qqid={qqid} allQuestion={allQuestion} isMobile={isMobile} isLandscapeMobile={isLandscapeMobile} />
						   ) : q.type === "Pick One" ? (
							 <PickOne q={q} qqid={qqid} allQuestion={allQuestion} isMobile={isMobile} isLandscapeMobile={isLandscapeMobile} />
						   ) : q.type === "Slider" ? (
                <Slider q={q} qqid={qqid} allQuestion={allQuestion} isMobile={isMobile} isLandscapeMobile={isLandscapeMobile} />
                ) : (
							 <></>
						   )}
						 </div>
					   </Accordion.Body>
					 </Accordion.Item>
				   </Card>
				   )
})}
			    </Accordion>
			):(
				<Stack className="question" >
					<ol ref={questionRef}>
						{allQuestion.map((q, qqid) => (
							<li key={qqid}>
								<div className="question-body" key={q.QID}>
									<Form.Label>{q.question}</Form.Label>
                   {/* Tooltip icon using Font Awesome */}
                   {q.tooltip && q.tooltip !== "" && (
                    <OverlayTrigger
                      placement="right"
                      overlay={renderTooltip(q.tooltip)}
                    >
                      <i
                        className="fas fa-info-circle"
                        style={{
                          fontSize: "1em", // Adjusts the size of the icon
                          marginLeft: "8px", // Adds horizontal gap from the text
                          cursor: "pointer",
                          color: "#31708f",
                        }}
                      ></i>
                    </OverlayTrigger>
                  )}
									<div>
										{q.type === "Multiple" ? (
											<Multiple q={q} qqid={qqid} allQuestion={allQuestion} />
										) : q.type === "Inventory" ? (
											<Inventory q={q} qqid={qqid} allQuestion={allQuestion} />
										) : q.type === "Likert" ? (
											<Likert q={q} qqid={qqid} allQuestion={allQuestion} />
										) : q.type === "Likert-6" ? (
											<Likert6 q={q} qqid={qqid} allQuestion={allQuestion} />
										) : q.type === "Pick One" ? (
											<PickOne q={q} qqid={qqid} allQuestion={allQuestion} />
										) :  q.type === "Slider" ? (
											<Slider q={q} qqid={qqid} allQuestion={allQuestion} isMobile={isMobile} isLandscapeMobile={isLandscapeMobile} />
										  ) :(
											<></>
										)}
									</div>
								</div>
								<br />
							</li>
						))}
					</ol>
				</Stack>
			)}
		</>
	);
};

export default React.memo(UserResponseQuestion);
