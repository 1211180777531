import React, { useContext, useEffect, useState, useRef } from "react";
import { Form, Stack, Table, Button } from "react-bootstrap";
import { UserContext } from "../../Contexts/UserContext";

const Multiple = (props) => {
	let q = props.q;
	let qqid = props.qqid;
	let allQuestion = props.allQuestion;
	let isMobile = props.isMobile;
	let isLandscapeMobile = props.isLandscapeMobile;
	const { 
		userResponseList, 
		setUserResponseList,
		userHCSList,
		setUserHCSList,
		allSecObj,
	} = useContext(UserContext);

	useEffect(() => {
		// if(q.choices){
		// 	console.log(q.choices,"q.choices");
		// 	console.log(userResponseList,"userResponseList");
		// 	const choiceIds = q.choices.map(obj => obj.CID);
		// 	const filteredUserResponseList = userResponseList.filter(obj => choiceIds.includes(obj.CID));
		// 	setUserResponseList([...filteredUserResponseList, ...q.choices.map(({CID, QID, SecID}) => ({
		// 		response: '0',
		// 		CID,
		// 		QID,
		// 		SecID
		// 	}))])
		// }
		// if(q.choices){
		// 	const newResponses = q.choices.map(({CID, QID, SecID}) => ({
		// 	  response: '0',
		// 	  CID,
		// 	  QID,
		// 	  SecID
		// 	}));
		// 	setUserResponseList(prev => {
		// 	  // Remove old responses for the current question
		// 	  const filteredPrev = prev.filter(obj => obj.QID !== q.QID);
		// 	  // Add new responses for the current question
		// 	  return [...filteredPrev, ...newResponses];
		// 	});
		//   }

		if(allQuestion[qqid].choices){
			const newResponses = allQuestion[qqid].choices.map(({CID, QID, SecID}) => {
			  const existingResponse = userResponseList.find(obj => obj.CID === CID);
			  return {
				type: allQuestion[qqid].type,
				response: existingResponse ? existingResponse.response : '0',
				CID,
				QID,
				SecID
			  }
			});
		
			setUserResponseList(prev => {
			  // Remove old responses for the current question
			  const filteredPrev = prev.filter(obj => obj.QID !== allQuestion[qqid].QID);
			  // Add new responses for the current question
			  return [...filteredPrev, ...newResponses];
			});
		}
	}, [allQuestion[qqid].choices]);

	return (
		<div className="mult-opt">
			<ol type="a">
				{allQuestion[qqid].choices.map((c, ccid) => (
					<li key={ccid} className="single-mult-opt">
						{isMobile ? (
							<Button
								variant={userResponseList.find((i) => i.CID === c.CID)?.response === '1' ? 'primary' : 'outline-primary'}
								onClick={() => {
									c["checked"] = c.checked === 0 ? 1 : 0;
									const checked = c.checked === 1;
									setUserResponseList((prev) => {
										return prev.map(obj => {
										if (obj.CID !== c.CID) return obj;
										return {
											...obj,
											response: checked ? '1' : '0'
										}
										});
									});
									if (checked) {
										if (allQuestion[qqid].hcs === "1") {
											let newUserHCSList = [...userHCSList];
											let resObj = {
												QID: allQuestion[qqid].QID,
												CID: c.CID,
												weight: 1 * c.weightage,
											}
											newUserHCSList.push(resObj);
											setUserHCSList(newUserHCSList);
										}
									} else {
										let list = [...userHCSList];
										let newUserHCSList = list.filter(
											(i) => i.CID !== c.CID
										)
										setUserHCSList(newUserHCSList);
									}
								}}
								style={{width: "-webkit-fill-available"}}
							>
								{c.choice}
							</Button>
						) : (
							<Form.Check
								type="checkbox"
								id={c.CID}
								checked={userResponseList.find(
								(i) => i.CID === c.CID
								)?.response === '1'}
								name={c.choice}
								value={1}
								label={c.choice}
								onChange={(e) => {
								c["checked"] = c.checked === 0 ? 1 : 0;
								const { value, checked, id } = e.target;
								setUserResponseList((prev) => {
									return prev.map(obj => {
									if (obj.CID !== id) return obj;
									return {
										...obj,
										response: checked ? '1' : '0'
									}
									});
								});
								if (checked) {
									if (allQuestion[qqid].hcs === "1") {
									let newUserHCSList = [...userHCSList];
									let resObj = {
										QID: allQuestion[qqid].QID,
										CID: id,
										weight: value * c.weightage,
									}
									newUserHCSList.push(resObj);
									setUserHCSList(newUserHCSList);
									}
								} else {
									let list = [...userHCSList];
									let newUserHCSList = list.filter(
									(i) => i.CID !== id
									)
									setUserHCSList(newUserHCSList);
								}
								}}
							/>
						)}
					</li>
				))}
			</ol>
		</div>
	);
};

export default Multiple;
