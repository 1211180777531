import React, { useContext, useEffect, useState } from "react";
import { useNavigate,useSearchParams } from "react-router-dom";
import { AssmtContext } from "./Contexts/AssmtContext";
import ToastMessage from "./ToastMessage";
import {
  Navbar,
  Nav,
  Container,
  Stack,
  Button,
  NavDropdown,
} from "react-bootstrap";
import logo from "../image/Helix-logo.png";
import { useMediaQuery } from "react-responsive";
import menu from "../image/menu.svg";
import { Helmet } from "react-helmet";
import axios from "axios";


const VerifyEmail = () => {

  const { toast, setToast, message, setMessage, status, setStatus } =
    useContext(AssmtContext);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isLandscapeMobile = useMediaQuery({
    query: "(orientation: landscape) and (max-height: 431px",
  });

  const [searchParams] = useSearchParams();
  const clientID = searchParams.get("clientID");
  const confirmationCode = searchParams.get("confirmationCode");
  const userName = searchParams.get("userName");


  const VerifyEmailAddress = async () => {
    try {
      console.log(clientID);
      console.log(confirmationCode);
      console.log(userName);
    //   setLoad(true);
      const resp = await axios.get(`http://${process.env.REACT_APP_SERVER_IP}:${process.env.REACT_APP_SERVER_PORT}/user/verifyEmail?clientID=${clientID}&confirmationCode=${confirmationCode}&userName=${userName}`);
      console.log("RESPONSE : ", resp);  
      if(resp?.data?.error === false){
        setMessage(resp?.data?.message);
        setStatus(true);
        setToast(true);
        // setLoad(false);
        navigate("/login")
      }else{
        throw resp?.data;
      }
      return;

    } catch (err) {
      console.log("Error in verifying group code: ", err);
      setMessage("Something went wrong! Please try again");
      setStatus(false);
      setToast(true);
    //   setLoad(false);
      navigate("/register");
      return;
    }
  };

  useEffect(()=> {
    VerifyEmailAddress();
  },[]);

  return (
    <div>
      <Helmet>
        <title>Helix Employability Tool | Verify Email</title>
      </Helmet>
      <Navbar fixed="top" className="navbar">
        <Container>
          <Navbar.Brand href="/">
            <img
              alt=""
              src={logo}
              width="auto"
              height="40"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          {isMobile ? (
            <NavDropdown
              title={<img src={menu} alt="Menu" />}
              id="basic-nav-dropdown"
              align="end"
              style={{ marginRight: "-5px" }}
              className="custom-dropdown"
            >
              <NavDropdown.Item href="/">Home</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/about-us">About Us</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/contact-us">Contact Us</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/login">Sign in</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/register">Sign up</NavDropdown.Item>
            </NavDropdown>
          ) : isLandscapeMobile ? (
            <NavDropdown
              title={<img src={menu} alt="Menu" />}
              id="basic-nav-dropdown"
              align="end"
              style={{ marginRight: "-5px" }}
              className="custom-dropdown"
            >
              <NavDropdown.Item href="/">Home</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/about-us">About Us</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/contact-us">Contact Us</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/login">Sign in</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/register">Sign up</NavDropdown.Item>
            </NavDropdown>
          ) : (
            <Nav className="nav-right-gap">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/about-us">About Us</Nav.Link>
              <Nav.Link href="/contact-us">Contact Us</Nav.Link>
              <Nav.Link href="/login">
                <Button>Sign in</Button>
              </Nav.Link>
              <Nav.Link href="/register">
                <Button className="custom-button">Sign up</Button>
              </Nav.Link>
            </Nav>
          )}
        </Container>
      </Navbar>
      <ToastMessage
        toast={toast}
        onClose={() => setToast(!toast)}
        message={message}
        status={status}
      />
      <br />
      <div className="register-div">
        <Stack gap={3}>
          <h5 className="text-muted text-center">
           Loading...
          </h5>
          <h4 className="text-muted text-center">
           Please wait a moment till we verify your email address...
          </h4>
        </Stack>
      </div>
    </div>
  );
};

export default VerifyEmail;
