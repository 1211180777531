import React, { useContext, useEffect, useState } from "react";
import { Form, Button, Stack, Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Navigate from "../../Navigate";
import ToastMessage from "../../ToastMessage";
import { AssmtContext } from "../../Contexts/AssmtContext";
import { AccountContext } from "../../Contexts/AccountContext";
import AccessDenied from "../../AccessDenied";
import { Helmet } from "react-helmet";

const ViewGrpUserResult = () => {
    const { loginStatus, loggedInUser, apiCall } = useContext(AccountContext);

	const { toast, setToast, message, setMessage, status, setStatus } = useContext(AssmtContext);

    const ids = useParams();
    const AGID = ids.AGID;
    const UAID = ids.UAID;
    const UID = ids.UID;

    const navigate = useNavigate();

    const [grpAssmtList, setGrpAssmtList] = useState([]);
    const [grpAssmtDetails, setGrpAssmtDetails] = useState([]);

    useEffect(() => {
        const getAssmtDetails = async () => {
            const { data } = await apiCall(`admin/getGrpAssmtByID/${AGID}`);
            setGrpAssmtList(data);
            const res = await apiCall(`admin/getAssmtDetailsByID/${AGID}`);
            setGrpAssmtDetails(res["data"][0]);
        };
        if(grpAssmtList.length === 0) getAssmtDetails();
    }, [grpAssmtList])

    return(
        <div>
            {loginStatus === true && loggedInUser.group === "User" ? (
				<>
                <Helmet>
                    <title>Helix Employability Tool | View Group Assessment Result</title>
                </Helmet>
                <Navigate />
                <h6><a href="#" onClick={() => window.history.back()}>&lt;Back</a></h6>
					<ToastMessage
                    toast={toast}
                    onClose={() => setToast(!toast)}
                    message={message}
                    status={status}
					/>
					<Stack direction="horizontal" gap={3}>
						<label>View assessment response</label>
					</Stack>
					<br />

					<div className="newAssmt">
						<div className="w-25">
							<Form>
								<Form.Group className="mb-3">
									<Form.Control
                                    as={"textarea"}
                                    disabled={true}
                                    value={grpAssmtDetails["name"]}
									/>
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Control
                                    as="textarea"
                                    disabled={true}
                                    value={grpAssmtDetails["description"]}
                                    rows={5}
									/>
								</Form.Group>
							</Form>
							<br />
							<br />
						</div>
						<div className="assmt-b">
							{grpAssmtList.map((singleAssmt, aIdx) => (
								<div key={aIdx}>
									<Card key={singleAssmt.AID}>
                                        <Card.Body>
                                            <Stack
                                            className="assmt-card-list"
                                            direction="horizontal"
                                            >
                                                <div className="assmt-details">
                                                    <Card.Text>{singleAssmt.name}</Card.Text>
                                                </div>
                                                <div className="assmt-options">
                                                    <Button
                                                    variant="outline-secondary"
                                                    onClick={() => {
                                                        navigate(`/user/viewUserResult/${singleAssmt.AID}/${UID}/${UAID}`,{state: {AGID: AGID}});                                   
                                                    }}
                                                    >
                                                        View
                                                    </Button>
                                                </div>
                                            </Stack>
                                        </Card.Body>
                                    </Card>
									<br />
								</div>
							))}
						</div>
					</div>
                </>
			) : (
				<AccessDenied />
			)}
        </div>
    );
};

export default ViewGrpUserResult;
