import React, { useEffect, useRef, useState, useContext } from "react";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getBaseUrl } from "../../../base";
import { AssmtContext } from "../../Contexts/AssmtContext";

const UserListTable = (props) => {
  const { assmt, toast, setToast, message, setMessage, status, setStatus } =
    useContext(AssmtContext);
  $.DataTable = require("datatables.net");
  const tableRef = useRef();
  const navigate = useNavigate();

  const { exportUserResForAllAssmtToCSV } = useContext(AssmtContext);

  const setAllUserRes = props.setAllUserRes;
  const handleShow1 = props.handleShow1;
  const setAllresCSVFilename = props.setAllresCSVFilename;
  const setHeader = props.setHeader;
  const setBody = props.setBody;
  const handleShow4 = props.handleShow4;
  const setUserToDisable = props.setUserToDisable;

  useEffect(() => {
    const table = $(tableRef.current).DataTable({
      data: props.userList,
      columns: [
        {
          className: "dt-body-left",
          title: "First Name",
          data: "first_name",
        },
        { className: "dt-body-left", title: "Last Name", data: "last_name" },
        {
          className: "dt-body-left",
          title: "Email ID",
          data: "email_ID",
        },
        {
          className: "dt-body-left",
          width: '125px',
          title: "Groups",
          data: "GroupNames",
          render: function(data){
            //dont render All Users group name because it is already standard
            //render other groups if the user is present in
            let groups = data.split(',').filter((grpName)=> {return grpName !== "All Users"});
            if(groups.length === 0)
              return "----"
            let selectHTML = `<select class="grp-dropdown">
            ${groups.map((grp) => {
              return `<option class="grp-opt" value="${grp}">${grp.length >= 10 ? grp.slice(0,10)+'...' : grp }</option>`
            })}
            </select>`
            return selectHTML;
          }
        },
        {
          className: "dt-body-left",
          title: "Email Verified",
          data: "email_verified",
          render: function (data) {
            if (data === 0) {
              return "False";
            } else {
              return "True";
            }
          },
        },
        {
          className: "dt-body-left",
          title: "Consent",
          data: "consent",
          render: function (data) {
            if (data === 0) {
              return "No";
            } else {
              return "Yes";
            }
          },
        },
        {
          className: "dt-body-left",
          title: "Action",
          defaultContent: `<Button class="view-btn btn btn-outline-secondary btn-sm">
                View
              </Button>`,
        },
        {
          className: "dt-body-left",
          title: "All Response",
          defaultContent: `<Button class="all-csvbtn btn btn-outline-secondary btn-sm">
                CSV
              </Button>`,
        },
        {
          className: "dt-body-left",
          title: "Set Disabled",
          defaultContent: `<Button class="disable-btn btn btn-outline-danger btn-sm">
                  Disable
                </Button>`,
        },
      ],
      destroy: true,
    });
    $("#example2 tbody").on("click", ".view-btn", function () {
      const data = table.row($(this).parents("tr")).data();
      if (data !== undefined) {
        navigate(`/admin/userManagement/userResponse`, {
          state: {
            userObj: {
              first_name: data.first_name,
              last_name: data.last_name,
              email_ID: data.email_ID,
              UID: data.UID,
            },
          },
        });
        // });
      }
    });
    $("#example2 tbody").on("click", ".all-csvbtn", async function () {
      const data = table.row($(this).parents("tr")).data();
      if (data !== undefined) {
        let exportUserResAllData = await exportUserResForAllAssmtToCSV(
          data.UID
        );
        if (exportUserResAllData.length !== 0) {
          setAllUserRes(exportUserResAllData);
          setHeader(`${data.first_name} ${data.last_name}`);
          setBody(
            `${data.first_name} ${data.last_name}'s responses for all assessments is generated as .csv file and ready to download!`
          );
          setAllresCSVFilename(
            `${
              data.UID
            } responses for all assmt (exported on ${new Date().toLocaleDateString()})`
          );
          handleShow1();
        } else {
          setMessage("No response found!");
          setStatus(false);
          setToast(true);
        }
      }
    });
    $("#example2 tbody").on("click", ".disable-btn", async function () {
      const data = table.row($(this).parents("tr")).data();
      if (data !== undefined) {
        setUserToDisable(data);
        handleShow4();
        return;
      }
    });
    return function () {
      table.destroy();
    };
  }, [props.userList]);

  return (
    <div className="user-table">
      <table
        className="hover dt[-head|-body]-left"
        width="100%"
        id="example2"
        ref={tableRef}
      >
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email ID</th>
            <th>Groups</th>
            <th>Email Verified</th>
            <th>Consent</th>
            <th>Action</th>
            <th>All Response</th>
            <th>Set Disabled</th>
          </tr>
        </thead>
      </table>
    </div>
  );
};;

export default UserListTable;
