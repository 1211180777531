import React, { useEffect, useState } from "react";
import { Form, Table, Badge } from "react-bootstrap";
import axios from "axios";
import { getBaseUrl } from "../../../base";

const ViewInventory = (props) => {
  let q = props.q;
  let qQID = q.QID;
  let UAID = props.UAID;
  let allResponse = props.allResponse;
  // const [score, setScore] = useState(0);

  // useEffect(() => {
  //   if (!qQID || !UAID) return;
  //   const getQuestion = async (UAID, qQID) => {
  //     const { data } = await axios.get(
  //       `${getBaseUrl()}/admin/getResponseByQID/${UAID}/${qQID}`
  //     );
  //     let sum = 0;
  //     if (data.length) {
  //       data.forEach((fw) => {
  //         sum += fw.finalWeightage;
  //       });
  //     }
  //     setScore(sum);
  //   };
  //   getQuestion(UAID, qQID);
  // });

  return (
    <>
      <Table borderless>
        <tbody>
          <tr>
            <td></td>
            <td>"A Novice"</td>
            <td>"A Practitioner"</td>
            <td>"An Expert"</td>
            <td>"A Leader"</td>
          </tr>
          {q.choices.map((c, ccid) => (
            <tr key={ccid}>
              <td>{c.choice}</td>
              <td>
                <Form.Check
                  value="1"
                  type="radio"
                  name={c.CID}
                  id={c.CID}
                  disabled
                  checked={allResponse.find(
                    (i) =>
                      i.response === 1 &&
                      i.CID === c.CID &&
                      i.QID === c.QID &&
                      i.SecID === c.SecID &&
                      i.AID === c.AID &&
                      i.UAID === UAID
                  )}
                />
              </td>
              <td>
                <Form.Check
                  value="2"
                  type="radio"
                  name={c.CID}
                  id={c.CID}
                  disabled
                  checked={allResponse.find(
                    (i) =>
                      i.response === 2 &&
                      i.CID === c.CID &&
                      i.QID === c.QID &&
                      i.SecID === c.SecID &&
                      i.AID === c.AID &&
                      i.UAID === UAID
                  )}
                />
              </td>
              <td>
                <Form.Check
                  value="3"
                  type="radio"
                  name={c.CID}
                  id={c.CID}
                  disabled
                  checked={allResponse.find(
                    (i) =>
                      i.response === 3 &&
                      i.CID === c.CID &&
                      i.QID === c.QID &&
                      i.SecID === c.SecID &&
                      i.AID === c.AID &&
                      i.UAID === UAID
                  )}
                />
              </td>
              <td>
                <Form.Check
                  value="4"
                  type="radio"
                  name={c.CID}
                  id={c.CID}
                  disabled
                  checked={allResponse.find(
                    (i) =>
                      i.response === 4 &&
                      i.CID === c.CID &&
                      i.QID === c.QID &&
                      i.SecID === c.SecID &&
                      i.AID === c.AID &&
                      i.UAID === UAID
                  )}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* <Badge pill bg="primary">
        Score: {score}
      </Badge> */}
    </>
  );
};

export default ViewInventory;
