import React, { useEffect, useRef, useContext, useState } from "react";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../Contexts/UserContext";
import { AccountContext } from "../../Contexts/AccountContext";
import { useMediaQuery } from "react-responsive";
import { Collapse } from "react-bootstrap";

const IncompleteResultTable = (props) => {
  $.DataTable = require("datatables.net");
  const tableRef = useRef();
  const navigate = useNavigate();
  const { loginStatus, loggedInUser, apiCall } = useContext(AccountContext);
  const { setAssmtID, setUserAssmtID, setStep, setFetchIndividualAssmt, setGrpAssmtID, setAssmtLeft, setAssmtType } = useContext(UserContext);
  const setAssmtToDelete = props.setAssmtToDelete;
  const setUAIDtoDelete = props.setUAIDtoDelete;
  const handleShow2 = props.handleShow2;
  const isMobile = useMediaQuery({ maxWidth: 737 });
  const isLandscapeMobile = useMediaQuery({ query: '(orientation: landscape) and (max-height: 431px' });
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    const table = $(tableRef.current).DataTable({
      data: props.incompleteList,
      columns: [
        {
          className: "dt-body-left",
          width: "20%",
          title: "Assessment",
          data: "name",
        },
        {
          className: "dt-body-left",
          title: "Title",
          data: "positionTitle",
        },
        {
          className: "dt-body-left",
          title: "Position",
          data: "positionOption",
        },
        {
          className: "dt-body-left",
          width: "15%",
          title: "Reference Date",
          data: "referenceDate",
        },
        {
          className: "dt-body-left",
          title: "Start Time",
          data: "start_Tstamp",
        },
        {
          className: "dt[-head|-body]-center",
          title: "Action",
          defaultContent: `<Button class="resume-btn btn btn-outline-secondary btn-sm">
                    Resume
                </Button>
                <Button class="del-btn btn btn-outline-danger btn-sm">
                    Delete
                </Button>`,
        },
      ],
      destroy: true,
      responsive: !isMobile,
      createdRow: function (row, data) {
        if (isMobile) {
          $(row).html(`
            <tr>
              <td><span class="cell-header">Assessment:</span> ${data.name}</td>
              <td><span class="cell-header">Title:</span> ${data.positionTitle}</td>
              <td><span class="cell-header">Position:</span> ${data.positionOption}</td>
              <td><span class="cell-header">Reference Date:</span> ${data.referenceDate}</td>
              <td><span class="cell-header">Start Time:</span> ${data.start_Tstamp}</td>
              <td>
                <div class="action-buttons">
                  <Button class="resume-btn btn btn-outline-secondary btn-sm" style=" margin-right: 20%; width: 40%;">Resume</Button>
                  <Button class="del-btn btn btn-outline-danger btn-sm" style=" width: 40%;">Delete</Button>
                </div>
              </td>
            </tr>
          `);
        }
      },
    });
    $("#example1 tbody").on("click", ".resume-btn", function () {
      const handleClick = async () => {
        const data = table.row($(this).parents("tr")).data();
        if (data !== undefined) {
          setAssmtID(data.AID);
          if (data.type === "individual"){ 
            setFetchIndividualAssmt(true);
            navigate(`/user/userResponse/${loggedInUser.UID}`);
            setUserAssmtID(data.UAID);
            data.lastSecSubmitted === null ? setStep(0) : setStep(data.lastSecSubmitted + 1);
          }
          else{
            const lefty = await apiCall(`user/getGrpAssmtLeft/${data.UAID}`);
            console.log(lefty);
            setGrpAssmtID(data.AID);
            setUserAssmtID(data.UAID);
            setAssmtType("group");
            navigate(`/user/userResponse/grpAssmt/${loggedInUser.UID}`);
          } 
        }
      }
      handleClick();
    });
    $("#example1 tbody").on("click", ".del-btn", function () {
      const data = table.row($(this).parents("tr")).data();
      if (data !== undefined) {
        setAssmtToDelete(data.name);
        setUAIDtoDelete(data.UAID);
        handleShow2();
      }
    });
    return function () {
      table.destroy();
    };
  }, [props.incompleteList, isMobile]);

  return (
    <div className="user-table">
      <table className="hover dt[-head|-body]-left" width="100%" id="example1" ref={tableRef}>
        <thead>
          <tr>
            <th>Assessment</th>
            <th>Title</th>
            <th>Position</th>
            <th>Reference Date</th>
            <th>Start Time</th>
            <th>Action</th>
          </tr>
        </thead>
      </table>
    </div>
  );
};

export default IncompleteResultTable;
