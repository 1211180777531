import React, { useContext, useEffect, useState } from "react";
import { Form, Stack, Table, Button } from "react-bootstrap";
import { UserContext } from "../../Contexts/UserContext";

const PickOne = (props) => {
	let q = props.q;
	let qqid = props.qqid;
	let allQuestion = props.allQuestion
	let isMobile = props.isMobile;
	let isLandscapeMobile = props.isLandscapeMobile;
	const { 
		userResponseList, 
		setUserResponseList,
		userHCSList,
		setUserHCSList
	} = useContext(UserContext);
	
	const [pickoneObj, setPickOneObj] = useState({});

	return (
		<div className="mult-opt">
			<ol type="a">
				<Form>
					{allQuestion[qqid].choices.map((c, ccid) => (
						<li key={ccid} className="single-mult-opt">
							{isMobile ? (
								<Button
									variant={
										//pickoneObj === c.CID
										userResponseList.findIndex(i => i.CID === c.CID)!==-1 ? 'primary' : 'outline-primary'
									}
									onClick={() => {
										allQuestion[qqid]["checked"] = 1;
										setPickOneObj(c.CID);
										let resObj = {
										response: ccid + 1,
										type: allQuestion[qqid].type,
										CID: c.CID,
										QID: c.QID,
										SecID: c.SecID,
										};
										setUserResponseList(userResponseList.filter(i => i.QID !== c.QID));
										if (allQuestion[qqid].hcs === "1") {
										let list = [...userHCSList];
										let newUserHCSList = list.filter(i => i.QID !== allQuestion[qqid].QID);
										let resObj = {
											QID: allQuestion[qqid].QID,
											CID: c.CID,
											weight: (ccid + 1) * c.weightage,
										};
										newUserHCSList.push(resObj);
										setUserHCSList(newUserHCSList);
										}
										setUserResponseList(prev => [...prev, resObj]);
									}}
									style={{width: "-webkit-fill-available"}}
								>
								{c.choice}
								</Button>
							) : (
							<Form.Check
								type="radio"
								id={c.CID}
								checked={userResponseList.findIndex(i => i.CID === c.CID)!==-1}
								name={c.choice}
								value={ccid+1}
								label={c.choice}
								onChange={(e) => {
									allQuestion[qqid]["checked"] = 1;
									setPickOneObj(c.CID);
									const { value, checked, id } = e.target;
									let resObj = {
										response: value,
										type: allQuestion[qqid].type,
										// finalWeightage: value,
										CID: c.CID,
										QID: c.QID,
										SecID: c.SecID,
									};
									if (checked) {
										setUserResponseList(
											userResponseList.filter(
												(i) => i.QID !== c.QID
											)
										);
										if(allQuestion[qqid].hcs === "1") {
											let list = [...userHCSList];
											let newUserHCSList = list.filter(
												(i) => i.QID !== allQuestion[qqid].QID
											)
											let resObj = {
												QID: allQuestion[qqid].QID,
												CID: id,
												weight: value*c.weightage,
											}
											newUserHCSList.push(resObj);
											setUserHCSList(newUserHCSList);
										}
									}
									setUserResponseList((prev) => {
										return [...prev, resObj];
									});
								}}
							/>
							)}
						</li>
					))}
				</Form>
			</ol>
		</div>
	);
};

export default PickOne;
