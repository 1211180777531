import React, { useContext } from "react";
import { Navbar, Container, Stack, Form, Button, Nav, NavDropdown } from "react-bootstrap";
import logo from "../image/Helix-logo.png";
import { AccountContext } from "./Contexts/AccountContext";
import Navigate from "./Navigate";
import { useMediaQuery } from "react-responsive";
import menu from "../image/menu.svg";
import { Helmet } from "react-helmet";

const ContactUs = () => {
	const { loginStatus, loggedInUser } = useContext(AccountContext);
	const isMobile = useMediaQuery({ maxWidth: 767 });
    const isLandscapeMobile = useMediaQuery({ query: '(orientation: landscape) and (max-height: 431px' });

	return (
		<div>
			<Helmet>
				<title>Helix Employability Tool | Contact Us</title>
			</Helmet>
			{loginStatus === true && loggedInUser.group === "User" ? (
				<>
					<Navigate />
				</>
			) : (
				<Navbar fixed="top" className="navbar">
					<Container>
						<Navbar.Brand href="/">
							<img
								alt=""
								src={logo}
								width="auto"
								height="40"
								className="d-inline-block align-top"
							/>
						</Navbar.Brand>
						{isMobile ? (
                        <NavDropdown
                         title={<img src={menu} alt="Menu" />}
                         id="basic-nav-dropdown"
                         align="end"
                         style={{ marginRight: "-5px" }}
                         className="custom-dropdown"
                        >
                           <NavDropdown.Item href="/">Home</NavDropdown.Item>
                           <NavDropdown.Divider />
                           <NavDropdown.Item href="/about-us">About Us</NavDropdown.Item>
                           <NavDropdown.Divider />
                           <NavDropdown.Item href="/contact-us">Contact Us</NavDropdown.Item>
                           <NavDropdown.Divider />
                           <NavDropdown.Item href="/login">Sign in</NavDropdown.Item>
                           <NavDropdown.Divider />
                           <NavDropdown.Item href="/register">Sign up</NavDropdown.Item>
                        </NavDropdown>
                    ) : isLandscapeMobile? (<NavDropdown
                        title={<img src={menu} alt="Menu" />}
                        id="basic-nav-dropdown"
                        align="end"
                        style={{ marginRight: "-5px" }}
                        className="custom-dropdown"
                       >
                          <NavDropdown.Item href="/">Home</NavDropdown.Item>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="/about-us">About Us</NavDropdown.Item>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="/contact-us">Contact Us</NavDropdown.Item>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="/login">Sign in</NavDropdown.Item>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="/register">Sign up</NavDropdown.Item>
                       </NavDropdown>):(
                        <Nav className="nav-right-gap">
                            <Nav.Link href="/">Home</Nav.Link>
                            <Nav.Link href="/about-us">About Us</Nav.Link>
                            <Nav.Link href="/contact-us">Contact Us</Nav.Link>
                            <Nav.Link href="/login"><Button>Sign in</Button></Nav.Link>
                            <Nav.Link href="/register"><Button className="custom-button">Sign up</Button></Nav.Link>
                        </Nav>
                    )}
					</Container>
				</Navbar>
			)}
			<br />
			<div className="ac-div">
				<Stack gap={1}>
					<h1 className="text-muted">
						Contact <span style={{ color: "#163172" }}>Us</span>
					</h1>
					<hr />
					<h5>
						For questions about Helix, please contact the research
						team at
						<Nav.Link
							style={{ color: "#163172" }}
							href="mailto:helix@stevens.edu"
						>
							helix@stevens.edu
						</Nav.Link>
					</h5>
					<h5>
						For technical assistance, please contact the support
						team at
						<Nav.Link
							style={{ color: "#163172" }}
							href="mailto:helixsupport@stevens.edu"
						>
							helixsupport@stevens.edu
						</Nav.Link>
					</h5>
					<hr />
					<h3 className="text-muted">Helix - Developers</h3>
					<hr />
					<br />
					<Stack
						style={{ justifyContent: "space-around" }}
						direction={isMobile? "vertical":"horizontal"}
					>
						<div style={{ padding: "10px" }}>
							<h3>
								{" "}
								<strong className="text-muted">
									Dr. Nicole Hutchison
								</strong>{" "}
							</h3>
							<br />
							<h6 className="text-muted">
								Principal Investigator
								<br />
								Systems Engineering Research Center
								<br />
								Stevens Institute of Technology <br />
								nlong@stevens.edu
								<br />
								Original Concept Design
								<br />
							</h6>
						</div>
						<div className="vr" />
						<div style={{ padding: "10px" }}>
							<h3>
								{" "}
								<strong className="text-muted">
									Thomas McDermott
								</strong>{" "}
							</h3>
							<br />
							<h6 className="text-muted">
								Principal Investigator
								<br />
								Systems Engineering Research Center
								<br />
								Stevens Institute of Technology <br />
								tmcdermo@stevens.edu
								<br />
								Original Concept Design
								<br />
							</h6>
						</div>
						<div className="vr" />
						<div style={{ padding: "10px" }}>
							<h3>
								{" "}
								<strong className="text-muted">
									Soham{isMobile? null: <br/>} Talekar
								</strong>{" "}
							</h3>
							<br />
							<h6 className="text-muted">
								Full Stack Developer - Grad Student
								<br />
								Systems Engineering Research Center
								<br />
								Stevens Institute of Technology <br />
								stalekar@stevens.edu
								<br />
							</h6>
						</div>
					</Stack>
					<br />
				</Stack>
			</div>
		</div>
	);
};

export default ContactUs;
