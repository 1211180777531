import React, { useContext } from "react";
import Navigate from "./Navigate";
import denied from "../image/accessdenied.svg";
import { Image } from "react-bootstrap";
import { AccountContext } from "./Contexts/AccountContext";
import { Link } from "react-router-dom";

const AccessDenied = () => {
	const { loginStatus, loggedInUser } = useContext(AccountContext);
	return (
		<div>
			<Navigate />
			<div className="confirm">
				<Image
					className="flex justify-content-center"
					src={denied}
					alt="No Image"
					width="450"
					height="450"
				/>
				<br />
				<br />
				<h3>AccessDenied !</h3>

				{loginStatus === true && loggedInUser.group === "Admin" ? (
					<>
						<p>You do not have access to this page!</p>
						<p>
							Take me to <Link to="/admin">Admin page</Link>{" "}
						</p>
					</>
				) : loginStatus === true && loggedInUser.group === "User" ? (
					<>
						<p>You do not have access to this page!</p>
						<p>
							Take me to{" "}
							<Link to={`/user/dashboard/${loggedInUser.UID}`}>
								Dashboard
							</Link>{" "}
						</p>
					</>
				) : (
					<p>
						Login or Register with <Link to="/">Helix</Link> to
						continue
					</p>
				)}
			</div>
		</div>
	);
};

export default AccessDenied;
