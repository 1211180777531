import React, { useContext, useEffect, useState } from "react";
import Navigate from "../../Navigate";
import { AccountContext } from "../../Contexts/AccountContext";
import { AssmtContext } from "../../Contexts/AssmtContext";
import { UserContext } from "../../Contexts/UserContext";
import AccessDenied from "../../AccessDenied";
import ToastMessage from "../../ToastMessage";
import UserListTable from "./UserListTable";
import { Button, Modal, Tab, Tabs } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { Helmet } from "react-helmet";
import GroupListTable from "./GroupListTable";
import EnableDisableModal from "../../Common/EnableDisableModal";
import { GroupContext } from "../../Contexts/GroupContext";

const UserManagement = () => {
  const { loginStatus, loggedInUser, apiCall } = useContext(AccountContext);
  const { toast, setToast, message, setMessage, status, setStatus } =
    useContext(AssmtContext);
  const { set_active_users, active_user_management_tab, set_active_user_management_tab } = useContext(UserContext);
  const {setGroupList} = useContext(GroupContext);
  const [userList, setUserList] = useState([]);
  const [show1, setShow1] = useState(false);
  const [show4, setShow4] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [allUserRes, setAllUserRes] = useState([]);
  const [allresCSVFilename, setAllresCSVFilename] = useState("");
  const [header, setHeader] = useState("");
  const [body, setBody] = useState("");
  const [userToDisable, setUserToDisable] = useState("");

  const getUserList = async () => {
    try {
      if (loggedInUser.jwtToken !== undefined) {
        let resp = await apiCall("user/getAllUser");
        if (resp?.data) {
          setUserList(resp?.data);
        }
        return;
      }
    } catch (err) {
      console.log("Error : ",err);
      setMessage("Unable to fetch users!");
      setStatus(false);
      setToast(true);
      return;
    }
  };

  const changeStatus = async () => {
          try {
            let resp = await apiCall(`user/updateStatus/${userToDisable.UID}`, "PUT", {
              status: false
            });

            if (resp?.name === "AxiosError")
                throw {message: 'Sorry, something went wrong!'}
            if (resp?.data?.error) throw resp.data;

            //filtering out the user that was disabled
            setUserList((data) => {
              return data.filter((user) => {
                return user.email_ID !== userToDisable.email_ID;
              });
            });

            //update the disable group memebers count
            setGroupList((groups) => {
              return groups.map((e) => {
                if (e.GID == '3') {
                  return { ...e, no_members: e.no_members + 1};
                }
                return e;
              });
            });

            setMessage("User disabled successfully!");
            setStatus(true);
            setToast(true);
          } catch (err) {
            console.log("Error while updating user status: ", err);
            setMessage(err.message);
            setStatus(false);
            setToast(true);
            return;
          }
        }

  useEffect(() => {
    getUserList();
  }, [loggedInUser.UID]);

  useEffect(() => {
    set_active_users(userList);
  }, [userList]);

  return (
    <div>
      {loginStatus === true && loggedInUser.group === "Admin" ? (
        <>
          <Helmet>
            <title>Helix Employability Tool | User Management</title>
          </Helmet>
          <Navigate />
          <h6>
            <a href="#" onClick={() => window.history.back()}>
              &lt;Back
            </a>
          </h6>
          <ToastMessage
            toast={toast}
            onClose={() => setToast(!toast)}
            message={message}
            status={status}
          />
          <Modal
            show={show1}
            onHide={handleClose1}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>{header}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{body}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose1}>
                Cancel
              </Button>
              <CSVLink
                data={allUserRes}
                headers={[
                  { label: "Assessment ID", key: "AID" },
                  { label: "User Assmt ID", key: "UAID" },
                  { label: "Section Name", key: "sec_name" },
                  { label: "Question", key: "question" },
                  { label: "Type", key: "type" },
                  { label: "Choice picked", key: "choice" },
                  { label: "Weightage of choice", key: "weightage" },
                  { label: "Weightage of response", key: "response" },
                ]}
                filename={allresCSVFilename}
                target="_blank"
              >
                <Button variant="success" onClick={handleClose1}>
                  Download
                </Button>
              </CSVLink>
            </Modal.Footer>
          </Modal>
          
          {/* Modal for disabling a particular user  */}
          <EnableDisableModal 
            show={show4}
            handleClose={handleClose4}
            title={"Disable User"}
            bodyText={`Are you sure you want to disable ${userToDisable?.first_name} ${userToDisable?.last_name}?`}
            callback={changeStatus}
          />

          <Tabs
            defaultActiveKey={`${active_user_management_tab.current == 0 ? 'UserList' : 'GroupList'}`}
            id="uncontrolled-tab-example"
            className="mb-3"
            onSelect={(val) => val == 'UserList' ? set_active_user_management_tab(0) : set_active_user_management_tab(1)}
          >
            <Tab eventKey="UserList" title="Users">
              <UserListTable
                userList={userList}
                setUserList={setUserList}
                setAllUserRes={setAllUserRes}
                handleShow1={handleShow1}
                setAllresCSVFilename={setAllresCSVFilename}
                setHeader={setHeader}
                setBody={setBody}
                handleShow4={handleShow4}
                setUserToDisable={setUserToDisable}
              />
            </Tab>

            {/* Group List  */}
            <Tab eventKey="GroupList" title="Groups">
              <GroupListTable
                apiCall={apiCall}
                loggedInUser={loggedInUser}
                loginStatus={loginStatus}
              />
            </Tab>
          </Tabs>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
};

export default UserManagement;
