import React from "react";
import { Accordion, Badge, Form, Stack, Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";

const PreviewAssmt = ({ sectionList }) => {
  return (
    <>
      {sectionList.map((singleSec, sidx) => (
        <div key={sidx}>
          <Accordion alwaysOpen>
            <Accordion.Item>
              <Accordion.Header>Section #{sidx + 1}</Accordion.Header>
              <Accordion.Body>
                <Stack>
                  <label>Name: {singleSec.sName}</label>
                  <label>Description: {singleSec.sDesc}</label>
                  <br />
                  <label>Questions: </label>
                </Stack>
                <ol>
                  {singleSec.qIDs.map((singleQue, qidx) => (
                    <li key={qidx}>
                      <div className="view-question-body" key={qidx}>
                        <div className="d-flex justify-content-between">
                          <label>{singleQue.qQuestion}
                          {singleQue.tooltip && singleQue.tooltip !== "" && (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{singleQue.tooltip}</Tooltip>}
                  >
                    <span
                      style={{
                        marginLeft: "8px",
                        cursor: "pointer",
                        color: "#31708f",
                      }}
                    >
                      <i className="fas fa-info-circle"></i>
                    </span>
                  </OverlayTrigger>
                )}
                          </label>
                          <Badge bg="secondary">Type: {singleQue.qType}</Badge>
                        </div>
                        <div>
                          {singleQue.qType === "Multiple" ? (
                            <div className="mult-opt">
                              {singleQue.cIDs.map((c, ccid) => (
                                <Form.Check
                                  key={ccid}
                                  type="checkbox"
                                  id={c.ccid}
                                  label={c.cName}
                                />
                              ))}
                            </div>
                          ) : singleQue.qType === "Inventory" ? (
                            <Table borderless>
                              <tbody>
                                <tr className="text-muted">
                                  <td></td>
                                  <td>"A Novice"</td>
                                  <td>"A Practitioner"</td>
                                  <td>"An Expert"</td>
                                  <td>"A Leader"</td>
                                </tr>
                                {singleQue.cIDs.map((c, ccid) => (
                                  <tr key={ccid}>
                                    <td className="text-muted">{c.cName}
                                    {c.tooltip && c.tooltip !== "" && (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{c.tooltip}</Tooltip>}
                  >
                    <span
                      style={{
                        marginLeft: "8px",
                        cursor: "pointer",
                        color: "#31708f",
                      }}
                    >
                      <i className="fas fa-info-circle"></i>
                    </span>
                  </OverlayTrigger>
                )}
                                    </td>
                                    <td>
                                      <Form.Check name={ccid} type="radio" />
                                    </td>
                                    <td>
                                      <Form.Check name={ccid} type="radio" />
                                    </td>
                                    <td>
                                      <Form.Check name={ccid} type="radio" />
                                    </td>
                                    <td>
                                      <Form.Check name={ccid} type="radio" />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          ) : singleQue.qType === "Likert" ? (
                            <Table borderless>
                              <tbody>
                                <tr>
                                  <td></td>
                                  <td>"Not At All Like Me"</td>
                                  <td>"Not Very Like Me"</td>
                                  <td>"A Little Like Me"</td>
                                  <td>"Somewhat Like Me"</td>
                                  <td>"Very Like Me"</td>
                                </tr>
                                {singleQue.cIDs.map((c, ccid) => (
                                  <tr key={ccid}>
                                    <td>{c.cName}

                                    {c.tooltip && c.tooltip !== "" && (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{c.tooltip}</Tooltip>}
                  >
                    <span
                      style={{
                        marginLeft: "8px",
                        cursor: "pointer",
                        color: "#31708f",
                      }}
                    >
                      <i className="fas fa-info-circle"></i>
                    </span>
                  </OverlayTrigger>
                )}
                                    </td>
                                    <td>
                                      <Form.Check name={ccid} type="radio" />
                                    </td>
                                    <td>
                                      <Form.Check name={ccid} type="radio" />
                                    </td>
                                    <td>
                                      <Form.Check name={ccid} type="radio" />
                                    </td>
                                    <td>
                                      <Form.Check name={ccid} type="radio" />
                                    </td>
                                    <td>
                                      <Form.Check name={ccid} type="radio" />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          ) : singleQue.qType === "Likert-6" ? (
                            <Table borderless>
                              <tbody>
                                <tr>
                                  <td></td>
                                  <td>"Strongly disagree"</td>
                                  <td>"Moderately disagree"</td>
                                  <td>"Slightly disagree"</td>
                                  <td>"Slightly agree"</td>
                                  <td>"Moderately agree"</td>
                                  <td>"Strongly agree</td>
                                </tr>
                                {singleQue.cIDs.map((c, ccid) => (
                                  <tr key={ccid}>
                                    <td>{c.cName}

                                    {c.tooltip && c.tooltip !== "" && (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{c.tooltip}</Tooltip>}
                  >
                    <span
                      style={{
                        marginLeft: "8px",
                        cursor: "pointer",
                        color: "#31708f",
                      }}
                    >
                      <i className="fas fa-info-circle"></i>
                    </span>
                  </OverlayTrigger>
                )}
                                    </td>
                                    <td>
                                      <Form.Check name={ccid} type="radio" />
                                    </td>
                                    <td>
                                      <Form.Check name={ccid} type="radio" />
                                    </td>
                                    <td>
                                      <Form.Check name={ccid} type="radio" />
                                    </td>
                                    <td>
                                      <Form.Check name={ccid} type="radio" />
                                    </td>
                                    <td>
                                      <Form.Check name={ccid} type="radio" />
                                    </td>
                                    <td>
                                      <Form.Check name={ccid} type="radio" />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          ) : singleQue.qType === "Slider" ? (
                            <Table borderless>
                              <tbody>
                                {singleQue.cIDs.map((c, ccid) => (
                                  <tr key={ccid}>
                                     <td style={{ width: "25%" }}>
                      {c.cName}
                      {c.tooltip && c.tooltip !== "" && (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{c.tooltip}</Tooltip>}
                  >
                    <span
                      style={{
                        marginLeft: "8px",
                        cursor: "pointer",
                        color: "#31708f",
                      }}
                    >
                      <i className="fas fa-info-circle"></i>
                    </span>
                  </OverlayTrigger>
                )}
                      
                      </td>
                    <td style={{ width: "75%" }}>
                      <Stack direction="horizontal" gap={3}>
                        {/* if text input or multi dropdown is selected  */}
                        <div style={{ width: "60%" }}>
                          {c.opt_type == "1" ? (
                            <Select
                              options={c.options_list?.split(",").map((val)=> ({value:val,label:val})) || {value:'No Option Selected', label:'No Option Selected'}}
                              isMulti={true}
                              placeholder={c.placeholder}
                              styles={{
                                control: (base) => ({
                                  ...base,
                                  border: "1px solid #ced4da",
                                  boxShadow: "none",
                                }),
                                multiValueRemove: (styles) => ({
                                  ...styles,
                                  ":hover": {
                                    backgroundColor: "#0d6efd",
                                    color: "white",
                                  },
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: "gray",
                                  fontWeight: 400,
                                  fontSize: "0.75rem",
                                  textAlign: "left",
                                }),
                              }}
                            />
                          ) : (
                            <>
                              <Form.Control
                                className="sliderInput"
                                type="text"
                                placeholder={c.placeholder}
                                id={c.CID}
                                style={{
                                  visibility: `${
                                    c.opt_type == "2" ? "visible" : "hidden"
                                  }`,
                                }}
                              />
                            </>
                          )}
                        </div>
                        {/* corresponding slider  */}
                        <input
                          type="range"
                          className="custom-range"
                          min="0"
                          max="5"
                          step="1"
                          style={{
                            width: "30%",
                            maxWidth: "400px",
                            cursor: "pointer",
                          }}
                          id={c.CID}
                        />
                      </Stack>
                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          ) : singleQue.qType === "Pick One" ? (
                            <div className="mult-opt">
                              <Form>
                                {singleQue.cIDs.map((c, ccid) => (
                                  <Form.Check
                                    name="group1"
                                    key={ccid}
                                    type="radio"
                                    id={c.ccid}
                                    label={c.cName}
                                  />
                                ))}
                              </Form>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <br />
                    </li>
                  ))}
                </ol>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <br />
        </div>
      ))}
    </>
  );
};

export default PreviewAssmt;
