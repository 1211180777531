import React, { useState, useEffect, useRef } from 'react';
import Roundy from 'roundy';

const RoundyWrapper = ({ radiusPercentage, ...props }) => {
    const wrapperRef = useRef(null);
    const [calculatedRadius, setCalculatedRadius] = useState(0);

    const updateRadius = () => {
        if (wrapperRef.current) {
            const parentWidth = wrapperRef.current.offsetWidth;
            console.log(parentWidth);
            const newRadius = (parentWidth * radiusPercentage) / 100;
            setCalculatedRadius(newRadius);
        }
    };

    useEffect(() => {
        updateRadius();
        window.addEventListener('resize', updateRadius);
        return () => window.removeEventListener('resize', updateRadius);
    }, [radiusPercentage]);

    return (
        <div ref={wrapperRef} style={{ width: '100%', height: '100%' }}>
            <Roundy {...props} radius={calculatedRadius} />
        </div>
    );
};

export default RoundyWrapper;