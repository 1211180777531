import React, { useContext, useEffect, useState } from "react";
import { Form, Stack, Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import { UserContext } from "../../Contexts/UserContext";
import Select from "react-select";

// opt_type:
// 1 -> multi dropdown
// 2 -> text input
// 3 -> None of the either

const Slider = (props) => {
  let q = props.q;
  let qqid = props.qqid;
  let allQuestion = props.allQuestion;
  let isMobile = props.isMobile;
  const { userResponseList, setUserResponseList, userHCSList, setUserHCSList } =
    useContext(UserContext);
  const [options, setOptions] = useState([]); //these are the list of options for multi dropdown

  useEffect(() => {
    //for slider questions, make the default choice as 0 and update accordingly if the user tries to slide it
    let responses = [];
    let updatedOptionsArray = [];
    allQuestion[qqid].choices.forEach((choice) => {
      //if the choice is already checked, then dont add it again in the response array
      if (choice["checked"]) return;
      choice["checked"] = 1;
      let respObj = {
        response: "0",
        type: q.type,
        CID: choice.CID,
        QID: choice.QID,
        SecID: choice.SecID,
        additionalChoiceText: "", //this will contain the response for the textbox
        additionalChoiceArray: [], //this will contain the response for the multi select drop down
      };

      responses.push(respObj);

      // not to be done for all the choices. Only choices marked with opt_type = 1
      if (choice.opt_type == "1") {
        let obj = {
          CID: choice.CID,
          options: [],
        };
        //now split the comma-seperated value into a list of options
        let optionsArray = choice.options_list.split(",").map((option) => {
          return { value: option, label: option };
        });
        obj["options"] = optionsArray;
        updatedOptionsArray.push(obj);
      }
    });

    let hcsResponses = [];
    if (q.hcs == "1") {
      q.choices.forEach((choice) => {
        let resObj = {
          QID: q.QID,
          CID: choice.CID,
          weight: 0 * choice.weightage,
        };
        hcsResponses.push(resObj);
      });
    }

    setOptions(updatedOptionsArray);

    setUserResponseList((prev) => {
      return [...prev, ...responses];
    });

    setUserHCSList((prev) => {
      return [...prev, ...hcsResponses];
    });
  }, [allQuestion[qqid].choices]);

  const handleAnswerUpdate = (e, c) => {
    const { value, id } = e.target;

    if (allQuestion[qqid].hcs === "1") {
      setUserHCSList((prev) => {
        return [
          ...prev.map((obj) => {
            if (obj.CID === id) {
              return { ...obj, weight: value * c.weightage };
            }

            return obj;
          }),
        ];
      });
    }

    setUserResponseList((prev) => {
      return [
        ...prev.map((obj) => {
          if (obj.CID === id) {
            return { ...obj, response: value };
          }
          return obj;
        }),
      ];
    });
  };

  //handling text input
  const handleTextOption = (e, c) => {
    const { value, id } = e.target;

    setUserResponseList((prev) => {
      return [
        ...prev.map((obj) => {
          if (obj.CID === id) {
            return { ...obj, additionalChoiceText: value };
          }
          return obj;
        }),
      ];
    });
  };

  //handling dropdown option
  const handleDropdownOption = (e, c) => {
    setUserResponseList((prev) => {
      return [
        ...prev.map((obj) => {
          if (obj.CID === c.CID) {
            return { ...obj, additionalChoiceArray: e };
          }
          return obj;
        }),
      ];
    });
  };

  return (
    <div>
      {isMobile ? (
        <Form>
          {allQuestion[qqid].choices.map((c, ccid) => (
            <Form.Group
              key={ccid}
              controlId={c.CID}
              style={{ marginBottom: "0.5rem" }}
            >
                <div style={{ display: "flex", alignItems: "center" }}>
                <Form.Label style={{ marginBottom: "0.25rem" }}>
                  {c.choice}
                </Form.Label>
                {c.tooltip && c.tooltip !== "" && (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{c.tooltip}</Tooltip>}
                  >
                    <span
                      style={{
                        marginLeft: "8px",
                        cursor: "pointer",
                        color: "#31708f",
                      }}
                    >
                      <i className="fas fa-info-circle"></i>
                    </span>
                  </OverlayTrigger>
                )}
              </div>
              <div className="my-1">
                {c.opt_type == "1" ? (
                  <Select
                    placeholder={c.placeholder}
                    options={
                      options.find((resp) => resp.CID === c.CID)?.options
                    }
                    value={
                      userResponseList.find((resp) => resp.CID === c.CID)
                        ?.additionalChoiceArray
                    }
                    onChange={(e) => {
                      handleDropdownOption(e, c);
                    }}
                    isMulti={true}
                    styles={{
                      control: (base) => ({
                        ...base,
                        border: "1px solid #ced4da",
                        boxShadow: "none",
                      }),
                      multiValueRemove: (styles) => ({
                        ...styles,
                        ":hover": {
                          backgroundColor: "#0d6efd",
                          color: "white",
                        },
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: "gray",
                        fontWeight: 400,
                        fontSize: "0.75rem",
                        textAlign: "left",
                      }),
                    }}
                  />
                ) : c.opt_type == "2" ? (
                  <>
                    <Form.Control
                      className="sliderInput"
                      type="text"
                      placeholder={c.placeholder}
                      id={c.CID}
                      name="additionalChoiceText"
                      value={
                        userResponseList.find((resp) => resp.CID === c.CID)
                          ?.additionalChoiceText
                      }
                      onChange={(e) => {
                        handleTextOption(e, c);
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
              <p className="mb-1">
                Score:{" "}
                {`${
                  userResponseList.find((resp) => resp.CID === c.CID)
                    ?.response || 0
                }.0`}
              </p>
              <input
                type="range"
                className="custom-range"
                min="0"
                max="5"
                step="1"
                style={{ width: "100%", maxWidth: "400px", cursor: "pointer" }}
                onChange={(e) => handleAnswerUpdate(e, c)}
                value={
                  userResponseList.find((resp) => resp.CID === c.CID)
                    ?.response || 0
                }
                id={c.CID}
              />
            </Form.Group>
          ))}
        </Form>
      ) : (
        <Table borderless>
          <tbody>
            {allQuestion[qqid].choices.map((c, ccid) => (
              <tr key={ccid}>
                {/* sub question  */}
                <td style={{ width: "25%" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    {c.choice}
                    {c.tooltip && c.tooltip !== "" && (
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{c.tooltip}</Tooltip>}
                      >
                        <span
                          style={{
                            marginLeft: "8px",
                            cursor: "pointer",
                            color: "#31708f",
                          }}
                        >
                          <i className="fas fa-info-circle"></i>
                        </span>
                      </OverlayTrigger>
                    )}
                  </div>
                </td>
                <td style={{ width: "75%" }}>
                  <Stack direction="horizontal" gap={3}>
                    {/* if text input or multi dropdown is selected  */}
                    <div style={{ width: "60%" }}>
                      {c.opt_type == "1" ? (
                        <Select
                          placeholder={c.placeholder}
                          options={
                            options.find((resp) => resp.CID === c.CID)?.options
                          }
                          value={
                            userResponseList.find((resp) => resp.CID === c.CID)
                              ?.additionalChoiceArray
                          }
                          onChange={(e) => {
                            handleDropdownOption(e, c);
                          }}
                          isMulti={true}
                          styles={{
                            control: (base) => ({
                              ...base,
                              border: "1px solid #ced4da",
                              boxShadow: "none",
                            }),
                            multiValueRemove: (styles) => ({
                              ...styles,
                              ":hover": {
                                backgroundColor: "#0d6efd",
                                color: "white",
                              },
                            }),
                            placeholder: (provided) => ({
                              ...provided,
                              color: "gray",
                              fontWeight: 400,
                              fontSize: "0.75rem",
                              textAlign: "left",
                            }),
                          }}
                        />
                      ) : (
                        <>
                          <Form.Control
                            className="sliderInput"
                            type="text"
                            placeholder={c.placeholder}
                            id={c.CID}
                            name="additionalChoiceText"
                            value={
                              userResponseList.find(
                                (resp) => resp.CID === c.CID
                              )?.additionalChoiceText
                            }
                            onChange={(e) => {
                              handleTextOption(e, c);
                            }}
                            style={{
                              visibility: `${
                                c.opt_type == "2" ? "visible" : "hidden"
                              }`,
                            }}
                          />
                        </>
                      )}
                    </div>
                    {/* corresponding slider  */}
                    <input
                      type="range"
                      className="custom-range"
                      min="0"
                      max="5"
                      step="1"
                      style={{
                        width: "30%",
                        maxWidth: "400px",
                        cursor: "pointer",
                      }}
                      onChange={(e) => handleAnswerUpdate(e, c)}
                      value={
                        userResponseList.find((resp) => resp.CID === c.CID)
                          ?.response || 0
                      }
                      id={c.CID}
                    />
                    <div>
                      {`${
                        userResponseList.find((resp) => resp.CID === c.CID)
                          ?.response || 0
                      }.0`}
                    </div>
                  </Stack>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default Slider;
