import React, { createContext, useState, useEffect } from "react";
import {
	CognitoUserPool,
	AuthenticationDetails,
	CognitoUser,
} from "amazon-cognito-identity-js";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { getBaseUrl } from "../../base";

/**
 * @callback ApiCallFunc
 * Makes an asynchronous API call using Axios.
 * @param {string} url - The URL for the API endpoint.
 * @param {string} [method="GET"] - The HTTP method for the request (default is "GET").
 * @param {Object} [data={}] - The data to be sent with the request (default is an empty object).
 * @param {Object} [headers={}] - The headers to be included in the request (default is an empty object).
 * @param {Object} [params={}] - The query parameters to be included in the request (default is an empty object).
 * @returns {Promise} A Promise that resolves to the response data from the API call.
 */

export const AccountContext = createContext();

const AccountContextProvider = ({ children }) => {
	const [loggedInUser, setLoggedInUser] = useState({
        UID:"",
		first_name:"",
		last_name: "",
		email_ID: "",
		email_verified: "",
		jwtToken: "",
		group: "",
		groupNames:[],
		gids:[],
	});
	const [loginStatus, setLoginStatus] = useState(false);
	const navigate = useNavigate();
	const baseURL = `${getBaseUrl()}/`;
	const axios = Axios.create({
		baseURL,
	});

	/**
	 * @type {ApiCallFunc}
	 */
	const apiCall = async (
		url,
		method = "GET",
		data = {},
		headers = {},
		params = {}
	) => {
		const token = loggedInUser.jwtToken;
		if (token) {
			try {
				return await axios({
					method,
					url,
					data,
					params,
					headers: {
						...headers,
						Authorization: `Bearer ${token}`,
					},
				});
			} catch (error) {
				console.log("CLIENT ERROR : ",error);
				if (error.response && error.response.status === 401) {
					if (error.response.data === "Session has expired. Please log in again." || error.response.data.message === "Session has expired. Please log in again.") {
						console.error("Session expired. Please log in again.", error);
						logout();
						navigate("/", { state: { sessionExpired: true } });
					} else {
						console.error("Unauthorized access.", error);
					}
				} else {
					console.error("An error occurred:", error);
				}
				throw error;
			}
		} 
	};

	const poolData = {
		UserPoolId: process.env.REACT_APP_USERPOOLID,
		ClientId: process.env.REACT_APP_CLIENTID,
	};

	const Pool = new CognitoUserPool(poolData);

	const authenticate = async (Username, Password) =>
		await new Promise((resolve, reject) => {
			const user = new CognitoUser({ Username, Pool });
			const authDetails = new AuthenticationDetails({
				Username,
				Password,
			});

			user.authenticateUser(authDetails, {
				onSuccess: async (data) => {
					try{
					let sessionObj = data.idToken.payload;
					let jwtToken = data.accessToken.jwtToken;
					const resp = await Axios.get(
						`${getBaseUrl()}/admin/getUserDetails/${sessionObj.sub}`,
						{
							headers: {
								Authorization: `Bearer ${jwtToken}`,
							},
						}
					);
					// console.log("response : ",resp);
					if (resp?.data?.email_verified != sessionObj.email_verified) {
					    await Axios.put(
							`${getBaseUrl()}/user/updateEmail_verified/${sessionObj.sub}`,
							{ email_verified: sessionObj.email_verified },
							{
								headers: {
									Authorization: `Bearer ${jwtToken}`,
								},
							}
						);
					}
					if (jwtToken !== undefined) {
						let userInfo = {};
						userInfo["UID"] = sessionObj.sub;
						userInfo["first_name"] = sessionObj.name;
						userInfo["last_name"] = sessionObj.family_name;
						userInfo["email_ID"] = sessionObj.email;
						userInfo["email_verified"] = sessionObj.email_verified;
						userInfo["jwtToken"] = jwtToken;
						userInfo["group"] = resp?.data?.group_name;
						userInfo["groupNames"] = resp?.data?.GroupNames;
						userInfo["gids"] = resp?.data?.GIDs;
						// console.log("USERRRRRRR : ",userInfo);
						setLoggedInUser(userInfo);
						setLoginStatus(true);
						resolve(userInfo);	
					}

					}catch(err){
						console.log("error in fetching user details : ",err);
						reject(err);
					}
				},

				onFailure: (err) => {
					//   console.error("onFailure:", err);
					reject(err);
				},

				newPasswordRequired: (data) => {
					//   console.log("newPasswordRequired:", data);
					resolve(data);
				},
			});
		});

	const getSession = async () =>
		await new Promise((resolve, reject) => {
			const user = Pool.getCurrentUser();
			if (user) {
				user.getSession(async (err, session) => {
					if (err) {
						reject();
					} else {
						try{
							let sessionObj = session.idToken.payload;
							let jwtToken = session.accessToken.jwtToken;
							const resp = await Axios.get(
								`${getBaseUrl()}/admin/getUserDetails/${sessionObj.sub}`,
								{
									headers: {
										Authorization: `Bearer ${jwtToken}`,
									},
								}
							);		
							if (jwtToken !== undefined) {
								let userInfo = {};
								userInfo["UID"] = sessionObj.sub;
								userInfo["first_name"] = sessionObj.name;
								userInfo["last_name"] = sessionObj.family_name;
								userInfo["email_ID"] = sessionObj.email;
								userInfo["email_verified"] = sessionObj.email_verified;
								userInfo["jwtToken"] = jwtToken;
								userInfo["group"] = resp?.data?.group_name;
								userInfo["groupNames"] = resp?.data?.GroupNames;
								userInfo["gids"] = resp?.data?.GIDs;
								resolve({ user, userInfo });
							}
						}catch(err){
                            console.log("error : ",err);
							logout();
						}
					}
				});
			} else {
				reject();
			}
		});

	useEffect(() => {
		const getSessionData = async () => {
			const { userInfo } = await getSession();
			setLoggedInUser(userInfo);
			setLoginStatus(true);
		};
		getSessionData();
	}, []);

	const logout = () => {
		const user = Pool.getCurrentUser();
		if (user) {
			user.signOut();
			setLoggedInUser({
				UID:"",
				first_name:"",
				last_name: "",
				email_ID: "",
				email_verified: "",
				jwtToken: "",
				group: "",
				groupNames:[],
				gids:[],
			});
			setLoginStatus(false);
		}
	};

	return (
		<AccountContext.Provider
			value={{
				Pool,
				authenticate,
				getSession,
				loginStatus,
				loggedInUser,
				setLoginStatus,
				setLoggedInUser,
				logout,
				apiCall,
			}}
		>
			{children}
		</AccountContext.Provider>
	);
};

export default AccountContextProvider;
