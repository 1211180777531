import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Container } from "react-bootstrap";

//Common routes
import Landing from "./components/Landing";
import PageNotFound from "./components/PageNotFound";
import AccessDenied from "./components/AccessDenied";
import Login from "./components/Login";
import ForgotPassword from "./components/ForgotPassword";
import Register from "./components/Register";
import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";

//Admin routes
import Admin from "./components/Admin/Admin";
import UserManagement from "./components/Admin/UserManagement/UserManagement";
import UserResponseList from "./components/Admin/UserManagement/UserResponseList";
import NewAssmt from "./components/Admin/Assmt/NewAssmt";
import ViewAssmt from "./components/Admin/ViewAssmt/ViewAssmt";
import ViewAllResponse from "./components/Admin/ViewResponse/ViewAllResponse";
import ViewUserResponse from "./components/Admin/ViewResponse/ViewUserResponse";
import ViewGrpAssmtUserResp from "./components/Admin/ViewResponse/ViewGrpAssmtUserResp";
import EditAssmt from "./components/Admin/EditAssmt/EditAssmt";
import ViewGroupAssmt from "./components/Admin/ViewGroupAssmt/ViewGroupAssmt";

//User routes
import Dashboard from "./components/User/Common/Dashboard";
import Profile from "./components/User/Common/Profile";
import EditProfile from "./components/User/Common/EditProfile";
import UserAssmt from "./components/User/CreateResponse/UserAssmt";
import UserResponse from "./components/User/CreateResponse/UserResponse";
import Confirmation from "./components/User/Common/Confirmation";
import Results from "./components/User/Result/Results";
import ViewSingleResult from "./components/User/Result/ViewSingleResult";
import ViewGrpUserResult from "./components/User/Result/ViewGrpUserResult";

//Analyze routes
import AnalyzeBySection from "./components/Admin/Analyze/BySection";

import AccountContextProvider from "./components/Contexts/AccountContext";
import AssmtContextProvider from "./components/Contexts/AssmtContext";
import UserContextProvider from "./components/Contexts/UserContext";
import EditAssmtContextProvider from "./components/Contexts/EditAssmtContext";
import GroupContextProvider from "./components/Contexts/GroupContext";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import QuestionBank from "./components/Admin/QuestionBank/QuestionBank";
import NewAssmtGroup from "./components/Admin/GroupAssmt/NewGroupAssmt";
import EditGroupAssmt from "./components/Admin/EditGroupAssmt/EditGroupAssmt";
import UserGrpAssmt from "./components/User/CreateResponse/UserGrpAssmt";

//group routes
import ViewGroup from "./components/Admin/Group/ViewGroup";

import VerifyEmail from "./components/VerifyEmail";

function App() {
  return (
    <Router>
      <AccountContextProvider>
        <AssmtContextProvider>
          <EditAssmtContextProvider>
            <UserContextProvider>
					  <GroupContextProvider>
              <Container>
                <div className="App">
                  <br />
                  <br />
                  <br />
                  <br />
                  <div className="App-body">
                    <Routes>
                      {/* common routes */}
                      <Route path="/" element={<Landing />} />
                      <Route path="/login" element={<Login />} />
                      <Route
                        path="/forgotpassword"
                        element={<ForgotPassword />}
                      />
                      <Route path="/accessDenied" element={<AccessDenied />} />
                      <Route path="/register" element={<Register />} />
                      <Route path="/about-us" element={<AboutUs />} />
                      <Route path="/contact-us" element={<ContactUs />} />

                      {/* admin routes */}
                        <Route path="/admin" element={<Admin />} />
                        <Route
                          path="/admin/questionBank"
                          element={<QuestionBank />}
                        />
                        <Route
                          path="/admin/userManagement"
                          element={<UserManagement />}
                        />
                        <Route
                          path="/admin/userManagement/userResponse"
                          element={<UserResponseList />}
                        />
                        <Route path="/admin/newAssmt" element={<NewAssmt />} />
                        <Route
                          path="/admin/viewAssmt/:id"
                          element={<ViewAssmt />}
                        />
                        <Route
                          path="/admin/viewAllResponse/:id"
                          element={<ViewAllResponse />}
                        />
                        <Route
                          path="/admin/viewUserResponse/:AID/:UAID"
                          element={<ViewUserResponse />}
                        />
						
                        <Route
                          path="/admin/viewGrpAssmtUserResponse/:AGID/:UAID"
                          element={<ViewGrpAssmtUserResp />}
                        />
                        <Route
                          path="admin/editAssmt/:id"
                          element={<EditAssmt />}
                        />

                        {/* Group routes */}
                        <Route
                          path="/admin/group/:GID"
                          element={<ViewGroup />}
                        />

                        <Route
                          path="/admin/newAssmtGroup"
                          element={<NewAssmtGroup />}
                        />
                        <Route
                          path="/admin/viewGrpAssmt/:id"
                          element={<ViewGroupAssmt />}
                        />

                        <Route
                          path="/admin/editGrpAssmt/:id"
                          element={<EditGroupAssmt />}
                        />

                      {/* user routes */}
                      <Route
                        path="/user/dashboard/:UID"
                        element={<Dashboard />}
                      />
                      <Route path="/user/profile/:UID" element={<Profile />} />
                      <Route
                        path="/user/editProfile/:UID"
                        element={<EditProfile />}
                      />
                      <Route
                        path="/user/userAssmt/:UID"
                        element={<UserAssmt />}
                      />
                      <Route
                        path="/user/userResponse/grpAssmt/:UID"
                        element={<UserGrpAssmt />}
                      />
                      <Route
                        path="/user/userResponse/:UID"
                        element={<UserResponse />}
                      />
                      <Route
                        path="/user/confirmation/:UID"
                        element={<Confirmation />}
                      />
                      <Route path="/user/results/:UID" element={<Results />} />
                      <Route
                        path="/user/viewUserResult/:AID/:UID/:UAID"
                        element={<ViewSingleResult />}
                      />
                      <Route
                        path="/user/viewGrpUserResult/:AGID/:UID/:UAID"
                        element={<ViewGrpUserResult />}
						/>
                      <Route
                        path="/analyze/bysection/:AID/:UID"
                        element={<AnalyzeBySection />}
                      />

                      {/* undefined routes */}
                      <Route path="*" element={<PageNotFound />} />

                      {/* route for verifying email address  */}
                      <Route path="verifyEmail" element={<VerifyEmail />} />
                    </Routes>
                  </div>
                </div>
              </Container>
						</GroupContextProvider>
            </UserContextProvider>
          </EditAssmtContextProvider>
        </AssmtContextProvider>
      </AccountContextProvider>
    </Router>
  );
}

export default App;
