export const isNFCComplete = (NFCData) => {
  for (let i = 0; i < NFCData.length; i++)
    if (NFCData[i]["startWeight"] === "" || NFCData[i]["eqWeight"] === "") {
      return false;
    }
  return true;
};

export const validateNFCOverlapHelper = (list) => {
  const NFCList = list.map((obj) => {
    const startWeight = typeof obj.startWeight === "string" ? Number(obj.startWeight) : obj.startWeight;
    let endWeight = startWeight;
    if (obj.endWeight) {
      if (typeof obj.endWeight === "string" && obj.endWeight.trim().length > 0) endWeight = Number(obj.endWeight);
      else if (typeof obj.endWeight === "number") endWeight = obj.endWeight;
    }
    const eqWeight = typeof obj.eqWeight === "string" ? Number(obj.eqWeight) : obj.eqWeight;
    return {
      startWeight,
      endWeight,
      eqWeight,
    };
  });

  NFCList.sort((a, b) => a.startWeight - b.startWeight);

  let prevEndWeight = -Infinity;
  const eqWeights = new Set();

  for (let i = 0; i < NFCList.length; i++) {
    const obj = NFCList[i];
    if (obj.startWeight < prevEndWeight) {
      return [false, `Overlapping ranges found in line ${i + 1}`];
    }

    if (eqWeights.has(obj.eqWeight)) {
      return [false, `Equivalent values must be unique. Duplicate found in line ${i + 1}`];
    }

    prevEndWeight = obj.endWeight;
    eqWeights.add(obj.eqWeight);
  }

  return [true, ""];
};

const generateRandomRGBColor = () => {
  var r = Math.floor(Math.random() * 256);
  var g = Math.floor(Math.random() * 256);
  var b = Math.floor(Math.random() * 256);
  return "rgba(" + r + ", " + g + ", " + b + ", 0.3)";
};

export const generateRGBColors = (n) => {
  var colors = [];
  for (var i = 0; i < n; i++) {
    colors.push(generateRandomRGBColor());
  }
  return colors;
};
