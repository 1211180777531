import React, { useContext,useEffect } from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import logo from "../image/Helix-logo.png";
import { useNavigate } from "react-router-dom";
import { AccountContext } from "./Contexts/AccountContext";
import { AssmtContext } from "./Contexts/AssmtContext";
import { GiDna1 } from "react-icons/gi";
import { useMediaQuery } from "react-responsive";
import menu from "../image/menu.svg";

const Navigate = () => {
	const { logout, loggedInUser } = useContext(AccountContext);
	const {
		setToast,
		setMessage,
		setStatus,
	} = useContext(AssmtContext);

	const navigate = useNavigate();
	const isMobile = useMediaQuery({ maxWidth: 767 });
    const isLandscapeMobile = useMediaQuery({ query: '(orientation: landscape) and (max-height: 431px' });

	return (
		<div>
			<Navbar fixed="top" className="navbar">
				<Container>
					{ loggedInUser.group === "User" ? (
						<Navbar.Brand href={`/user/dashboard/${loggedInUser.UID}`}>
							<img
								alt=""
								src={logo}
								width="auto"
								height="40"
								className="d-inline-block align-top"
							/>
						</Navbar.Brand>
					): loggedInUser.group === "Admin" ? (
						<Navbar.Brand href="/admin">
							<img
								alt=""
								src={logo}
								width="auto"
								height="40"
								className="d-inline-block align-top"
							/>
						</Navbar.Brand>
					):(
						<></>
					)}
					{isMobile || isLandscapeMobile? (
						<NavDropdown
							title={<img src={menu} alt="Menu" />}
							id="basic-nav-dropdown"
							align="end"
							style={{ marginRight: "-5px" }}
							className="custom-dropdown"
						>
							{loggedInUser.group === "User" ? (
								<>
									<NavDropdown.Item href={`/user/dashboard/${loggedInUser.UID}`}>Dashboard</NavDropdown.Item>
									<NavDropdown.Divider />
									<NavDropdown.Item href={`/user/profile/${loggedInUser.UID}`}>Profile</NavDropdown.Item>
									<NavDropdown.Divider />
									<NavDropdown.Item href="/about-us">About Us</NavDropdown.Item>
									<NavDropdown.Divider />
									<NavDropdown.Item href="/contact-us">Contact Us</NavDropdown.Item>
									<NavDropdown.Divider />
									<NavDropdown.Item onClick={() => {
										logout();
										setMessage("Logged out successfully!");
										setStatus(true);
										setToast(true);
										navigate("/");
									}}>Logout</NavDropdown.Item>
								</>
							): loggedInUser.group === "Admin" ? (
								<>
									<NavDropdown.Item href="/admin">Admin</NavDropdown.Item>
									<NavDropdown.Divider />
									<NavDropdown.Item href="/admin/userManagement">User Management</NavDropdown.Item>
									<NavDropdown.Divider />
									<NavDropdown.Item onClick={() => {
										logout();
										setMessage("Logged out successfully!");
										setStatus(true);
										setToast(true);
										navigate("/");
									}}>Logout</NavDropdown.Item>
								</>
							):(
								<></>
							)}
						</NavDropdown>
					// ): isLandscapeMobile ? (
					): (
						<Nav className="justify-content-end">
							{/* <Nav.Link href="/">Home</Nav.Link> */}
							{loggedInUser.group === "User" ? (
								<>
									<Nav.Link
										href={`/user/dashboard/${loggedInUser.UID}`}
									>
										Dashboard
									</Nav.Link>
									<Nav.Link
										href={`/user/profile/${loggedInUser.UID}`}
									>
										Profile
									</Nav.Link>
									<Nav.Link href="/about-us">About Us</Nav.Link>
									<Nav.Link href="/contact-us">
										Contact Us
									</Nav.Link>
									<Nav.Link
										onClick={() => {
											logout();
											setMessage("Logged out successfully!");
											setStatus(true);
											setToast(true);
											navigate("/");
										}}
									>
										Logout
									</Nav.Link>
								</>
							) : loggedInUser.group === "Admin" ? (
								<>
									<Nav.Link href="/admin">Admin</Nav.Link>
									<Nav.Link href="/admin/userManagement">
										User Management
									</Nav.Link>
									<Nav.Link
										onClick={() => {
											logout();
											setMessage("Logged out successfully!");
											setStatus(true);
											setToast(true);
											navigate("/");
										}}
									>
										Logout
									</Nav.Link>
								</>
							) : (
								<></>
							)}
						</Nav>
					)}
				</Container>
			</Navbar>
		</div>
	);
};

export default React.memo(Navigate);
