import React, { useEffect, useRef, useContext } from "react";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import { useNavigate, useLocation } from "react-router-dom";
import { AssmtContext } from "../../Contexts/AssmtContext";

const UserResponseListTable = (props) => {
  $.DataTable = require("datatables.net");
  const tableRef = useRef();
  const navigate = useNavigate();
  let userObj = props.userObj;

  const { exportSingleResToCSV } = useContext(AssmtContext);

  const setCSVfilename = props.setCSVfilename;
  const setHeaderName = props.setHeaderName;
  const setUserRes = props.setUserRes;
  const handleShow1 = props.handleShow1;
  const setBodyName = props.setBodyName;
  const setAssmtToDelete = props.setAssmtToDelete;
  const setUAIDtoDelete = props.setUAIDtoDelete;
  const handleShow2 = props.handleShow2;

  useEffect(() => {
    const table = $(tableRef.current).DataTable({
      data: props.userResponseList,
      columns: [
        {
          className: "dt-body-left",
          width: "20%",
          title: "Assessment",
          data: "name",
        },
        { className: "dt-body-left", title: "Title", data: "positionTitle" },
        {
          className: "dt-body-left",
          title: "Position",
          data: "positionOption",
        },
        {
          className: "dt-body-left",
          width: "15%",
          title: "Reference Date",
          data: "referenceDate",
        },
        {
          className: "dt-body-left",
          title: "Start Time",
          data: "start_Tstamp",
        },
        { className: "dt-body-left", title: "End Time", data: "end_Tstamp" },
        {
          className: "dt[-head|-body]-center",
          title: "Export",
          defaultContent: `<Button class="csvbtn btn btn-outline-secondary btn-sm">
                CSV
              </Button>`,
        },
        {
          className: "dt[-head|-body]-center",
          title: "Delete",
          defaultContent: `<Button class="delbtn btn btn-outline-danger btn-sm">
                Delete
              </Button>`,
        },
      ],
      destroy: true,
    });
 
    $("#example3 tbody").on("click", ".csvbtn", async function (e) {
      e.stopPropagation();
      const data = table.row($(this).parents("tr")).data();
      if (data !== undefined) {
        let exportData = await exportSingleResToCSV(
          data.UID,
          data.UAID,
          data.AID
        );
        setUserRes(exportData);
        setHeaderName(data.name);
        setBodyName(
          `${data.first_name} ${data.last_name}'s response is generated as .csv file and ready to download!`
        );
        setCSVfilename(
			`${data.UID} ${data.UAID} ${data.first_name} ${
				data.last_name
			} ${data.end_Tstamp}.csv`
		);
        handleShow1();
      }
    });
    $("#example3 tbody").on("click", ".delbtn", function (e) {
      e.stopPropagation();
      const data = table.row($(this).parents("tr")).data();
      if (data !== undefined) {
        setAssmtToDelete(data.name);
        setUAIDtoDelete(data.UAID);
        handleShow2();
      }
    });

    $("#example3 tbody").on("click", "tr", function () {
      const data = table.row($(this)).data();
      if (data !== undefined) {
        navigate(`/admin/viewUserResponse/${data.AID}/${data.UAID}`, {
          state: { userAssmtObj: { ...userObj, ...data } },
        });
      }
    });

    return function () {
      table.destroy();
    };
  }, [props.userResponseList]);

  return (
    <div className="user-table">
      <table
        className="hover dt[-head|-body]-left cell-border"
        width="100%"
        id="example3"
        ref={tableRef}
      >
        <thead>
          <tr>
            <th>Assessment</th>
            <th>Title</th>
            <th>Position</th>
            <th>Reference Date</th>
            <th>Start Time</th>
            <th>End Time</th>
            <th style={{textAlign: "center"}}>Export</th>
            <th style={{textAlign: "center"}}>Delete</th>
          </tr>
        </thead>
      </table>
    </div>
  );
};

export default UserResponseListTable;
