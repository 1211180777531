import React from "react";
import { Accordion, Badge, Form, Stack, Table } from "react-bootstrap";

const PreviewGroupAssmt = ({ sectionList }) => {
  return (
    <>
      {sectionList.map((singleSec, sidx) => (
        <div key={sidx}>
          <Accordion alwaysOpen>
            <Accordion.Item>
              <Accordion.Header>Section #{sidx + 1}</Accordion.Header>
              <Accordion.Body>
                <Stack>
                  <label>Name: {singleSec.sName}</label>
                  <label>Description: {singleSec.sDesc}</label>
                  <br />
                  <label>Questions: </label>
                </Stack>
                <ol>
                  {singleSec.qIDs.map((singleQue, qidx) => (
                    <li key={qidx}>
                      <div className="view-question-body" key={qidx}>
                        <div className="d-flex justify-content-between">
                          <label>{singleQue.qQuestion}</label>
                          <Badge bg="secondary">Type: {singleQue.qType}</Badge>
                        </div>
                        <div>
                          {singleQue.qType === "Multiple" ? (
                            <div className="mult-opt">
                              {singleQue.cIDs.map((c, ccid) => (
                                <Form.Check
                                  key={ccid}
                                  type="checkbox"
                                  id={c.ccid}
                                  label={c.cName}
                                />
                              ))}
                            </div>
                          ) : singleQue.qType === "Inventory" ? (
                            <Table borderless>
                              <tbody>
                                <tr className="text-muted">
                                  <td></td>
                                  <td>"A Novice"</td>
                                  <td>"A Practitioner"</td>
                                  <td>"An Expert"</td>
                                  <td>"A Leader"</td>
                                </tr>
                                {singleQue.cIDs.map((c, ccid) => (
                                  <tr key={ccid}>
                                    <td className="text-muted">{c.cName}</td>
                                    <td>
                                      <Form.Check name={ccid} type="radio" />
                                    </td>
                                    <td>
                                      <Form.Check name={ccid} type="radio" />
                                    </td>
                                    <td>
                                      <Form.Check name={ccid} type="radio" />
                                    </td>
                                    <td>
                                      <Form.Check name={ccid} type="radio" />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          ) : singleQue.qType === "Likert" ? (
                            <Table borderless>
                              <tbody>
                                <tr>
                                  <td></td>
                                  <td>"Not At All Like Me"</td>
                                  <td>"Not Very Like Me"</td>
                                  <td>"A Little Like Me"</td>
                                  <td>"Somewhat Like Me"</td>
                                  <td>"Very Like Me"</td>
                                </tr>
                                {singleQue.cIDs.map((c, ccid) => (
                                  <tr key={ccid}>
                                    <td>{c.cName}</td>
                                    <td>
                                      <Form.Check name={ccid} type="radio" />
                                    </td>
                                    <td>
                                      <Form.Check name={ccid} type="radio" />
                                    </td>
                                    <td>
                                      <Form.Check name={ccid} type="radio" />
                                    </td>
                                    <td>
                                      <Form.Check name={ccid} type="radio" />
                                    </td>
                                    <td>
                                      <Form.Check name={ccid} type="radio" />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          ) : singleQue.qType === "Likert-6" ? (
                            <Table borderless>
                              <tbody>
                                <tr>
                                  <td></td>
                                  <td>"Strongly disagree"</td>
                                  <td>"Moderately disagree"</td>
                                  <td>"Slightly disagree"</td>
                                  <td>"Slightly agree"</td>
                                  <td>"Moderately agree"</td>
                                  <td>"Strongly agree</td>
                                </tr>
                                {singleQue.cIDs.map((c, ccid) => (
                                  <tr key={ccid}>
                                    <td>{c.cName}</td>
                                    <td>
                                      <Form.Check name={ccid} type="radio" />
                                    </td>
                                    <td>
                                      <Form.Check name={ccid} type="radio" />
                                    </td>
                                    <td>
                                      <Form.Check name={ccid} type="radio" />
                                    </td>
                                    <td>
                                      <Form.Check name={ccid} type="radio" />
                                    </td>
                                    <td>
                                      <Form.Check name={ccid} type="radio" />
                                    </td>
                                    <td>
                                      <Form.Check name={ccid} type="radio" />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          ) : singleQue.qType === "Pick One" ? (
                            <div className="mult-opt">
                              <Form>
                                {singleQue.cIDs.map((c, ccid) => (
                                  <Form.Check
                                    name="group1"
                                    key={ccid}
                                    type="radio"
                                    id={c.ccid}
                                    label={c.cName}
                                  />
                                ))}
                              </Form>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <br />
                    </li>
                  ))}
                </ol>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <br />
        </div>
      ))}
    </>
  );
};

export default PreviewGroupAssmt;
