import React from "react";
import { Form, Stack, Button } from "react-bootstrap";
import { FaMinus } from "react-icons/fa";

const ModalListSet = (props) => {
  let editing = props.editing;
  return (
    <div className="c-main">
        <Stack direction="horizontal" gap={2}>
            <Form.Control
            disabled={editing}
            className="c-text"
            type="number"
            placeholder="Start"
            name="startWeight"
            value={props.item.startWeight}
            onBlur={(e) => {
                props.validateOverlap();
            }}
            onChange={(e) => {
                props.handleChange(e, props.idx);
            }}
            />
            <div style={{height: "100%"}}>
                <p style={{margin: "auto"}}><b>-</b></p>
            </div>
            <Form.Control
            disabled={editing}
            className="c-text"
            type="number"
            placeholder="End"
            name="endWeight"
            value={props.item.endWeight === -1000 ? "" : props.item.endWeight}
            onBlur={(e) => {
                props.validateOverlap();
            }}
            onChange={(e) => {
                props.handleChange(e, props.idx);
            }}
            />
            <div style={{height: "100%"}}>
                <p style={{margin: "auto"}}><b>=</b></p>
            </div>
            <Form.Control
            disabled={editing}
            className="c-text"
            type="text"
            placeholder="Equivalent"
            name="eqWeight"
            value={props.item.eqWeight}
            onBlur={(e) => {
                props.validateOverlap();
            }}
            onChange={(e) => {
                props.handleChange(e, props.idx);
            }}
            />
            <div className="vr" />
            <Button
            disabled={editing}
            className="sec-x-btn"
            variant="outline-light"
            onClick={() => {
                props.handleRemove(props.idx);
            }}
            >
                <FaMinus style={{ color: "#BD4B4B", fontSize: "15px" }} />
            </Button>
        </Stack>
        <br></br>
    </div>
  );
};

export default ModalListSet;
