import React from "react";
import { Form, Table } from "react-bootstrap";

const ViewLikert6Result = (props) => {
	let q = props.q;
	let qQID = q.QID;
	let UAID = props.UAID;
	let allResponse = props.allResponse;
	let isMobile = props.isMobile;
	let isLandscapeMobile = props.isLandscapeMobile;

	return (
		<>
		{isMobile ? (
			<Form>
				{q.choices.map((c, ccid) => (
					<Form.Group key={ccid} controlId={c.CID}>
						<Form.Label style={{marginBottom: '0.5rem'}}>{c.choice}</Form.Label>
						<Form.Select
							value={allResponse.find(
								(i) =>
									i.CID === c.CID &&
									i.QID === c.QID &&
									i.SecID === c.SecID &&
									i.AID === c.AID &&
									i.UAID === UAID
							)?.response || ""
							}
							style={{marginBottom: '1rem'}}
							disabled
						>
							<option value="1">Strongly disagree</option>
							<option value="2">Moderately disagree</option>
							<option value="3">Slightly disagree</option>
							<option value="4">Slightly agree</option>
							<option value="5">Moderately agree</option>
							<option value="6">Strongly agree</option>
						</Form.Select>
					</Form.Group>
				))}
			</Form>
		) : (
			<Table borderless>
				<tbody>
					<tr>
						<td></td>
						<td>"Strongly disagree"</td>
						<td>"Moderately disagree"</td>
						<td>"Slightly disagree"</td>
						<td>"Slightly agree"</td>
						<td>"Moderately agree"</td>
						<td>"Strongly agree</td>
					</tr>
					{q.choices.map((c, ccid) => (
						<tr key={ccid}>
							<td>{c.choice}</td>
							<td>
								<Form.Check
									value="1"
									type="radio"
									name={c.CID}
									id={c.CID}
									disabled
									checked={allResponse.find(
										(i) =>
											i.response === 1 &&
											i.CID === c.CID &&
											i.QID === c.QID &&
											i.SecID === c.SecID &&
											i.AID === c.AID &&
											i.UAID === UAID
									)}
								/>
							</td>
							<td>
								<Form.Check
									value="2"
									type="radio"
									name={c.CID}
									id={c.CID}
									disabled
									checked={allResponse.find(
										(i) =>
											i.response === 2 &&
											i.CID === c.CID &&
											i.QID === c.QID &&
											i.SecID === c.SecID &&
											i.AID === c.AID &&
											i.UAID === UAID
									)}
								/>
							</td>
							<td>
								<Form.Check
									value="3"
									type="radio"
									name={c.CID}
									id={c.CID}
									disabled
									checked={allResponse.find(
										(i) =>
											i.response === 3 &&
											i.CID === c.CID &&
											i.QID === c.QID &&
											i.SecID === c.SecID &&
											i.AID === c.AID &&
											i.UAID === UAID
									)}
								/>
							</td>
							<td>
								<Form.Check
									value="4"
									type="radio"
									name={c.CID}
									id={c.CID}
									disabled
									checked={allResponse.find(
										(i) =>
											i.response === 4 &&
											i.CID === c.CID &&
											i.QID === c.QID &&
											i.SecID === c.SecID &&
											i.AID === c.AID &&
											i.UAID === UAID
									)}
								/>
							</td>
							<td>
								<Form.Check
									value="5"
									type="radio"
									name={c.CID}
									id={c.CID}
									disabled
									checked={allResponse.find(
										(i) =>
											i.response === 5 &&
											i.CID === c.CID &&
											i.QID === c.QID &&
											i.SecID === c.SecID &&
											i.AID === c.AID &&
											i.UAID === UAID
									)}
								/>
							</td>
							<td>
								<Form.Check
									value="6"
									type="radio"
									name={c.CID}
									id={c.CID}
									disabled
									checked={allResponse.find(
										(i) =>
											i.response === 6 &&
											i.CID === c.CID &&
											i.QID === c.QID &&
											i.SecID === c.SecID &&
											i.AID === c.AID &&
											i.UAID === UAID
									)}
								/>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		)}
		</>
	);
};

export default ViewLikert6Result;
