import React, { createContext, useState, useEffect, useContext } from "react";
import { AccountContext } from "./AccountContext";



export const GroupContext = createContext();

const GroupContextProvider = ({ children }) => {
    const [groupList,setGroupList] = useState([]);
    const {apiCall,loggedInUser} = useContext(AccountContext);
	
    const fetchGroupList = async () => {
        try{
          const resp = await apiCall("admin/getGroups");
          if(resp.data.error){
            throw resp.data.error;
          }
          setGroupList(resp.data.data);
          return;
        }catch(err){
           console.log("Error in fetching groups : ",err);
           return;
        }
    }

    useEffect(()=>{
       fetchGroupList();
    },[loggedInUser]);

	return (
		<GroupContext.Provider
			value={{
				groupList,
                setGroupList
			}}
		>
			{children}
		</GroupContext.Provider>
	);
};

export default GroupContextProvider;
