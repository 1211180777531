import React, { useContext, useEffect, useState } from "react";
import { Form, Button, Stack, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../Contexts/UserContext";
import Navigate from "../../Navigate";
import ToastMessage from "../../ToastMessage";
import { AssmtContext } from "../../Contexts/AssmtContext";
import axios from "axios";
import { getBaseUrl } from "../../../base";
import { AccountContext } from "../../Contexts/AccountContext";
import AccessDenied from "../../AccessDenied";

const UserGrpAssmt = () => {
    const { loginStatus, loggedInUser, apiCall } = useContext(AccountContext);

	const { toast, setToast, message, setMessage, status, setStatus } = useContext(AssmtContext);

    const {
		AssmtID,
		setAssmtID,
        assmtLeft,
        setAssmtLeft,
        grpAssmtDetails,
        fetchIndividualAssmt,
        setFetchIndividualAssmt,
        setTotalAssmt,
        grpAssmtID,
        getIncompleteGrpAssmt,
        UserAssmtID,
        grpSecAttmpted,
        setStep,
	} = useContext(UserContext);

    const navigate = useNavigate();

    const [grpAssmtList, setGrpAssmtList] = useState([]);

    useEffect(() => {
        const getAssmtDetails = async () => {
            const { data } = await apiCall(`admin/getGrpAssmtByID/${grpAssmtID}`);
            setGrpAssmtList(data);
            setTotalAssmt(data.length);
        };
        getAssmtDetails();
    }, [])

    useEffect(()=>{
        getIncompleteGrpAssmt(UserAssmtID, grpAssmtID);
    },[UserAssmtID, grpAssmtID]);

    return(
        <div>
            {loginStatus === true && loggedInUser.group === "User" ? (
				<>
                <Navigate />
					<ToastMessage
                    toast={toast}
                    onClose={() => setToast(!toast)}
                    message={message}
                    status={status}
					/>
					<Stack direction="horizontal" gap={3}>
						<label>Attempt assessments</label>
					</Stack>
					<br />

					<div className="newAssmt">
						<div className="w-25">
							<Form>
								<Form.Group className="mb-3">
									<Form.Control
                                    as={"textarea"}
                                    disabled={true}
                                    value={grpAssmtDetails["name"]}
									/>
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Control
                                    as="textarea"
                                    disabled={true}
                                    value={grpAssmtDetails["description"]}
                                    rows={5}
									/>
								</Form.Group>
							</Form>
							<br />
							<br />
						</div>
						<div className="assmt-b">
							{grpAssmtList.map((singleAssmt, aIdx) => (
								<div key={aIdx}>
									<Card key={singleAssmt.AID}>
                                        <Card.Body>
                                            <Stack
                                            className="assmt-card-list"
                                            direction="horizontal"
                                            >
                                                <div className="assmt-details">
                                                    <Card.Text>{singleAssmt.name}</Card.Text>
                                                </div>
                                                <div className="assmt-options">
                                                    {[...assmtLeft].includes(singleAssmt.AID) ?
                                                        <>
                                                        <Button
                                                        variant="success"
                                                        >
                                                            Completed
                                                        </Button>
                                                        </> : 
                                                        <>
                                                            <Button
                                                            variant="outline-secondary"
                                                            onClick={() => {
                                                                navigate(`/user/userResponse/${loggedInUser.UID}`);
                                                                setAssmtID(singleAssmt["AID"]);
                                                                setFetchIndividualAssmt(true);
                                                                const matching = grpSecAttmpted.find(obj => obj.hasOwnProperty(singleAssmt["AID"]));
                                                                setStep(matching[singleAssmt["AID"]]? matching[singleAssmt["AID"]] : 0);
                                                            }}
                                                            >
                                                                Attempt
                                                            </Button>
                                                        </>
                                                    }
                                                </div>
                                            </Stack>
                                        </Card.Body>
                                    </Card>
									<br />
								</div>
							))}
						</div>
					</div>
                </>
			) : (
				<AccessDenied />
			)}
        </div>
    );
};

export default UserGrpAssmt;
