import React, { useEffect, useRef, useContext } from "react";
import $, { get } from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import { useNavigate } from "react-router-dom";
import { AssmtContext } from "../../Contexts/AssmtContext";
import { AccountContext } from "../../Contexts/AccountContext";
import { useMediaQuery } from "react-responsive";


const ResultTable = (props) => {
	$.DataTable = require("datatables.net");
	const tableRef = useRef();
	const navigate = useNavigate();

	const { exportSingleResToCSV, setAssmtType } = useContext(AssmtContext);
	const { apiCall } = useContext(AccountContext);

	const setCSVfilename = props.setCSVfilename;
	const setHeaderName = props.setHeaderName;
	const setUserRes = props.setUserRes;
	const handleShow1 = props.handleShow1;
	const setBodyName = props.setBodyName;
	const setAssmtToDelete = props.setAssmtToDelete;
	const setUAIDtoDelete = props.setUAIDtoDelete;
	const handleShow2 = props.handleShow2;
	const isMobile = useMediaQuery({ maxWidth: 737 });
	const isLandscapeMobile = useMediaQuery({ query: '(orientation: landscape) and (max-height: 431px' });

	async function getData(userData) {
		const { data } = await apiCall(`admin/getAssmtType/${userData.UAID}`);
		localStorage.setItem('secAssmtType', data["type"]);
		if(data["type"] === "individual") navigate(`/user/viewUserResult/${userData.AID}/${userData.UID}/${userData.UAID}`);
		else if(data["type"] === "group") navigate(`/user/viewGrpUserResult/${userData.AID}/${userData.UID}/${userData.UAID}`);
	}

	async function getAnalysis(userData) {
		const { data } = await apiCall(`admin/getAssmtType/${userData.UAID}`);
		localStorage.setItem('secAssmtType', data["type"]);
		setAssmtType(data["type"]);
		if(data["type"] === "individual"){ 
			navigate(`/analyze/bysection/${userData.AID}/${userData.UID}`, { state: {UAID: userData.UAID}});
		}
		else{ 
			if(data["type"] === "group"){ 
				localStorage.setItem("AGID", userData.AID); 
				navigate(`/analyze/bysection/${userData.AID}/${userData.UID}`, { state: {UAID: userData.UAID}});
			}
		}
	}

	useEffect(() => {
		if(localStorage.getItem('secAssmtType') !== null) {
			localStorage.removeItem('secAssmtType');
		}
		if(localStorage.getItem('AGID') !== null) {
			localStorage.removeItem('AGID');
		}
	}, [])

	useEffect(() => {
		const table = $(tableRef.current).DataTable({
			data: props.resultList,
			scrollX:true,
			order: [[5,'desc'],[4,'asc']],
			columns: [
				{
					className: "dt-body-left",
					width: "20%",
					title: "Assessment",
					data: "name",
				},
				{
					className: "dt-body-left",
					title: "Title",
					data: "positionTitle",
				},
				{
					className: "dt-body-left",
					title: "Position",
					data: "positionOption",
				},
				{
					className: "dt-body-left",
					width: "15%",
					title: "Reference Date",
					data: "referenceDate",
				},
				{
					className: "dt-body-left",
					title: "Start Time",
					data: "start_Tstamp",
				},
				{
					className: "dt-body-left",
					title: "End Time",
					data: "end_Tstamp",
				},
				{
					className: "dt[-head|-body]-center",
					title: "Analysis",
					defaultContent: `<Button class="analysis-view-btn btn btn-outline-secondary btn-sm">
						View
					  </Button>`,
				},
				{
					className: "dt[-head|-body]-center",
					title: "Export",
					defaultContent: `<Button class="csvbtn btn btn-outline-secondary btn-sm">
                    CSV
                </Button>`,
				},
				{
					className: "dt[-head|-body]-center",
					title: "Delete",
					defaultContent: `<Button class="del-btn btn btn-outline-danger btn-sm">
                    Delete
                </Button>`,
				},
			],
			destroy: true,
			responsive: !isMobile,
			createdRow: function (row, data) {
				if (isMobile) {
					$(row).html(`
					  <tr>
						<td><span class="cell-header">Assessment:</span> ${data.name}</td>
						<td><span class="cell-header">Title:</span> ${data.positionTitle}</td>
						<td><span class="cell-header">Position:</span> ${data.positionOption}</td>
						<td><span class="cell-header">Reference Date:</span> ${data.referenceDate}</td>
						<td><span class="cell-header">Start Time:</span> ${data.start_Tstamp}</td>
						<td><span class="cell-header">End Time:</span> ${data.end_Tstamp}</td>
						<td>
						  <div class="action-buttons">
							<Button class="view-btn btn btn-outline-secondary btn-sm" style=" margin-right: 4.66%; width: 20%;">View</Button>
							<Button class="analysis-view-btn btn btn-outline-secondary btn-sm" style=" margin-right: 4.67%; width: 25%;">Analysis</Button>
							<Button class="csvbtn btn btn-outline-secondary btn-sm" style=" margin-right: 4.67%; width: 18%;">CSV</Button>
							<Button class="del-btn btn btn-outline-danger btn-sm" style=" width: 23%;">Delete</Button>
						  </div>
						</td>
					  </tr>
					`);
				}
				if(isLandscapeMobile){
					$(row).html(`
					  <tr>
						<td><span class="cell-header">Assessment:</span> ${data.name}</td>
						<td><span class="cell-header">Title:</span> ${data.positionTitle}&nbsp;&nbsp;&nbsp; <span class="cell-header">Position:</span> ${data.positionOption}</td>
						<td><span class="cell-header">Reference Date:</span> ${data.referenceDate}</td>
						<td><span class="cell-header">Start Time:</span> ${data.start_Tstamp}</td>
						<td><span class="cell-header">End Time:</span> ${data.end_Tstamp}</td>
						<td>
						  <div class="action-buttons">
							<Button class="view-btn btn btn-outline-secondary btn-sm" style=" margin-right: 6.66%; width: 20%;">View</Button>
							<Button class="analysis-view-btn btn btn-outline-secondary btn-sm" style=" margin-right: 6.67%; width: 20%;">Analysis</Button>
							<Button class="csvbtn btn btn-outline-secondary btn-sm" style=" margin-right: 6.67%; width: 20%;">CSV</Button>
							<Button class="del-btn btn btn-outline-danger btn-sm" style=" width: 20%;">Delete</Button>
						  </div>
						</td>
					  </tr>
					`);
				}
			},
		});
		$("#example2 tbody").on("click", ".analysis-view-btn", function (e) {
			e.stopPropagation();
			const data = table.row($(this).parents("tr")).data();
			if (data !== undefined) {
			//   let userAssmtObj = {}
			//   userAssmtObj["first_name"] = data["first_name"];
			//   userAssmtObj["last_name"] = data["last_name"];
			//   navigate(`/analyze/bysection/${data.AID}/${data.UID}`, { state: {UAID: data.UAID}});
				getAnalysis(data);
			}
		  });
		$("#example2 tbody").on("click", ".csvbtn", async function (e) {
			e.stopPropagation();
			const data = table.row($(this).parents("tr")).data();
			if (data !== undefined) {
				let exportData = await exportSingleResToCSV(
					data.UID,
					data.UAID,
					data.AID
				);
				setUserRes(exportData);
				setHeaderName(`${data.first_name} ${data.last_name}`);
				setBodyName(
					`Response for "${data.first_name} ${data.last_name}" is generated as .csv file and ready to download!`
				);
				setCSVfilename(
					`${data.UID} ${data.first_name} ${
						data.last_name
					} submitted on ${new Date(
						data.end_Tstamp
					).toLocaleDateString()}.csv`
				);
				handleShow1();
			}
		});
		$("#example2 tbody").on("click", ".del-btn", function (e) {
			e.stopPropagation();
			const data = table.row($(this).parents("tr")).data();
			if (data !== undefined) {
			  setAssmtToDelete(data.name);
			  setUAIDtoDelete(data.UAID);
			  handleShow2();
			}
		  });
		  $("#example2 tbody").on("click", "tr", function () {
			const data = table.row($(this)).data();
			if (data !== undefined) {
				getData(data);
			}
		});
		return function () {
			table.destroy();
		};
	}, [props.resultList, isMobile, isLandscapeMobile]);

	return (
		<div className="user-table" style={{marginBottom:"2rem"}}>
			<table
				className="hover dt[-head|-body]-left cell-border"
				width="100%"
				id="example2"
				ref={tableRef}
			>
				<thead>
					<tr>
						<th>Assessment</th>
						<th>Title</th>
						<th>Position</th>
						<th>Reference Date</th>
						<th>Start Time</th>
						<th>End Time</th>
						<th>Analysis</th>
						<th>Export</th>
						<th>Delete</th>
					</tr>
				</thead>
			</table>
		</div>
	);
};

export default ResultTable;
