import React, { useEffect, useRef, useState, useContext } from "react";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import { AssmtContext } from "../../Contexts/AssmtContext";
import { Button,Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const GroupListTable = (props) => {
  const { setToast, setMessage, setStatus } =
    useContext(AssmtContext);
  const [groupList,setGroupList] = useState([]);
  const [show,setShow] = useState(false);
  const [group,setGroup] = useState({
    GID:'',
    g_name:'',
    g_code:'',
    g_desc:'',
    isDeleting: false,
    isEditing: false
  });
  const [deleteInput, setDeleteInput] = useState("");
  const navigate = useNavigate();
  
  //for keeping a track while editing info of a group
  const [edit_Group,setEditGroup] = useState({
    g_name:'',
    g_code:'',
    g_desc:'',
    GID:''
  });

  $.DataTable = require("datatables.net");
  const tableRef = useRef();
  const apiCall = props.apiCall;

  const handleClose = () => {
    setShow(false);
    setGroup({
      GID:'',
      g_name:'',
      g_code:'',
      g_desc:'',
      isDeleting: false,
      isEditing: false
     })
  }


  const fetchGroupList = async () => {
    try{
      const {data} = await apiCall("admin/getGroups");
      if(data.error){
        throw data.error;
      }
      setGroupList(data.data);
      return;
    }catch(err){
       console.log("Error in fetching groups : ",err);
       setMessage('Sorry, something went wrong!');
       setToast(true);
       return;
    }
  }

  const createGroup = async ()=> {
     try{
       if(!group.g_name.trim() || (!group.g_code.trim())){
        setMessage('Fill in the required details!');
        setToast(true);
        setStatus(false);
        return;
       }

       const response = await apiCall("admin/createGroup", "POST", group);
       console.log("RESPONSE : ",response);

       if(response.response?.data?.error){
        setMessage(response.response?.data?.error);
        setToast(true);
        setStatus(false);
        return;
       }
      
       setMessage(response.data?.message);
       setToast(true);
       setStatus(true);

       //update the array
       let grp = {...group};
       grp['GID'] = response.data?.GID;
       setGroupList((list)=> {
        return [...list,grp];
       })

       handleClose();         
     }catch(err){
      console.log("Error in creating groups: ",err);
      setMessage('Sorry, something went wrong!');
      setToast(true);
      setShow(false);
     }
  }

  const deleteGroup = async ()=> {
    try{
      const response = await apiCall(`admin/deleteGroup/${group.GID}`, "DELETE");

      if(response.response?.data?.error){
        setMessage(response.response?.data?.error);
        setToast(true);
        setStatus(false);
        return;
      }
     
      setMessage(response.data?.message);
      setToast(true);
      setStatus(true);

      //update the array
      setGroupList((list)=> {
       return list.filter((ele)=> {
        console.log("ELE : ",ele.GID);
        console.log("GRP : ",group.GID)
        return ele.GID !== group.GID
       })
      })
    }catch(err){
     console.log("Error in creating groups: ",err);
     setMessage('Sorry, something went wrong!');
     setToast(true);
    }finally{
      handleClose();
    }
 }

 const editGroup = async () => {
  try{
    if(!edit_Group.g_name.trim() || (!edit_Group.g_code.trim())){
     setMessage('Fill in the required details!');
     setToast(true);
     setStatus(false);
     return;
    }

    const response = await apiCall(`admin/editGroup/${group.GID}`, "PUT", edit_Group);

    if(response.response?.data?.error){
      setMessage(response.response?.data?.error);
      setToast(true);
      setStatus(false);
      return;
    }
   
    setMessage(response.data?.message);
    setToast(true);
    setStatus(true);

    //update the array
    setGroupList((list)=> {
     return list.map((e)=> {
      if(e.GID === edit_Group.GID){
        return {
          GID: edit_Group.GID,
          g_name: edit_Group.g_name,
          g_code: edit_Group.g_code,
          g_desc: edit_Group.g_desc,
          isDeleting: false,
          isEditing: false
        }
      }

      return e;
     });
    })

   handleClose();

  }catch(err){
   console.log("Error in creating groups: ",err);
   setMessage(err);
   setToast(true);
   setShow(false);
  }
 }

  useEffect(()=> {
     fetchGroupList();
  },[]);

  useEffect(() => {
    const table = $(tableRef.current).DataTable({
      data: groupList,
      columns: [
        {
          className: "dt-body-left",
          title: "Group Name",
          data: "g_name",
        },
        {
          className: "dt-body-left",
          title: "Group Description",
          data: "g_desc",
          render: function(data){
            if(data.length > 30){
              return data.substring(0,30)+"...."
            }

            return data.length === 0 ? '----' : data;
          }
        },
        {
          className: "dt-body-left",
          title: "Group Code",
          data: "g_code",
          render: function(data){
            return data ? data : '----'
          }
        },
        {
          className: "dt-body-left",
          title: "Action",
          defaultContent: `<Button class="view-btn btn btn-outline-secondary btn-sm">
                View
              </Button>`,
        },
        //conditional disabling for Admin, Disabled and Standard User Group
        // GID: 1 (Admins)
        // GID: 2 (Users)
        // GID: 3 (Disabled Users)
        {
          className: "dt-body-left",
          title: "Action",
          data: "GID",
          render: function(data){
            return `<Button class="edit-btn btn btn-outline-secondary btn-sm ${[1,2,3].includes(data) && 'disabled'}">
                Edit
              </Button>`
          }
        },
        {
            className: "dt-body-left",
            title: "Action",
            data: "GID",
            defaultContent: `<Button class="delbtn btn btn-outline-danger btn-sm">
                  Delete
                </Button>`,
            render: function(data){
              return `<Button class="delbtn btn btn-outline-danger btn-sm ${[1,2,3].includes(data) && 'disabled'}">
                  Delete
                </Button>`
            }
          },
      ],
      destroy: true,
    });

    $("#example4 tbody").on("click", ".delbtn", function () {
      const data = table.row($(this).parents("tr")).data();
      console.log("GID : ",data);
      if (data !== undefined) {
        setGroup({
          GID: data.GID,
          g_name: data.g_name,
          g_desc: data.g_desc,
          g_code: data.g_code,
          isDeleting: true,
          isEditing: false
        })
        setShow(true);
      }
    });

    $("#example4 tbody").on("click", ".view-btn", function () {
      const data = table.row($(this).parents("tr")).data();
      if(!data)
        return;
      if (data) {
        navigate(`/admin/group/${data.GID}`,{
          state: data
        })
      }
    });

    $("#example4 tbody").on("click", ".edit-btn", function () {
      const data = table.row($(this).parents("tr")).data();
      if (data !== undefined) {
        setGroup({
          GID: data.GID,
          g_name: data.g_name,
          g_desc: data.g_desc,
          g_code: data.g_code,
          isDeleting: false,
          isEditing: true
        })
        setEditGroup({
          GID: data.GID,
          g_name: data.g_name,
          g_code: data.g_code,
          g_desc: data.g_desc
        })
        setShow(true);
      }
    });

    return function () {
      table.destroy();
    };
  }, [groupList]);

  return (
    <>
    {/* modal for creating, editing, deleting group */}

    <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {
                  group.isDeleting ? `Delete Group` : (
                    group.isEditing ? 'Edit Group' :
                    `Create New Group`
                  )
                }
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {
                group.isDeleting ? 
                <>
                Do you want to delete this group - {group.g_name}?. This data will not be recovered.
                <br />
                  <p>
                    you must enter "<span style={{ color: "red" }}>delete</span>" to
                    proceed.
                  </p>
                  <input
                    onChange={(e) => {
                      setDeleteInput(e.target.value);
                    }}
                  />
                </>
                :
                group.isEditing ? 
                <>
                 {/* group name  */}
              <label htmlFor="g_name">
                Group Name <sup style={{color:'red'}}>*</sup>
              </label>
              <div style={{width: '100%'}} className="my-1">
              <input name="g_name" type="text" id="g_name"
                value={edit_Group.g_name}
                style={{width:'90%'}}
                onChange={(e) => {
                  setEditGroup((obj)=>{
                    return {...obj,[e.target.name]:e.target.value}
                  })
                }}
              />
              </div>
               {/* group description  */}
               <label htmlFor="g_desc">
                Group Description
              </label>
              <div style={{width: '100%'}} className="my-1">
              <input name="g_desc" type="text" id="g_desc"
                value={edit_Group.g_desc}
                style={{width:'90%'}}
                onChange={(e) => {
                  setEditGroup((obj)=>{
                    return {...obj,[e.target.name]:e.target.value}
                  })
                }}
              />
              </div>
               {/* group code  */}
               <label htmlFor="g_code">
                Group Code <sup style={{color:'red'}}>*</sup>{"   "} <span style={{fontSize: '10px'}}>
                  (This is case insensitive.)
                  </span>
              </label>
              <div style={{width: '100%'}} className="my-1">
              <input name="g_code" type="text" id="g_code"
                style={{width:'90%'}}
                value={edit_Group.g_code}
                onChange={(e) => {
                  setEditGroup((obj)=>{
                    return {...obj,[e.target.name]:e.target.value}
                  })
                }}
              />
              </div>
                </>
                :
                <>
              {/* group name  */}
              <label htmlFor="g_name">
                Group Name <sup style={{color:'red'}}>*</sup>
              </label>
              <div style={{width: '100%'}} className="my-1">
              <input name="g_name" type="text" id="g_name"
                value={group.g_name}
                style={{width:'90%'}}
                onChange={(e) => {
                  setGroup((obj)=>{
                    return {...obj,[e.target.name]:e.target.value}
                  })
                }}
              />
              </div>
               {/* group description  */}
               <label htmlFor="g_desc">
                Group Description
              </label>
              <div style={{width: '100%'}} className="my-1">
              <input name="g_desc" type="text" id="g_desc"
                value={group.g_desc}
                style={{width:'90%'}}
                onChange={(e) => {
                  setGroup((obj)=>{
                    return {...obj,[e.target.name]:e.target.value}
                  })
                }}
              />
              </div>
               {/* group code  */}
               <label htmlFor="g_code">
                Group Code <sup style={{color:'red'}}>*</sup>{" "} <span style={{fontSize: '10px'}}>
                  (This is case insensitive.)
                  </span>
              </label>
              <div style={{width: '100%'}} className="my-1">
              <input name="g_code" type="text" id="g_code"
                value={group.g_code}
                style={{width:'90%'}}
                onChange={(e) => {
                  setGroup((obj)=>{
                    return {...obj,[e.target.name]:e.target.value}
                  })
                }}
              />
              </div>
                </>
              }
            </Modal.Body>
            <Modal.Footer>
              {
                group.isDeleting ? 
                <>
                  <Button
                disabled={deleteInput.trim() === "delete" ? false : true}
                variant="danger"
                onClick={() => {
                 deleteGroup();
                }}
              >
                Delete
              </Button>
                </>
                :
                group.isEditing ?
                <>
                  <Button
                disabled={
                  (group.g_name === edit_Group.g_name) &&
                  (group.g_desc === edit_Group.g_desc) &&
                  (group.g_code === edit_Group.g_code)
                }
                variant="primary"
                onClick={() => {
                 editGroup();
                }}
              >
                Save
              </Button>
                </>
                :
                <>    
             <Button variant="primary" onClick={createGroup}>
                Create Group
              </Button>
                </>
              }
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

    <div className="d-flex justify-content-end my-3">
    <Button variant="outline-primary" onClick={() => setShow(true)}>Create Group</Button>
    </div>
    <div className="user-table">
      <table
        className="hover dt[-head|-body]-left"
        width="100%"
        id="example4"
        ref={tableRef}
      >
        <thead>
          <tr>
            <th>Group Name</th>
            <th>Group Description</th>
            <th>Group Code</th>
            <th>Action</th>
            <th>Action</th>
            <th>Action</th>
          </tr>
        </thead>
      </table>
    </div>
    </>
  );
};

//memoized the component to prevent unnecessary renderings
export default React.memo(GroupListTable);
