import React, { useEffect, useState } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import done from "../image/done.png";
import warning from "../image/warning.png";

const ToastMessage = ({ toast, message, onClose, status }) => {
	const [show, setShow] = useState(false);
	const [icon, setIcon] = useState("");

	useEffect(() => {
		setShow(toast);
		if (status === true) {
			setIcon(done);
		} else if (status === false) {
			setIcon(warning);
		}
	}, [toast]);

	const renderMessage = () => {
		if (Array.isArray(message)) {
		  return message.map((msg, index) => <p key={index}>{msg}</p>);
		} else {
		  return <p>{message}</p>;
		}
	};	

	return (
		<ToastContainer className="toast-container" position="top-center">
			{show && (
				<Toast
					show={show}
					onClose={() => onClose()}
					className="toast"
					delay={5000}
					autohide
				>
					<Toast.Header>
						<img src={icon} className="rounded me-2" alt="" />
						<strong className="me-auto">Helix</strong>
						<small>just now</small>
					</Toast.Header>
					<Toast.Body>{renderMessage()}</Toast.Body>
				</Toast>
			)}
		</ToastContainer>
	);
};

export default ToastMessage;
