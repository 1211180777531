import React, { useContext } from "react";
import { Form, Stack, Button } from "react-bootstrap";
import { FaMinus } from "react-icons/fa";
import { GoArrowDown, GoArrowUp } from "react-icons/go";
import { EditAssmtContext } from "../../Contexts/EditAssmtContext";

const EditChoiceSet = (props) => {
  const { sectionList } = useContext(EditAssmtContext);
  let editing = props.editing;
  let textOnly = props.textOnly;

  return (
    <div className="c-main">
      <Stack direction="horizontal" gap={2}>
        <div
          style={{
            flexGrow: 1,
          }}
        >
          <Form.Control
            disabled={editing}
            className="c-text"
            type="text"
            placeholder="Choice"
            name="cName"
            value={sectionList[props.sidx].qIDs[props.qidx].cIDs[props.cidx].cName}
            onChange={(e) => {
              props.handleChoiceChange(e, props.cidx);
            }}
          />
          <Stack
            direction="horizontal"
            gap={2}
            style={{
              marginTop: "0.5rem",
              justifyContent: "flex-end",
            }}
          >
            <div className="c-weigthage">
              <Form.Control
                disabled={editing||textOnly}
                type="number"
                step="0.1"
                placeholder="Weight"
                name="weight"
                value={sectionList[props.sidx].qIDs[props.qidx].cIDs[props.cidx]?.weight||0}
                onChange={(e) => {
                  props.handleChoiceChange(e, props.cidx);
                }}
              />
            </div>
            <Form.Check
              type="checkbox"
              label={"Communication"}
              name="communication"
              disabled={true}
              checked={sectionList[props.sidx].qIDs[props.qidx].cIDs[props.cidx]?.communication === "1" ? true : false}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => {
                e.target.value = e.target.checked ? 1 : 0;
                props.handleChoiceChange(e, props.cidx);
              }}
            />
            <Form.Select
              aria-label="Motivation Select"
              disabled={true}
              name="motivType"
              value={sectionList[props.sidx].qIDs[props.qidx].cIDs[props.cidx]?.motivType}
              onChange={(e) => {
                props.handleChoiceChange(e, props.cidx);
              }}
              style={{
                maxWidth: "max-content",
              }}
            >
              <option value="null">Select Motivation Type</option>
              <option value="intrinsic-1">Intrinsic 1</option>
              <option value="intrinsic-2">Intrinsic 2</option>
              <option value="intrinsic-3">Intrinsic 3</option>
              <option value="extrinsic-1">Extrinsic 1</option>
              <option value="extrinsic-2">Extrinsic 2</option>
              <option value="extrinsic-3">Extrinsic 3</option>
            </Form.Select>
          </Stack>
        </div>
        <div className="vr" />
        <Stack direction="horizontal" gap={0}>
          <Button disabled={editing||textOnly} variant="outline-light" onClick={() => props.moveUp(props.cidx)}>
            <GoArrowUp style={{ color: "#163172", fontSize: "20px" }} />{" "}
          </Button>
          <Button disabled={editing||textOnly} variant="outline-light" onClick={() => props.moveDown(props.cidx)}>
            <GoArrowDown style={{ color: "#163172", fontSize: "20px" }} />{" "}
          </Button>
        </Stack>
        <div className="vr" />
        <Button
          disabled={editing||textOnly}
          className="sec-x-btn"
          variant="outline-light"
          onClick={() => {
            props.handleChoiceRemove(props.cidx);
          }}
        >
          <FaMinus style={{ color: "#BD4B4B", fontSize: "15px" }} />
        </Button>
      </Stack>
    </div>
  );
};

export default EditChoiceSet;
