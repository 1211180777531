import React, { useEffect, useRef, useContext, useState } from "react";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import { useNavigate } from "react-router-dom";
import { AssmtContext } from "../../Contexts/AssmtContext";
import { AccountContext } from "../../Contexts/AccountContext";

const UserResponseTable = (props) => {
  $.DataTable = require("datatables.net");
  const tableRef = useRef();
  const navigate = useNavigate();
  const { assmtType } = useContext(AssmtContext);
  const { apiCall } = useContext(AccountContext);
  const { exportSingleResToCSV } = useContext(AssmtContext);

  const setCSVfilename = props.setCSVfilename;
  const setUserRes = props.setUserRes;
  const handleShow = props.handleShow;
  const setBodyName = props.setBodyName;
  const addRecordToDeletionList = props.addRecordToDeletionList;
  const removeRecordFromDeletionList = props.removeRecordFromDeletionList;
  const responsesToDelete = props.responsesToDelete;

  // Get current page and page length from localStorage, or use defaults
  const [currentPage, setCurrentPage] = useState(
    localStorage.getItem("datatablePage")
      ? parseInt(localStorage.getItem("datatablePage"), 10)
      : 0
  );
  const [pageLength, setPageLength] = useState(
    localStorage.getItem("datatablePageLength")
      ? parseInt(localStorage.getItem("datatablePageLength"), 10)
      : 10 // Default to 10 entries per page
  );

  async function getData(userData) {
  
    const { data } = await apiCall(`admin/getAssmtType/${userData.UAID}`);
    localStorage.setItem("secAssmtType", data["type"]);
    if (data["type"] === "individual") {
      navigate(`/admin/viewUserResponse/${data["AID"]}/${userData.UAID}`);
    } else if (data["type"] === "group") {
      navigate(
        `/admin/viewGrpAssmtUserResponse/${props.assmtID}/${userData.UAID}`
      );
    }
  }

  useEffect(() => {
    const table = $(tableRef.current).DataTable({
      data: props.userAssmtDetails,
      scrollX: true,
      order: [[8,'desc',[7,'asc']]],
      pageLength: pageLength, // Use dynamic page length
      displayStart: currentPage * pageLength, // Calculate the starting point based on page length
      lengthMenu: [10, 25, 50, 100], // Dropdown options for number of entries per page
      columns:[
              {
                className: "dt-body-center",
                title: "",
                defaultContent: `
           <input type='checkbox' class="delete-check" style="cursor:pointer;" />
      `,
              },
              {
                className: "dt-body-left",
                title: "First Name",
                data: "first_name",
              },
              {
                className: "dt-body-left",
                title: "Last Name",
                data: "last_name",
              },
              {
                className: "dt-body-left",
                title: "Email ID",
                data: "email_ID",
              },
              {
                className: "dt-body-left",
                title: "Position",
                data: "positionOption",
              },
              {
                className: "dt-body-left",
                title: "Title",
                data: "positionTitle",
              },
              {
                className: "dt-body-left",
                title: "Reference Date",
                data: "referenceDate",
              },
              {
                className: "dt-body-left",
                title: "Start Time",
                data: "start_Tstamp",
              },
              {
                className: "dt-body-left",
                title: "End Time",
                data: "end_Tstamp",
              },
              {
                className: "dt[-head|-body]-center",
                title: "Analysis",
                defaultContent: `<Button class="analysis-view-btn btn btn-outline-secondary btn-sm">
              View
            </Button>`,
              },
              {
                className: "dt[-head|-body]-center",
                title: "Export",
                defaultContent: `<Button class="csvbtn btn btn-outline-secondary btn-sm">
                CSV
              </Button>`,
              },
            ],
      destroy: true,
      createdRow: function (row, data) {
        if (shouldCheckRow(data)) {
          $(row).find(".delete-check").prop("checked", true);
        }
      },
    });

    // Handle page changes and store current page in localStorage
    table.on("page.dt", function () {
      const info = table.page.info();
      setCurrentPage(info.page); // Update current page state
      localStorage.setItem("datatablePage", info.page); // Store page in localStorage
    });

    // Handle changes to the number of entries per page
    table.on("length.dt", function (e, settings, len) {
      setPageLength(len); // Update page length when changed
      localStorage.setItem("datatablePageLength", len); // Store the selected page length
    });

        //checkbox for deletion
        $("#example tbody").on("click", ".delete-check", function (e) {
          e.stopPropagation();
          const data = table.row($(this).parents("tr")).data();
          if (data?.UAID) {
            e.target.checked
              ? addRecordToDeletionList(data?.UAID)
              : removeRecordFromDeletionList(data?.UAID);
          }
        });

  
    $("#example tbody").on("click", ".analysis-view-btn", function (e) {
      e.stopPropagation();
      const data = table.row($(this).parents("tr")).data();
      if (data !== undefined) {
        navigate(`/analyze/bysection/${props.assmtID}/${data.UID}`, {
          state: { UAID: data.UAID },
        });
      }
    });
    $("#example tbody").on("click", ".csvbtn", async function (e) {
      e.stopPropagation();
      const data = table.row($(this).parents("tr")).data();
      if (data !== undefined) {
        let exportData = await exportSingleResToCSV(
          data.UID,
          data.UAID,
          data.AID
        );

        const formatDate = (dateString) => {
          const dateObj = new Date(dateString);
          const day = String(dateObj.getDate()).padStart(2, "0");
          const month = String(dateObj.getMonth() + 1).padStart(2, "0");
          const year = dateObj.getFullYear();
          return `${day}-${month}-${year}`;
        };

        const groupedData = exportData.reduce((acc, curr) => {
          if (!acc[curr.sec_name]) {
            acc[curr.sec_name] = { entries: [], sec_scores: new Set() };
          }
          acc[curr.sec_name].entries.push(curr);
          acc[curr.sec_name].sec_scores.add(curr.sec_score);
          return acc;
        }, {});

        let formattedData = [
          {
            group_name: exportData[0]?.group_name,
            UID: data.UID,
            UAID: data.UAID,
            referenceDate: formatDate(exportData[0]?.referenceDate) || "",
          },
        ];

        let currentRow = formattedData[0];

        Object.keys(groupedData).forEach((sectionName, sectionIdx) => {
          const sectionData = groupedData[sectionName].entries;

          const uniqueScores = Array.from(groupedData[sectionName].sec_scores);
          const totalScore = uniqueScores.reduce(
            (sum, score) => sum + score,
            0
          );
          const averageScore = totalScore / uniqueScores.length || 0;

          currentRow[sectionName] = averageScore.toFixed(2);

          currentRow[`Section Name ${sectionIdx + 1}`] = sectionName;
          currentRow[`Score Obtained ${sectionIdx + 1}`] =
            averageScore.toFixed(2);

          sectionData.forEach((entry, questionIdx) => {
            currentRow[`Question ${sectionIdx + 1}-${questionIdx + 1}`] =
              entry.question;
            currentRow[
              `Type of Question ${sectionIdx + 1}-${questionIdx + 1}`
            ] = entry.type;
            currentRow[`Choice Picked ${sectionIdx + 1}-${questionIdx + 1}`] =
              entry.choice;
            currentRow[
              `Weightage of Choice ${sectionIdx + 1}-${questionIdx + 1}`
            ] = entry.weightage;
            currentRow[
              `Weightage of Response ${sectionIdx + 1}-${questionIdx + 1}`
            ] = entry.response;
          });
        });

        setUserRes([currentRow]);

        setBodyName(
          `${data.first_name} ${data.last_name}'s response is generated as .csv file and ready to download!`
        );
        setCSVfilename(
          `${data.UID} ${data.UAID} ${data.first_name} ${data.last_name} ${data.end_Tstamp}.csv`
        );
        handleShow();
      }
    });

    $("#example tbody").on("click", "tr", function () {
      const data = table.row($(this)).data();
      if (data !== undefined) {
        getData(data);
      }
    });

    return function () {
      table.destroy();
    };
  }, [props.userAssmtDetails, responsesToDelete, pageLength]);

  const shouldCheckRow = (data) => {
    return responsesToDelete.includes(data.UAID);
  };

  return (
    <div className="user-table">
      <table
        className="hover dt[-head|-body]-left cell-border"
        width="100%"
        id="example"
        ref={tableRef}
      >
        <thead>
          <tr>
            <th></th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email ID</th>
            <th>Position</th>
            <th>Title</th>
            <th>Reference Date</th>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Analysis</th>
            <th>Export</th>
          </tr>
        </thead>
      </table>
    </div>
  );
};

export default UserResponseTable;
