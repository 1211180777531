import React, { useContext, useEffect, useState } from "react";
import Navigate from "../../Navigate";
import { useLocation } from "react-router-dom";
import { AccountContext } from "../../Contexts/AccountContext";
import { AssmtContext } from "../../Contexts/AssmtContext";
import AccessDenied from "../../AccessDenied";
import ToastMessage from "../../ToastMessage";
import UserResponseListTable from "./UserResponseListTable";
import { Button, Modal } from "react-bootstrap";
import { CSVLink } from "react-csv";
import moment from "moment";
import "moment-timezone";
import { Helmet } from "react-helmet";

const UserResponseList = () => {
  const { loginStatus, loggedInUser, apiCall } = useContext(AccountContext);
  const { toast, setToast, message, setMessage, status, setStatus } =
    useContext(AssmtContext);

  const { state } = useLocation();
  const userObj = state?.userObj;
  const [userResponseList, setUserResponseList] = useState([]);

  const getAllResponseByUser = async (id) => {
    const resp = await apiCall(`user/getAllResults/${id}`);
    resp.data.forEach((obj) => {
      obj.referenceDate = moment.utc(obj.referenceDate).format("MM/DD/YYYY");
      obj.start_Tstamp = moment
        .utc(obj.start_Tstamp)
        .tz("America/New_York")
        .format("MM/DD/YYYY hh:mm:ss A");
      obj.end_Tstamp = moment
        .utc(obj.end_Tstamp)
        .tz("America/New_York")
        .format("MM/DD/YYYY hh:mm:ss A");
    });
    return resp.data;
  };

  useEffect(() => {
    if (userObj?.UID) {
      getAllResponseByUser(userObj?.UID).then((resData) => {
        setUserResponseList(resData);
      });
    }
  }, [userObj?.UID]);

  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [csvFilename, setCSVfilename] = useState("");
  const [headerName, setHeaderName] = useState("");
  const [userRes, setUserRes] = useState([]);
  const [bodyName, setBodyName] = useState("");
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [UAIDtoDelete, setUAIDtoDelete] = useState("");
  const [assmtToDelete, setAssmtToDelete] = useState("");
  const [deleteOpr, setDeleteOpr] = useState("");

  const handleOnClickDelete = async (id) => {
    try {
      let deleteUserRes = await apiCall(`admin/deleteUserRes/${id}`, "DELETE");
      if(deleteUserRes.name === 'AxiosError' || deleteUserRes.response?.data?.error)
         throw "Error";
      
      //update the response list
      setUserResponseList((responses)=>{
        return responses.filter((response)=>{
          return response.UAID != id;
        })
      })
      setMessage("User response deleted!");
      setStatus(true);
      setToast(true);
    } catch (err) {
      setMessage("Sorry, something went wrong!");
      setStatus(false);
      setToast(true);
    } finally {
      handleClose2();
      setAssmtToDelete("");
      setUAIDtoDelete("");
    }
  };

  return (
    <div>
      {loginStatus === true && loggedInUser.group === "Admin" ? (
        <>
          <Helmet>
            <title>{`Helix Employability Tool | View All Responses - ${userObj?.first_name} ${userObj?.last_name}`}</title>
          </Helmet>
          <Navigate />
          <h6>
            <a href="#" onClick={() => window.history.back()}>
              &lt;Back
            </a>
          </h6>
          <ToastMessage
            toast={toast}
            onClose={() => setToast(!toast)}
            message={message}
            status={status}
          />
          <Modal
            show={show1}
            onHide={handleClose1}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>{headerName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{bodyName}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose1}>
                Cancel
              </Button>
              <CSVLink
                data={userRes}
                headers={[
                  { label: "Section Name", key: "sec_name" },
                  { label: "Question", key: "question" },
                  { label: "Type", key: "type" },
                  { label: "Choice picked", key: "choice" },
                  { label: "Weightage of choice", key: "weightage" },
                  { label: "Weightage of response", key: "response" },
                ]}
                filename={csvFilename}
                target="_blank"
              >
                <Button variant="success" onClick={handleClose1}>
                  Download
                </Button>
              </CSVLink>
            </Modal.Footer>
          </Modal>
          <Modal
            show={show2}
            onHide={handleClose2}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>{assmtToDelete}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete {userObj?.first_name}{" "}
              {userObj?.last_name}'s response for the said assessment ? This
              data will not be recovered!
              <br />
              <br />
              <p>
                you must enter "<span style={{ color: "red" }}>delete</span>" to
                proceed.
              </p>
              <input
                onChange={(e) => {
                  setDeleteOpr(e.target.value);
                }}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose2}>
                Close
              </Button>
              <Button
                disabled={deleteOpr === "delete" ? false : true}
                variant="danger"
                onClick={() => {
                  handleOnClickDelete(UAIDtoDelete);
                  handleClose2();
                }}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
          <label>
            Response list for user "{userObj?.first_name} {userObj?.last_name}"
          </label>
          <br />
          <br />
          <UserResponseListTable
            userResponseList={userResponseList}
            userObj={userObj}
            setCSVfilename={setCSVfilename}
            setHeaderName={setHeaderName}
            setUserRes={setUserRes}
            handleShow1={handleShow1}
            setBodyName={setBodyName}
            setUAIDtoDelete={setUAIDtoDelete}
            setAssmtToDelete={setAssmtToDelete}
            handleShow2={handleShow2}
          />
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
};

export default UserResponseList;
