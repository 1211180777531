import React, { createContext, useEffect, useState, useContext } from "react";
import axios from "axios";
import { getBaseUrl } from "../../base";
import { AccountContext } from "./AccountContext";
const { v4 } = require("uuid");

export const EditAssmtContext = createContext();

const EditAssmtContextProvider = ({ children }) => {
  const { loginStatus, loggedInUser, apiCall } = useContext(AccountContext);

  const [assmtID, setAssmtID] = useState("");
  const [assmtGrpID, setAssmtGrpID] = useState("");

  const [assmtObj, setAssmtObj] = useState({
    aName: "",
    aDesc: "",
    aUrl: "",
  });

  const [sectionList, setSectionList] = useState([]);

  const [toast, setToast] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(false);

  let AID = assmtID;

  useEffect(() => {
    if (!AID || AID === "") return;
    const getAssmtdetails = async (AID) => {
      const { data } = await apiCall(`admin/getAssmtDetailsByID/${AID}`);
      const cnt = await apiCall(`admin/getResponseCount/${AID}`);

      setAssmtObj({
        aid: v4(),
        aName: data[0].name,
        aDesc: data[0].description,
        aUrl: data[0].url,
        published: data[0].published,
        hasResponse: cnt.data > 0 ? true : false,
      });
    };
    getAssmtdetails(AID);
  }, [AID, loggedInUser.jwtToken]);

  useEffect(() => {
    if (!AID || AID === "") return;
    const getAssmt = async (AID) => {
      const { data } = await apiCall(`admin/getAssmt/${AID}`);
      let sectionList = [];
      if(data.length === 0){ 
        setSectionList([])
      }
      else{
        data.forEach(async (sec, sidx, sArray) => {
          let sObj = {};
          sObj["SecID"] = sec.SecID;
          sObj["sName"] = sec.sec_name;
          sObj["sDesc"] = sec.sec_desc;
          sObj["sAnalysisType"] = sec.sec_analysisType;
          sObj["nfcData"] = sec.nfcData;
          sObj["nfc"] = `${sec.nfc}`;
          let qIDs = [];
          if (sec.questions.length === 0) sObj["qIDs"] = qIDs;
          sec.questions.forEach(async (que, qidx, qArray) => {
            let qObj = {};
            qObj["QID"] = que.QID;
            qObj["sidx"] = sidx;
            qObj["qQuestion"] = que.question;
            qObj["qType"] = que.type;
            qObj["hcs"] = que.hcs;
            qObj["motiv"] = que.motiv;
            qObj["CuAr"] = que.CuAr;
            qObj["CuArType"] = que.CuArType;
            let cIDs = [];
            if (que.choices.length === 0) qObj["cIDs"] = cIDs;
            que.choices.forEach(async (ch, cidx, cArray) => {
              let cObj = {};
              cObj["CID"] = ch.CID;
              cObj["qidx"] = qidx;
              cObj["sidx"] = sidx;
              cObj["cName"] = ch.choice;
              cObj["weight"] = ch.weightage;
              cObj["communication"] = `${ch.communication}`;
              cObj["motivType"] = ch.motivType;
              cIDs.push(cObj);
              if (cidx === cArray.length - 1) {
                qObj["cIDs"] = cIDs;
              }
            });
            qIDs.push(qObj);
            if (qidx === qArray.length - 1) {
              sObj["qIDs"] = qIDs;
            }
          });
          sectionList.push(sObj);
          if (sidx === sArray.length - 1) {
            setSectionList(sectionList);
          }
        });
      }
    };
    getAssmt(AID);
  }, [AID, loggedInUser.jwtToken]);

  const handleSectionAdd = () => {
    setSectionList([
      ...sectionList,
      {
        sName: "",
        sDesc: "",
        qIDs: [],
        nfc: false,
        sAnalysisType: "",
        nfcData: [],
      },
    ]);
  };

  const handleSectionRemove = (idx) => {
    const newSectionList = [...sectionList];
    newSectionList.splice(idx, 1);
    setSectionList(newSectionList);
  };

  const handleSectionChange = (e, index) => {
    const { name, value } = e.target;
    const newSectionList = [...sectionList];
    newSectionList[index][name] = value;
    setSectionList(newSectionList);
  };

  const handleSectionList = (array, index, from) => {
    const newSectionList = [...sectionList];
    if (from === "ques") newSectionList[index]["qIDs"] = array;
    if (from === "nfcData") newSectionList[index]["nfcData"] = array;
    setSectionList(newSectionList);
  };

  const createAssmt = async (assmtObj, sectionList, deleteOldAssmt, textOnly) => {
    try {
      let assmt = {
        AID: assmtObj.aid,
        name: assmtObj.aName,
        desc: assmtObj.aDesc,
        url: assmtObj.aUrl,
        type: "individual",
      };
      if (!textOnly) {
        let assmtDone = await apiCall("admin/createAssmt", "POST", assmt);
        if (assmtDone) {
          await sectionList.forEach(async (sObj, sSeq) => {
            let newSection = await apiCall(`admin/createSection/${assmtObj.aid}`, "POST", {
              sec_name: sObj.sName,
              sec_desc: sObj.sDesc,
              sec_analysisType: sObj.sAnalysisType,
              sSeq: sSeq,
              nfc: sObj.nfc,
            });
            if (newSection) {
              await sObj.qIDs.forEach(async (qObj, qSeq) => {
                let cList = [];
                qObj.cIDs.forEach((c, cSeq) => {
                  let cObj = {
                    choice: c.cName,
                    weightage: c.weight,
                    cSeq: cSeq,
                    communication: c.communication,
                    motivType: c.motivType,
                  };
                  cList.push(cObj);
                });

                await apiCall(`admin/createQuestion/${assmtObj.aid}/${newSection.data}`, "POST", {
                  question: qObj.qQuestion,
                  type: qObj.qType,
                  choices: cList,
                  qSeq: qSeq,
                  hcs: qObj.hcs,
                  motiv: qObj.motiv,
                  CuAr: qObj.CuAr,
                  CuArType: qObj.CuArType,
                });
              });

              if (sObj.nfcData.length !== 0) {
                await apiCall(`admin/createNfc/${assmtObj.aid}/${newSection.data}`, "POST", {
                  nfcData: sObj.nfcData,
                });
              }
            }
          });
          if (assmtObj.hasResponse === false && deleteOldAssmt === true) {
            await apiCall(`admin/deleteAssmt/${assmtID}`, "DELETE");
          }
          return true;
        }
      }
      else{
          let assmtDone = await apiCall(`admin/updateAssmt/${AID}`, "PUT", assmt);
          if (assmtDone) {
            await sectionList.forEach(async (sObj, sSeq) => {
                let newSection = await apiCall(`admin/updateSection/${sObj.SecID}`, "PUT", {
                  sec_name: sObj.sName,
                  sec_desc: sObj.sDesc,
                });
                if (newSection) {

                  await sObj.qIDs.forEach(async (qObj) => {
                    let cList = [];
                    qObj.cIDs.forEach((c) => {
                      let cObj = {
                        CID: c.CID,
                        choice: c.cName,
                      };
                      cList.push(cObj);
                    });
                    await apiCall(`admin/updateQuestion/${qObj.QID}`, "PUT", {
                      question: qObj.qQuestion,
                      choices: cList,
                    });
                  });
                }
                else{
                  return false;
                }
              
            });
          }
          else{
            return false;
          }
        return true;    
      }
    } catch (error) {
      return false;
    }
  };

  const createAssmtGrp = async (assmtGrp, assmtList, deleteOldAssmt) => {
    try {
      assmtGrp["AID"] = v4();
      assmtGrp["type"] = "group";
      let assmtDone = await apiCall("admin/createAssmt", "POST", assmtGrp);
      if (assmtDone) {
        await assmtList.forEach(async (assmtId, aSeq) => {
          await apiCall(`admin/createAssmtGrp/${assmtGrp.AID}`, "POST", {
            AID: assmtId,
            aSeq: aSeq,
          });
        });

        if (deleteOldAssmt) apiCall(`admin/deleteAssmt/${assmtGrpID}`, "DELETE");

        return true;
      }
    } catch (error) {
      return false;
    }
  };

  return (
    <EditAssmtContext.Provider
      value={{
        assmtID,
        setAssmtID,
        assmtObj,
        setAssmtObj,
        toast,
        setToast,
        message,
        setMessage,
        status,
        setStatus,
        sectionList,
        setSectionList,
        handleSectionAdd,
        handleSectionRemove,
        handleSectionChange,
        handleSectionList,
        createAssmt,
        createAssmtGrp,
        assmtGrpID,
        setAssmtGrpID,
      }}
    >
      {children}
    </EditAssmtContext.Provider>
  );
};

export default EditAssmtContextProvider;
