import React, { useEffect, useRef, useState, useContext } from "react";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.css";
import { AssmtContext } from "../../Contexts/AssmtContext";
import { UserContext } from "../../Contexts/UserContext";
import { Button, Modal } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { AccountContext } from "../../Contexts/AccountContext";
import Navigate from "../../Navigate";
import ToastMessage from "../../ToastMessage";
import { Helmet } from "react-helmet";
import EnableDisableModal from "../../Common/EnableDisableModal";
import { GroupContext } from "../../Contexts/GroupContext";

const ViewGroup = () => {
  const { setToast, setMessage, setStatus, toast, status, message } =
    useContext(AssmtContext);
  const { activeUsers, set_active_users } = useContext(UserContext);
  const { loggedInUser, apiCall } = useContext(AccountContext);
  const { setGroupList } = useContext(GroupContext);
  const [membersList, setMembersList] = useState([]);
  const [usersToRemove, setUsersToRemove] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteInput, setDeleteInput] = useState("");
  const [show, setShow] = useState(false);
  const [newMembers, setNewMembers] = useState([]);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [load, setLoad] = useState(false);
  const [enableUserModal, setEnableUserModal] = useState(false);
  const [userToEnable, setUserToEnable] = useState("");

  // NOTE:
  // For Admin Group:
  // 1. Active users don't contain admins. They are normal active users
  // 2. While adding an user in an admin group, make sure to update the relevant information in the db
  // like changing the groupname in the users table and updating usergroupjoin table and make sure to remove them from the active users list
  // 3. while removing the user from an admin group, again update the relevant information and make sure to add them back in the active users list

  $.DataTable = require("datatables.net");
  //for displaying the members of the group
  const tableRef = useRef();
  //for displaying the members when you open the modal for adding new members
  const newMembersRef = useRef();

  //API call for fetching members list for this current group
  const fetchMembersList = async () => {
    try {
      setLoad(true);
      const response = await apiCall(`/admin/getMembers/${state?.GID}`);
      // console.log("resppp : ", response);
      if (response?.data?.error) {
        throw response.data.error;
      }
      setMembersList(response?.data?.data);
      return;
    } catch (err) {
      console.log("Error in fetching groups : ", err);
      setMessage("Sorry, something went wrong!");
      setToast(true);
      setStatus(false);
      return;
    } finally {
      setLoad(false);
    }
  };

  //functions for updating state for removing members from the group
  const addRecordToDeletionList = (UID) => {
    setUsersToRemove((list) => {
      return [...list, UID];
    });
  };

  const removeRecordFromDeletionList = (UID) => {
    setUsersToRemove((list) => {
      return list.filter((val) => {
        return val !== UID;
      });
    });
  };

  //functions for updating state for adding new members into this group
  const addMemberToAdditionList = (UID) => {
    setNewMembers((list) => {
      return [...list, UID];
    });
  };

  const removeMemberFromAdditionList = (UID) => {
    setNewMembers((list) => {
      return list.filter((val) => {
        return val !== UID;
      });
    });
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleNewMembersModal = () => {
    setShow(false);
  };

  const handleCloseEnableUserModal = () => {
    setEnableUserModal(false);
  };

  //API call for removing members from this group
  const removeAllUsersFromGroup = async () => {
    try {
      setLoad(true);
      const response = await apiCall(
        `admin/removeMembers/${state?.GID}`,
        "POST",
        {
          users: usersToRemove,
          isAdmin: state?.GID == 1,
        }
      );

      if (response.response?.data?.error) {
        throw response.response?.data?.error;
      }

      setMessage(response.data?.message);
      setToast(true);
      setStatus(true);

      //update the array
      //now whenever you remove an admin from the admin group, make sure to
      // add the user in the activeUsers list because it consists of non-admin users
      let newnNonAdminUsers = [];

      let newMembersList = membersList.filter((user) => {
        if (state?.GID == 1 && usersToRemove.includes(user.UID)) {
          newnNonAdminUsers.push(user);
        }
        return !usersToRemove.includes(user.UID);
      });

      let users;
      if (state?.GID == 1) {
        users = [
          ...newnNonAdminUsers.map((user) => {
            user["GroupNames"] = user?.GroupNames?.split(",")
              .filter((val) => {
                return val !== state.g_name;
              })
              .join(",");
            user["GIDs"] = user?.GIDs?.split(",")
              .filter((val) => {
                return val != state?.GID;
              })
              .join(",");
            return user;
          }),
          ...activeUsers,
        ];
      } else {
        users = [
          ...activeUsers.map((user) => {
            if (usersToRemove.includes(user.UID)) {
              user["GroupNames"] = user.GroupNames.split(",")
                .filter((val) => {
                  return val !== state.g_name;
                })
                .join(",");
              user["GIDs"] = user.GIDs.split(",")
                .filter((val) => {
                  return val != state?.GID;
                })
                .join(",");
            }
            return user;
          }),
        ];
      }

      //decreasing the members count in the group
      setGroupList((groups) => {
        return groups.map((e) => {
          if (e.GID === state?.GID) {
            return { ...e, no_members: e.no_members - usersToRemove.length };
          }
          return e;
        });
      });
      setMembersList([...newMembersList]);
      set_active_users(users);
      setUsersToRemove([]);
      handleCloseDeleteModal();
    } catch (err) {
      console.log("Error in creating groups: ", err);
      setMessage("Sorry, something went wrong!");
      setToast(true);
      setStatus(false);
      handleCloseDeleteModal();
      return;
    } finally {
      setLoad(false);
    }
  };

  //API call for adding new members to this group
  const addMembersToThisGroup = async () => {
    // console.log("state : ",state);
    try {
      setLoad(true);
      const response = await apiCall(`admin/addMembers/${state?.GID}`, "POST", {
        users: newMembers,
        isAdmin: state?.GID == 1,
      });

      if (response.name === "AxiosError" || response.response?.data?.error) {
        throw response.response?.data?.error || "AxiosError";
      }

      setMessage(response.data?.message);
      setToast(true);
      setStatus(true);

      //update the array
      setMembersList((list) => {
        return [
          ...list,
          ...activeUsers.filter((user) => {
            return newMembers.includes(user.UID);
          }),
        ];
      });

      //update the active users list as well
      //if an user is getting added in admin group, then remove it from the active Users list
      let users = [];

      if (state?.GID == 1) {
        users = [
          ...activeUsers.filter((user) => {
            return !newMembers.includes(user.UID);
          }),
        ];
      } else {
        users = [
          ...activeUsers.map((user) => {
            if (newMembers.includes(user.UID)) {
              user["GroupNames"] = user["GroupNames"] + `,${state.g_name}`;
              user["GIDs"] = user["GIDs"] + `,${state?.GID}`;
            }
            return user;
          }),
        ];
      }
      //increasing the members count in the group
      setGroupList((groups) => {
        return groups.map((e) => {
          if (e.GID === state?.GID) {
            return { ...e, no_members: e.no_members + newMembers.length };
          }
          return e;
        });
      });
      set_active_users(users);
      setNewMembers([]);
      handleNewMembersModal();
    } catch (err) {
      console.log("Error in adding members: ", err);
      setMessage("Sorry, something went wrong!");
      setToast(true);
      setStatus(false);
      handleNewMembersModal();
      return;
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    if (!loggedInUser.jwtToken) navigate("/admin/userManagement");
    fetchMembersList();
  }, []);

  //function for enabling disabled users
  const changeStatus = async () => {
    try {
      let resp = await apiCall(`user/updateStatus/${userToEnable.UID}`, "PUT", {
        status: true,
      });
      if (resp?.name === "AxiosError") throw { message: "Network Error!" };
      if (resp?.data?.error) throw resp.data;

      //filtering out the user that was disabled
      setMembersList((data) => {
        return data.filter((user_) => {
          return user_.email_ID !== userToEnable.email_ID;
        });
      });
      //add the newly enabled user to the active_users list
      let users = [...activeUsers, userToEnable];
      set_active_users(users);

      //update the members count for teh disabled group
      setGroupList((groups) => {
        return groups.map((e) => {
          if (e.GID === state?.GID) {
            return { ...e, no_members: e.no_members - 1 };
          }
          return e;
        });
      });
      setMessage("User enabled successfully!");
      setStatus(true);
      setToast(true);
    } catch (err) {
      console.log("Error : ", err);
      setMessage(err.message);
      setStatus(false);
      setToast(true);
      return;
    }
  };

  useEffect(() => {
    const table = $(tableRef.current).DataTable({
      scrollX: true,
      order: [[1, "asc", [2, "asc"]]],
      columnDefs: [
        {
          targets:[0],
          visible: state?.GID == '3' ? false : true,
          searchable: false
        },
        {
          targets:[6],
          visible: state?.GID == '3' ? true : false,
          searchable: false
        }
      ],
      data: membersList,
      columns: [
        {
          className: "dt-body-center",
          title: "",
          render: function (data, type, row) {
            return [2, 3].includes(state?.GID) || row.UID === loggedInUser.UID
              ? null
              : `<input type='checkbox' class="delete-check" style="cursor:pointer;" />`;
          },
        },
        {
          className: "dt-body-left",
          title: "First Name",
          data: "first_name",
          render: function (data, type, row) {
            return row.UID === loggedInUser.UID ? "You" : row.first_name;
          },
        },
        {
          className: "dt-body-left",
          title: "Last Name",
          data: "last_name",
          render: function (data, type, row) {
            return row.UID === loggedInUser.UID ? "" : row.last_name;
          },
        },
        {
          className: "dt-body-left",
          title: "Email ID",
          data: "email_ID",
        },
        {
          className: "dt-body-left",
          title: "Email Verified",
          data: "email_verified",
          render: function (data) {
            if (data === 0) {
              return "False";
            } else {
              return "True";
            }
          },
        },
        {
          className: "dt-body-left",
          title: "Consent",
          data: "consent",
          render: function (data) {
            if (data === 0) {
              return "No";
            } else {
              return "Yes";
            }
          },
        },
        {
          className: "dt-body-left",
          title: "Action",
          data: null,
          render: function () {
            return ` <Button
            class="mx-1 enable_btn btn btn-outline-primary"
          >
            Enable
          </Button>`;
          },
        },
      ],
      destroy: true,
    });

    //checkbox for deletion
    $("#example4 tbody").on("click", ".delete-check", function (event) {
      event.stopPropagation();
      const data = table.row($(this).parents("tr")).data();
      // console.log("DATTATAA :", data);
      if (data?.UID) {
        event.target.checked
          ? addRecordToDeletionList(data?.UID)
          : removeRecordFromDeletionList(data?.UID);
      }
    });

    // button for enabling users
    $("#example4 tbody").on("click", ".enable_btn", function (event) {
      event.stopPropagation();
      const data = table.row($(this).parents("tr")).data();
      if (data !== undefined) {
        console.log("heheh : ",data);
        setUserToEnable(data);
        setEnableUserModal(true);
        return;
      }
    });

    // viewing user responses
    $("#example4 tbody").on("click", "tr", function () {
      const data = table.row($(this)).data();
      if (data !== undefined) {
        navigate(`/admin/userManagement/userResponse`, {
          state: {
            userObj: {
              first_name: data.first_name,
              last_name: data.last_name,
              email_ID: data.email_ID,
              UID: data.UID,
            },
          },
        });
      }
    });

    return function () {
      table.destroy();
    };
  }, [membersList]);

  useEffect(() => {
    let table;
    if (show && newMembersRef.current) {
      table = $(newMembersRef.current).DataTable({
        scrollX: true,
        order: [[1, "desc", [2, "asc"]]],
        data: activeUsers.filter((user) => {
          return !user?.GIDs?.split(",").includes(String(state?.GID));
        }),
        columns: [
          {
            className: "dt-body-center",
            title: "",
            defaultContent: `
             ${
               ![2, 3].includes(state?.GID)
                 ? `<input type='checkbox' class="add-check" style="cursor:pointer;" />`
                 : ""
             }
        `,
          },
          {
            className: "dt-body-left",
            title: "First Name",
            data: "first_name",
          },
          {
            className: "dt-body-left",
            title: "Last Name",
            data: "last_name",
          },
          {
            className: "dt-body-left",
            title: "Email ID",
            data: "email_ID",
          },
          {
            className: "dt-body-left",
            title: "Email Verified",
            data: "email_verified",
            render: function (data) {
              if (data === 0) {
                return "False";
              } else {
                return "True";
              }
            },
          },
          {
            className: "dt-body-left",
            title: "Consent",
            data: "consent",
            render: function (data) {
              if (data === 0) {
                return "No";
              } else {
                return "Yes";
              }
            },
          },
        ],
        destroy: true,
      });

      //checkbox for insertion
      $("#example5 tbody").on("click", ".add-check", function (event) {
        const data = table.row($(this).parents("tr")).data();
        if (data?.UID) {
          event.target.checked
            ? addMemberToAdditionList(data.UID)
            : removeMemberFromAdditionList(data.UID);
        }
      });
    }

    return function () {
      if (show && newMembersRef.current) table?.destroy();
    };
  }, [show, activeUsers]);

  return (
    <>
      <Helmet>
        <title>Helix Employability Tool | View Group</title>
      </Helmet>
      <Navigate />
      <ToastMessage
        toast={toast}
        onClose={() => setToast(!toast)}
        message={message}
        status={status}
      />
      <h6>
        <a href="#" onClick={() => window.history.back()}>
          &lt;Back
        </a>
      </h6>
      <h5>{state?.g_name}</h5>
      <p>
        {state?.g_desc.length === 0
          ? "No Description Provided."
          : state?.g_desc}
      </p>

      {/* Enable User Modal  */}
      <EnableDisableModal
        show={enableUserModal}
        handleClose={handleCloseEnableUserModal}
        title={"Enable User"}
        bodyText={`Do you want to enable ${userToEnable?.first_name} ${userToEnable?.last_name}?`}
        callback={changeStatus}
        btnVariant="success"
        btnText="Enable"
        toDisable={false}
      />

      {/* Delete Modal  */}
      <Modal
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Remove Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Do you want to remove all the selected users from this group?
          <br />
          <br />
          <p>
            you must enter "<span style={{ color: "red" }}>delete</span>" to
            proceed.
          </p>
          <input
            onChange={(e) => {
              setDeleteInput(e.target.value);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={
              load ? true : deleteInput.trim() === "delete" ? false : true
            }
            variant="danger"
            onClick={() => {
              removeAllUsersFromGroup();
            }}
          >
            {load ? "Loading..." : "Delete"}
          </Button>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add Members Modal  */}
      <Modal
        show={show}
        onHide={handleNewMembersModal}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Members</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="user-table">
            <table
              className="hover dt[-head|-body]-left cell-border"
              width="100%"
              id="example5"
              ref={newMembersRef}
            >
              <thead>
                <tr>
                  <th></th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email ID</th>
                  <th>Email Verified</th>
                  <th>Consent</th>
                </tr>
              </thead>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={newMembers.length === 0 || load}
            variant="primary"
            onClick={() => {
              addMembersToThisGroup();
            }}
          >
            {load ? "Loading..." : "Add"}
          </Button>
          <Button variant="secondary" onClick={handleNewMembersModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="d-flex justify-content-end my-3">
        {![2, 3].includes(state?.GID) && (
          <Button
            variant="outline-primary"
            className="mx-1"
            onClick={() => setShow(true)}
          >
            Add Members
          </Button>
        )}
        {![2, 3].includes(state?.GID) && usersToRemove.length !== 0 && (
          <Button
            variant="outline-danger"
            className="mx-1"
            onClick={() => setShowDeleteModal(true)}
          >
            Remove Members
          </Button>
        )}
      </div>
      <div className="user-table">
        <table
          className="hover dt[-head|-body]-left cell-border"
          width="100%"
          id="example4"
          ref={tableRef}
        >
          <thead>
            <tr>
              <th></th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email ID</th>
              <th>Email Verified</th>
              <th>Consent</th>
              <th>Action</th>
            </tr>
          </thead>
        </table>
      </div>
    </>
  );
};

export default ViewGroup;
