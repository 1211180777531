import React,{useState} from "react";
import { Button, Modal } from "react-bootstrap";

const EnableDisableModal = ({
  show,
  handleClose,
  title,
  bodyText,
  callback,
  callbackParameters=[],
  btnVariant="danger",
  btnText="Disable",
  toDisable=true,
}) => {

   const [input, setInput] = useState("");

   return(
    <>
       <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {bodyText}
              <br />
              <br />
              <p>
                you must enter "
                <span style={{ color: toDisable ? 'red' : 'green'}}>
                  {toDisable ? 'disable' : 'enable'}
                </span>
                " to proceed.
              </p>
              <input
                onChange={(e) => {
                  setInput(e.target.value);
                }}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
                <Button
                  disabled={input.trim() === (toDisable ? "disable" : "enable") ? false : true}
                  variant={btnVariant}
                  onClick={() => {
                    callback(...callbackParameters);
                    handleClose();
                  }}
                >
                  {btnText}
                </Button>
            </Modal.Footer>
        </Modal>
    </>
   )
}

export default EnableDisableModal;