import React,{useState} from "react";
import { Button, Modal } from "react-bootstrap";
// This modal can be used for having jquery datatables 

const AddModal = ({
  show,
  load=false,
  array=[],
  handleClose,
  title,
  callback,
  callbackParameters=[],
  children
}) => {

   return(
    <>
       <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="xl"
          >
            <Modal.Header closeButton>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {children}
            </Modal.Body>
            <Modal.Footer>
                <Button
                  disabled={array.length === 0 || load}
                  variant="primary"
                  onClick={() => {
                    callback(...callbackParameters);
                    // here you can call the closing modal function or within the callback function 
                    // handleClose();
                  }}
                >
                  {load ? "Loading..." : "Add"}
                </Button>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
        </Modal>
    </>
   )
}

export default AddModal;