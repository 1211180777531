import React, { useContext, useEffect, useState } from "react";
import { Form, Stack, Table } from "react-bootstrap";
import { UserContext } from "../../Contexts/UserContext";

const Likert = (props) => {
	let q = props.q;
	let qqid = props.qqid;
	let allQuestion = props.allQuestion;
	let isMobile = props.isMobile;
	let isLandscapeMobile = props.isLandscapeMobile;
	const { 
		userResponseList, 
		setUserResponseList,
		userHCSList,
		setUserHCSList
	} = useContext(UserContext);

	const handleSelectChange = (e, c) => {
		c["checked"] = 1;
		const { value, checked, id } = e.target;
		let resObj = {
			response: value,
			type: allQuestion[qqid].type,
			// finalWeightage: parseFloat(value * c.weightage),
			CID: c.CID,
			QID: c.QID,
			SecID: c.SecID,
		};
		if (checked||isMobile) {
			setUserResponseList(
				userResponseList.filter((i) => i.CID !== id)
			);
			if(allQuestion[qqid].hcs === "1") {
				let list = [...userHCSList];
				let newUserHCSList = list.filter(
					(i) => i.CID !== id
				)
				let resObj = {
					QID: allQuestion[qqid].QID,
					CID: id,
					weight: value*c.weightage,
				}
				newUserHCSList.push(resObj);
				setUserHCSList(newUserHCSList);
			}
		}
		setUserResponseList((prev) => {
			return [...prev, resObj];
		});
	};
	
	return (
		<div>
			{isMobile ? (
				<Form>
				{allQuestion[qqid].choices.map((c, ccid) => (
					<Form.Group key={ccid} controlId={c.CID}>
					<Form.Label style={{marginBottom: '0.5rem'}}>{c.choice}</Form.Label>
					<Form.Select
						value={
						userResponseList.find((i) => i.CID === c.CID)?.response || ""
						}
						onChange={(e) => handleSelectChange(e, c)}
						style={{marginBottom: '1rem'}}
					>
						<option value="">Select an option</option>
						<option value="1">Not At All Like Me</option>
						<option value="2">Not Very Like Me</option>
						<option value="3">A Little Like Me</option>
						<option value="4">Somewhat Like Me</option>
						<option value="5">Very Like Me</option>
					</Form.Select>
					</Form.Group>
				))}
				</Form>
			) : (
		
				<Table borderless>
					<tbody>
						<tr>
							<td></td>
							<td>"Not At All Like Me"</td>
							<td>"Not Very Like Me"</td>
							<td>"A Little Like Me"</td>
							<td>"Somewhat Like Me"</td>
							<td>"Very Like Me"</td>
						</tr>
						{allQuestion[qqid].choices.map((c, ccid) => (
							<tr key={ccid}>
								<td>{c.choice}</td>
								{[1, 2, 3, 4, 5].map((value) => (
									<td key={value}>
										<Form.Check
											value={value}
											type="radio"
											name={c.CID}
											id={c.CID}
											checked={
												userResponseList.find(
													(i) => i.CID === c.CID && i.response === String(value)
												)
											}
											onChange={(e) => handleSelectChange(e, c)}
										/>
									</td>
								))}
							</tr>
						))}
					</tbody>
				</Table>
			)}
		</div>
	);
};

export default Likert;
